﻿import { product_detail_marketing_information } from './product_detail_marketing_information';
import { product_detail_operational_information } from './product_detail_operational_information';
import { product_detail_seller_information } from './product_detail_seller_information';

export class product_detail {
    public tour_id: string = "";
    public name: string = "";
    public mapping_codes: string[] = [];
    public marketing_information: product_detail_marketing_information = new product_detail_marketing_information();
    public operational_information: product_detail_operational_information = new product_detail_operational_information();
    public seller_information: product_detail_seller_information = new product_detail_seller_information();
}