﻿import { defineComponent } from 'vue';

import basket from "./basket.vue"
import calendar from "./calendar.vue"
import passenger_details from "../passenger_details.vue"
import pickup_point from "./pickup.vue"
import sales_group from "./sales_group.vue"
import tour_heading from "./tour_heading.vue"

import { Product } from '../../../services/product';

import { product_detail } from '../../../objects/v1_3/product/product/product_detail';
import { product_detail_seller_information_month } from '../../../objects/v1_3/product/product/product_detail_seller_information_month';

const Prod = new Product();

export default defineComponent({
    name: "tour_details",
    components: {
        basket,
        calendar,
        passenger_details,
        pickup_point,
        sales_group,
        tour_heading
    },
    emits: [
        "Add_To_Cart"
    ],
    props: {
        trip_index: Number
    },
    data() {
        return {
            product: new product_detail() as product_detail,
            tour_ids: [] as string[],
            tour_id: "" as string,
            selected_tour_id: "" as string,
            year: 0 as number,
            month: 0 as number
        }
    },
    methods: {
        async download_product(tour_id: string) {
            this.tour_id = tour_id;
            this.selected_tour_id = tour_id;
            this.tour_ids = [];

            (await Prod.Get_Sales_Group(this.tour_id))?.products.forEach(async product => {
                await Prod.Get_Details(product.tour_id, this.$store);
                this.tour_ids.push(product.tour_id);
            });

            this.product = await Prod.Get_Details(this.tour_id, this.$store);

            if (this.product?.seller_information?.months?.length > 0) {
                this.$refs.calendar.onMonthChange(this.product.seller_information.months[0]);
            }

            this.$refs.sales_group.Download_Sales_Group(this.tour_id);
            this.$refs.sales_group.select_product(this.tour_id);

            await this.$refs.pickup_points.Download_Pickup_Points(this.tour_id);
        },
        Add_To_Cart() {
            this.$emit("Add_To_Cart");
        },
        onMonthChange(year: number, month: number) {
            this.year = year;
            this.month = month;

            this.onCalendarUpdate();
        },
        onDateChange() {
            this.$refs.passenger_details.Collapse_Panel();
        },
        onSelectedTour(tour_id: string) {
            this.selected_tour_id = tour_id;

            this.onCalendarUpdate();
        },
        onCalendarUpdate() {
            this.$refs.calendar.onCalendarUpdate();
        },
        isMobile(): boolean{
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
        goTo(id) {
            const element = document.getElementById(id);
      
            if (element) {
               element.scrollIntoView({
                 block: "center",
                 behavior: "smooth",
               });
            }
            console.log(element)
        },
        isSmallScreen(): boolean{
            if(window.innerHeight < 800) {
                return true
            }
            else {
                return false
            }
        }
    },
    mounted() {
    }
})