﻿import { createWebHistory, createRouter } from "vue-router"
declare global { interface Window { dataLayer: any; } }

import NotFound from "../pages/NotFound.vue"

import Forgot_Password from "../../tms/agent/forgot_password/forgot_password.vue"
import Login from "../../tms/agent/login/login.vue"
import My_Profile from "../../tms/agent/my_profile/my_profile.vue"
import Manage_Bookings from "../../tms/agent/manage_bookings/manage_bookings.vue"
import Booking_Details from "../../tms/agent/manage_bookings/details/details.vue"
import Register from "../../tms/agent/register/register.vue"

import Search from "../../tms/booking_engine/components/consumer/1_search.vue"
import Cart from "../../tms/booking_engine/components/consumer/3_cart.vue"
import Payment from "../../tms/booking_engine/components/consumer/4_check_out.vue"
import Credit_Card from "../../tms/booking_engine/components/consumer/4a_payment.vue"
import Confirmation from "../../tms/booking_engine/components/consumer/5_confirmation.vue"
import failed_payment from "../../tms/booking_engine/components/consumer/5b_failed.vue" 

import store from "../../tms/booking_engine/store/index";

const history = createWebHistory();
const routes = [
    { path: "/", component: Search },
    { path: "/search", component: Search },
    { path: "/search/details", component: Booking_Details },
    { path: "/cart", component: Cart },
    { path: "/payment", component: Payment },
    { path: "/creditcard", component: Credit_Card },
    { path: "/confirmation", component: Confirmation },
    { path: "/failed_payment", component: failed_payment },
    { path: "/login", component: Login },
    { path: "/login/forgot_password", component: Forgot_Password },
    { path: "/login/register", component: Register },
    { path: "/my_profile", component: My_Profile },
    { path: "/manage_bookings", component: Manage_Bookings },

    { path: "/:pathMatch(.*)*", component: NotFound, name: "Page Not Found", meta: { title: "Page Not Found" } }
]

const router = createRouter({
    history, routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
            }
        }

        return {};
    }
});

store.commit("Initialise_Agent_Login");

console.log(JSON.stringify(store.getters.get_Agent_Login));
console.log("Initialise Agent");

router.beforeEach((to, from, next) => {

    console.log(from);
    console.log(to);

    if (store.getters.get_Agent_Login?.valid_login == true || to.path == "/login" || to.path == "/login/forgot_password" || to.path == "/login/register") {
        next();
    } else {
        next({ path: "/login" });
    }
});

export default router;