﻿import { defineComponent } from 'vue';
import { Booking } from "../../../services/booking";
const Book: Booking = new Booking();

export default defineComponent({
    name: "footer_item",
    props: {
    },
    methods: {
        privacy_policy(): string {
            return String(import.meta.env.VITE_FOOTER_POLICY_PRIVACY);
        },
        booking_policy(): string {
            return String(import.meta.env.VITE_FOOTER_POLICY_BOOKING);
        },
        terms_policy(): string {
            return String(import.meta.env.VITE_FOOTER_POLICY_TERMS);
        },
        company_copyright(): string {
            return String(import.meta.env.VITE_FOOTER_COMPANY);
        },
        company_contact(): string {
            return String(import.meta.env.VITE_DEFAULT_CONTACT_TEXT);
        },
        get_Currency(): string {
            return this.$store.getters.get_Currency?.currency_info?.iso_currency_code;
        },
        get_Total_Price(): string {
            return Book.get_total_price(this.$store.getters.get_Booking, this.$store).toFixed(2);
        }
    },
    mounted() {
    }
})