﻿import { defineComponent, PropType } from 'vue';

import { response_login_contact } from "../../booking_engine/objects/agent/identity/v1_3/response_login_contact";

import { Agent_Login } from "../../booking_engine/services/agent_login";

let Agent_Logins: Agent_Login = new Agent_Login();

export default defineComponent({
    components: {
    },
    props: {
        selected_contact: Object as PropType<response_login_contact>
    },
    data() {
        return {
            saving_details: false as boolean
        }
    },
    methods: {
        async Save_Details() {
            this.saving_details = true;

            await Agent_Logins.Update_Contact(this.$store.getters.get_Agent_Login.agency.code, this.selected_contact || new response_login_contact());

            this.saving_details = false;
        }
    },
    mounted() {
    }
});