﻿import { defineComponent } from 'vue';

import { client_booking } from "../../../booking_engine/objects/v1_2/Booking_Details/client_booking";

import { Booking } from "../../../booking_engine/services/booking";
import { Currency_Service } from "../../../system/services/currency";


import { request } from "../../../booking_engine/objects/v1_2/Booking_Delete/Request/request";
import { request_trip } from "../../../booking_engine/objects/v1_2/Booking_Delete/Request/request_trip";
import { request_trip_passenger } from "../../../booking_engine/objects/v1_2/Booking_Delete/Request/request_trip_passenger";

let Book: Booking = new Booking();
let Currency_Services: Currency_Service = new Currency_Service();

export default defineComponent({
    components: {
    },
    props: {
        trip_passenger: client_booking
    },
    data() {
    },
    methods: {
        get_Passenger(): string {
            return Book.get_Passenger(this.trip_passenger?.passenger_identifier ?? "", this.$store.getters.get_Booking);
        },
        get_Price(Price: number): string {
            return Currency_Services.Format_Currency(Price, this.$store.getters.get_Currency);
        },
        Toggle_Cancellation() {
            this.$store.getters.get_Booking_Cancellation.trips.forEach((trip: request_trip) => {
                trip.passengers.forEach((trip_cancel_passenger: request_trip_passenger) => {
                    if (this.trip_passenger?.trip_passenger_identifier == trip_cancel_passenger.trip_passenger_identifier) {
                        trip_cancel_passenger.cancel = !trip_cancel_passenger.cancel;
                    }
                });
            });
        },
        Cancel_Line(): boolean {

            let Output: boolean = false;

            this.$store.getters.get_Booking_Cancellation.trips.forEach((trip: request_trip) => {
                trip.passengers.forEach((trip_cancel_passenger: request_trip_passenger) => {
                    if (this.trip_passenger?.trip_passenger_identifier == trip_cancel_passenger.trip_passenger_identifier) {
                        Output = trip_cancel_passenger.cancel;
                    }
                });
            });

            return Output;
        }
    },
    mounted() {
    }
});