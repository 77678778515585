﻿<template>

    <footer>
        <div class="footer">
            <div class="footer-outter">
                <div class="copyright">
                    <p>Copyright &copy; {{company_copyright()}}. All rights reserved. <span id="pixelPrice" class="invisible"></span></p>
                    <p> {{ company_contact() }}</p>
                    <div v-if="get_Total_Price() != '0.00' && $store.getters.get_Booking?.group_booking_id != 0" class="text-white">{{ get_Total_Price() }}</div>
                </div>
                <div class="footer-links">
                    <ul>
                        <li><a :href="privacy_policy()" target="_blank">Privacy Policy</a></li>
                        <li><a :href="booking_policy()" target="_blank">Booking Conditions</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>

</template>

<script lang="ts" src="./footer.ts"></script>