﻿import { defineComponent } from 'vue';

import calendar_day from "./calendar_day.vue";

import { Booking } from "../../../../services/booking";
import { Capacity } from "../../../../services/capacity";
import { Product } from "../../../../services/product";

const Book = new Booking();
const Capa = new Capacity();
const Prod = new Product();

import { week_day } from "../../../../objects/front_end/calendar/week_day";

import { product_search } from "../../../../objects/v1_3/product/search/product_search";
import { product_detail_seller_information_month } from '../../../../objects/v1_3/product/product/product_detail_seller_information_month';
import { week } from '../../../../objects/front_end/calendar/week';

export default defineComponent({
    name: "calendar_details",
    components: {
        calendar_day
    },
    emits: [
        "dateSelected"
    ],
    props: {
    },
    data() {
        return {
            product_search: new product_search() as product_search,
            capacity: [] as week[],
            day_from: new week_day() as week_day,
            day_to: new week_day() as week_day
        }
    },
    methods: {
        async onCalendarUpdate(selected_filter_tags: [], selected_month: product_detail_seller_information_month) {
            this.product_search = await Prod.Get_Product_Search_Month(selected_filter_tags, selected_month?.year, selected_month?.month);
            this.capacity = Capa.Get_Capacity_Search_Week(selected_month?.year, selected_month?.month, this.product_search, this.$store.getters.get_Currency.currency_info.iso_currency_code, Book.Pricing_Age_Category(this.$store));
        },
        Date_Selected(day: week_day) {
            if (import.meta.env.VITE_DEFAULT_SEARCH_DATE_RANGE == "false") {
                this.$emit("dateSelected", Prod.Get_Product_Search_Day(day.tour_id, this.product_search), day.date);
            } else {
                if ((this.day_from.number ?? 0 ) == 0) {
                    this.day_from = day;
                } else {
                    if ((this.day_from.number ?? 0) < (day.number ?? 0)) {
                        this.day_to = day;
                    } else {
                        if ((this.day_to.number ?? 0) == 0) {
                            this.day_to = this.day_from;
                            this.day_from = day;
                        } else {
                            this.day_from = day;
                        }
                    }
                }
            }
        }, 
        Is_Date_Selected(day: week_day): boolean {
            return (day.number ?? 0) >= (this.day_from.number ?? 0) && (day.number ?? 0) <= (this.day_to.number ?? 0);
        },
        isAAT(): boolean {
            if (import.meta.env.VITE_DEFAULT_PARENT == "AAT") {
                return true;
            }
            return false;
        }
    },
    mounted() {
    }
})