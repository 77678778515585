﻿<template>
  <header class="header">
    <header_menu></header_menu>
  </header>

  <main class="main pl-3 pr-3">
    {{ scrollBehavior() }}
    <div class="grid grid-cols-1 md:grid-cols-2">
      <h1>Trip Cart</h1>
      <!--<basket_header :position="1"></basket_header>-->
    </div>

    <div class="cart-content flex flex-col md:flex-row">
      <div class="cart-items w-full md:w-8/12 mr-0 md:mr-4 lg:mr-8 mb-8">
        <div v-if="get_Basket().passengers.toString() === null || get_Basket().passengers.toString() === undefined || get_Basket().passengers.toString() === ''" class="basket-tours w-full">
          <div class="card-h basket">
            <h3>Your cart is empty</h3>
            <p class="text-gray-50 text-lg">Have a look at some of our trips below</p>
          </div>
        </div>
        <div v-else>
          <basket></basket>
        </div>
        <add_ons v-for="trip in get_Basket().trips" :trip="trip"></add_ons>
        <tours></tours>
      </div>

      <trip_summary></trip_summary>
    </div>
  </main>

  <footer_item></footer_item>
</template>

<script lang="ts" src="./3_cart.ts"></script>
