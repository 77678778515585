﻿import { defineComponent } from 'vue';

import header_menu from "../header/menu.vue"
import hero from "../header/hero.vue"
import footer_item from "../base/common/footer.vue"

import searchfilter from "../base/1_search/searchfilter.vue"
import filter_buttons from "../base/1_search/tour_list/filter_buttons.vue";
import tour_card from "../base/1_search/tour_card.vue"
import tour_details from "../base/2_tour_details/tour_details.vue"
import embedded_calendar from "../base/2_tour_details/embedded_calendar.vue"

import { Booking } from "../../services/booking";
import { Currency_Service } from "../../../system/services/currency";
import { Product } from '../../services/product';
import { Web_Engine } from "../../services/web_engine";

import { product_search } from '../../objects/v1_3/product/search/product_search';
import { filtertag } from "../../objects/v1_3/web_engine/filtertag";
import { product_detail } from '../../objects/v1_3/product/product/product_detail';
import { booking } from '../../objects/v1_2/Booking_Put/booking';
import { week_day } from '../../objects/front_end/calendar/week_day';
import { product_search_product } from '../../objects/v1_3/product/search/product_search_product';

const Book = new Booking;
const Currency_Services = new Currency_Service;
const Prod = new Product;
const Web_Engines = new Web_Engine;

export default defineComponent({
    components: {
        footer_item,
        header_menu,
        hero,
        searchfilter,
        filter_buttons,
        tour_card,
        tour_details,
        embedded_calendar
    },
    props: {
    },
    data() {
        return {
            all_products: new product_search() as product_search,
            product_search: new product_search() as product_search,
            isHidden: true as boolean,
            tour_id: this.$route.query.tour_id as string,
            show: true as boolean,
            trip_index: 0 as number,
            loading_search: false as boolean,
            dep_date_from: new week_day() as week_day,
            dep_date_to: new week_day() as week_day,
            in_cart: new product_search() as product_search,
            //TRAIN TEMPORARY HACK PART
            isTrain: false as boolean,
        }
    },
    methods: {
        async download_product_search() {
            this.loading_search = true;
            this.product_search = new product_search();
            this.all_products = Prod.Filtered_Products(this.$store.getters.get_Basket, await Prod.Get_Product_Search_Filtered(this.$store.getters.get_Search_Filter_Tags, this.$store.getters.get_Search_Tags, this.$store.getters.get_Search_Products, this.dep_date_from.output_date ?? "", this.dep_date_to.output_date ?? ""));
            this.in_cart = Prod.Filtered_out_Products(this.$store.getters.get_Basket, await Prod.Get_Product_Search_Filtered(this.$store.getters.get_Search_Filter_Tags, this.$store.getters.get_Search_Tags, this.$store.getters.get_Search_Products, this.dep_date_from.output_date ?? "", this.dep_date_to.output_date ?? ""));

            this.all_products.products.forEach((product) => {
                if(this.product_search.products.length == 0){
                    this.product_search.products.push(product);
                }
                else{
                    let ignore = false;
                    let index = 0;
                    this.product_search.products.forEach((product_search) => {
                        if(this.FormatForCompare(product_search.name) == this.FormatForCompare(product.name)){
                            if(this.getPrice(product_search) > this.getPrice(product)){
                                this.product_search.products.splice(index, 1);
                                this.product_search.products.push(product);
                            }
                            ignore = true;
                        }
                        index++;
                    });
                    if(!ignore){
                        this.product_search.products.push(product);
                    }

                }
            });
            
            this.loading_search = false;
        },
        //TRAIN TEMPORARY HACK PART
        GetTrainTours(productSearch:  product_search_product[]): product_search_product[] {
            var newList: product_search_product[] = [];
            
            productSearch.forEach(tour => {
                switch(tour.tour_id.toUpperCase()) { 
                    case "IVW": { 
                        newList.push(tour);
                       break; 
                    }
                    case "IW3": { 
                        newList.push(tour);
                       break; 
                    } 
                    case "IW9": { 
                        newList.push(tour);
                       break; 
                    } 
                    case "HIW": { 
                        newList.push(tour);
                       break; 
                    } 
                    default: { 
                       break; 
                    }
                } 
            });
            return newList;
        },
        //TRAIN TEMPORARY HACK PART
        GoHome() {
            this.isTrain = false;
            this.$router.push({ path: "/", query: { allTours: "true" }})
        },
        homeRefresh() {
            this.isTrain = false;
            this.$router.push({ path: "/" });
        },      
        FormatForCompare(name: string): string {
            return name.toLowerCase().replace("(hotel)", "").replace("(b&b)","");
        },
        async Setup_Currency() {
            await Web_Engines.Initialise_Currency(this.$store, this.getOverrideCurrencyFromQueryString());
        },
        getOverrideCurrencyFromQueryString() : string {
            let curcur = '';
            if (this.$route.query.cur != null) {
                curcur = this.$route.query.cur.toString();
            }

            return curcur;
        },
        getPrice(product: product_search_product): number {
            return Prod.product_pricing(Book.Pricing_Age_Category(this.$store), this.$store.getters.get_Currency, product ?? new product_search_product())
        },
        onBookTour(tour_id: string)
        {
            if (tour_id != this.tour_id) {
                this.tour_id = tour_id;
            }

            this.trip_index = this.get_Tour_Length();
            this.$refs.tour_details.download_product(tour_id);
            this.$store.commit('Initialise_Basket', { Currency: this.$store.getters.get_Currency?.currency_info?.iso_currency_code, Trip_Index: this.trip_index, Tour_ID: tour_id });

            this.isHidden = false;
        },
        get_Tour_Length(): number {
            if (this.$store.getters.get_Basket?.trips.length == 1) {
                if (this.$store.getters.get_Basket?.trips[0].tour_id == "" || this.$store.getters.get_Basket?.trips[0].departure_date == "") {
                    return 0;
                }
            }

            return this.$store.getters.get_Basket?.trips.length;
        },
        onFilterChange() {
            this.download_product_search();
        },
        onSearchChanges(dep_date_from: week_day, dep_date_to: week_day) {
            this.dep_date_from = dep_date_from;
            this.dep_date_to = dep_date_to;

            this.download_product_search();
        },
        onTagChanges() {
            this.download_product_search();
        },
        Add_To_Cart() {
            this.$router.push({ path: "/cart" });
        },
        getSearchHeader(): string {
            return import.meta.env.VITE_DEFAULT_HERO_TEXT + " " + Currency_Services.Format_Currency(Prod.get_minimum_price(Book.Pricing_Age_Category(this.$store), this.$store.getters.get_Currency, this.product_search), this.$store.getters.get_Currency) + "pp";
        },
        getLoadingText(): string {
            if(window.location.href.toLowerCase().includes("open")){
                return "Loading your tour";
            }
            else{
                return "Loading Search Information";
            }
        },
        getSearchTitle(): string {
            return String(import.meta.env.VITE_DEFAULT_HERO_TITLE);
        },
        getHeroImage(): string {
            return String(import.meta.env.VITE_DEFAULT_HERO_IMAGE);
        },
        Tour_Selected(travel_date: string, tour_id: string) {
            this.onBookTour(tour_id);
        },
        Reset_Basket() {
            this.$store.commit("Remove_Product", { Tour_ID: this.$store.getters.get_Basket?.trips[this.trip_index].tour_id });
            this.isHidden = true;
        },
        getHeroFont(){
            return String(import.meta.env.VITE_DEFAULT_HERO_FONT);
        },
        URLCheck(tourId: string): Boolean {
            let url = window.location.search.substring(1).replace("%20"," ");
            let params = url.split("&");
            let URLtourid = "";
            
            if(params.length > 0)
            {
                params.forEach((param: string) => {
                    if(param.toLocaleLowerCase().includes("open"))
                    {
                        URLtourid = param.split("=")[1];
                    }
                    if(param.toLocaleLowerCase().includes("alltours"))
                    {
                        this.homeRefresh();
                    }  
                }
                );
                if(tourId.toUpperCase().trim() == URLtourid.toUpperCase().trim())
                {
                    //TRAIN TEMPORARY HACK PART
                    if( URLtourid.toUpperCase().trim() == "IVW" || URLtourid.toUpperCase().trim() == "IW3" || URLtourid.toUpperCase().trim() == "IW9" || URLtourid.toUpperCase().trim() == "HIW")
                     {
                        this.isTrain = true;
                        return false;
                     }
                    return true;
                }
            }

            return false;
        },
        EmbedCheck(): boolean{
            let url = window.location.search.substring(1).replace("%20"," ");

            if(url.includes("embed"))
            {
                return true;
            }
            else
            {
                return false;
            }

        },
        EmbededTour(productSearch:  product_search_product[]): product_search_product {
            let returnProduct = new product_search_product();
            returnProduct.tour_id = "NUL";

            productSearch.forEach(tour => {
                if(this.URLCheck(tour.tour_id))
                {
                    returnProduct = tour;
                }
            });
            return returnProduct;

        },
        CartCountCheck(){
            //counts as 1 item in cart if refreshed when viewed. This checks if passengers are zero and clears basket if they are
            if((this.$store.getters.get_Basket.trips.length + Book.getTotal_Add_Ons(this.$store.getters.get_Basket) == 1) && this.$store.getters.get_Basket.passengers.toString() == ''){
                this.Reset_Basket();
            }
            
        },
        CartCount(): number {
            return this.$store.getters.get_Basket.trips.length;
        },
        includedTours(productSearch:  product_search_product[]): product_search_product[] {
            if (import.meta.env.VITE_EXCLUDED_TOURS == undefined || import.meta.env.VITE_EXCLUDED_TOURS == "") {
                return productSearch;
            }
            

            var newList: product_search_product[] = [];
            var excludedTours = import.meta.env.VITE_EXCLUDED_TOURS.trim().toUpperCase().split(",");

            var str = "";
            var count = 1;
            productSearch.forEach(tour => {
                str = str + "\n" + count + ". " +  tour.tour_id + ": " + tour.name;
                count++;
                
                var addTour = true;
                excludedTours.forEach(excludedTour => {
                    if(excludedTour.trim() == tour.tour_id.toUpperCase() ){
                        addTour = false;
                    }
                });
                if(addTour){
                    newList.push(tour);
                }
            });
            if(this.localUrl()){
                console.log(str);
            }
            
            return newList;


        },
        localUrl(): boolean {
            if (window.location.href.includes("localhost")) {
                return true;
            }
            return false;
        }
    },
    mounted() {
        this.download_product_search();
        this.Setup_Currency();
      
        if (this.tour_id != undefined) {
            this.onBookTour(this.tour_id);
        }
        this.CartCountCheck();
    }
})