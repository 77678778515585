﻿<template>

    <div class="pax dropdown w-full">
        <div id="Passenger_Click_Away" class="mt-1 relative" v-click-away="onClickAway">

            <button type="button" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label" class="select-button" @click="isHidden = !isHidden">
                <div class="left-0 absolute top-5 mx-4 w-4">
                    <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="user" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-user fa-w-14 fa-2x"><path fill="currentColor" d="M313.6 288c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4zM416 464c0 8.8-7.2 16-16 16H48c-8.8 0-16-7.2-16-16v-41.6C32 365.9 77.9 320 134.4 320c19.6 0 39.1 16 89.6 16 50.4 0 70-16 89.6-16 56.5 0 102.4 45.9 102.4 102.4V464zM224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm0-224c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z" class=""></path></svg>
                </div>
                <div class="passengers ml-8">
                    <div><span class="font-semibold text-heading">Passengers:</span> <span class="passenger-adult font-normal ml-1">{{Passenger_Details()}}</span></div>
                </div>
            </button>

            <div class="pax-dropdown relative z-10w-full pb-4" :hidden="isHidden">
                <div class="pax w-full  bg-white shadow-xl rounded px-8" :hidden="add_ons">
                    <ul class="list-none">
                        <li class="flex my-4" v-for="passenger in CategorySort(age_cat.categories)" v-bind:key="passenger.age_cat">
                            <passengers :pass=passenger @passenger_update="onPassengerUpdate"></passengers>
                        </li>
                    </ul>
                    <div class="action clear block text-right py-2">
                        <button class="button button-standard xsmall mr-8" @click="Clear_Passengers()">Clear</button>
                        <button class="button button-standard xsmall" @click="isHidden = true">Done</button>
                    </div>
                </div>
                <div class="pax w-full mt-8 bg-white shadow-xl rounded py-4 px-8" :hidden="!add_ons">
                    <ul>
                        <p class="my-2 mx-8 text-heading font-semibold">Select passengers</p>
                        <li v-for="(passenger, index) in get_Booking()?.passengers">
                            <div class="flex items-center" @click="Toggle_Passenger(tour_id, index)">
                                <input id="register2" name="register" type="checkbox" :value="index" :checked="Add_On_Passenger(tour_id, index)" class="focus:ring-action -mt-1 h-4 w-4 text-action border-grey-light rounded">
                                <label for="register2" class="ml-3 block text-sm cursor-pointer">{{passenger.first_name}}</label>
                            </div>
                        </li>
                    </ul>
                    <div class="action clear block text-right py-2">
                        <button class="btn outline text-action font-bold mx-2" @click="isHidden = true">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script lang="ts" src="./passenger_details.ts"></script>