﻿import { defineComponent } from 'vue';

import header_menu from "../header/menu.vue"
import hero from "../header/hero.vue"
import footer_item from "../base/common/footer.vue"

import basket from "../base/common/basket.vue"

import documentation from "../base/5_confirmation/documentation.vue"
import trip_summary from "../base/5_confirmation/summary.vue"

import { Booking } from "../../services/booking";

import { booking_search_group } from "../../objects/v1_2/Booking_Details/booking_search_group";

export default defineComponent({
    components: {
        footer_item,
        header_menu,
        hero,
        basket,
        documentation,
        trip_summary
    },
    data() {
        return {
            attempts: this.$store.getters.get_Payment_Attempts + 1
        }
    },
    methods: {
        getSubtitle(): string {
            return "Unable to complete transaction" + "</strong>";
        },
        getHeroImage(): string {
            return String(import.meta.env.VITE_DEFAULT_HERO_IMAGE);
        },
        isAgent_Login(): boolean {
            return this.$store.getters.get_Agent_Login?.valid_login == true;
        },
        getConfirmationText(): string {
            return 'There was a problem with your payment';
        },
        get_Contact_Text(): string {
            return  String(import.meta.env.VITE_DEFAULT_CONTACT_TEXT)
        },
        getFailReason(): string {
            let url = window.location.search.substring(1).replace(/%20/g," ").replace(/-/g," ");
            let params = url.split("&");
            let displayText = "An Unknown payment error has occured";

            if(params.length > 0){
                    params.forEach((param: string) => {
                        if(param.toLowerCase().includes("reason"))
                        {
                            let reason = param.split("=")[1];
                            if(reason.toLowerCase().includes("rejected")){
                                displayText = "Your payment has been rejected by your payment provider";
                            }
                        }
                        
                    }
                    );
            }
            return displayText;
        },
        retryPayment(){
            this.$store.commit("Set_Payment_Attempts", { Attempts: this.attempts });
            this.$router.push({ path: "/creditcard" });
        }
    },
    mounted(){
    }
})