﻿<template>
    <div class="summary-price section" :hidden="get_Trip_Total() == get_Total()">
        <div class="row item flex py-1">
            <div class="flex-0 w-60">Trip Price</div>
            <div class="flex-1 text-right">{{get_Trip_Total()}}</div>
        </div>
        <div class="row discount flex py-1" v-if="is_Discounted()">
            <div class="flex-0 w-60">Discount <span>({{get_Discount_Percentage()}})</span></div>
            <div class="flex-1 text-right text-status-success">{{get_Discount()}}</div>
        </div>
        <div class="row subtotal flex py-1" :hidden="!(is_Discounted() == true && hasAddOns == true)">
            <div class="flex-0 w-60">Subtotal</div>
            <div class="flex-1 text-right">{{get_Full_Total()}}</div>
        </div>
        <div class="row item flex py-1" :hidden="!hasAddOns">
            <div class="flex-0 w-60">{{get_AddOnText()}}</div>
            <div class="flex-1 text-right">{{get_AddOn_Total()}}</div>
        </div>
        <div class="row total flex py-1">
            <div class="flex-0 w-60">Total</div>
            <div class="flex-1 text-right">{{get_Total()}}</div>
        </div>
    </div>
</template>

<script lang="ts" src="./trip_summary.ts"></script>