import { room } from "./Room";
import { trip_passenger } from "./Trip_Passenger";

export class add_on {
    public tour_id: string = "";
    public quantity: number = 0;
    public nights_pre_tour: number = 0;
    public nights_post_tour: number = 0;
    public passengers: trip_passenger[] = [];
    public rooms: room[] = [];
}
