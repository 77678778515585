import { client_booking } from './client_booking';
import { booking_trip_room } from "./booking_trip_room";

export class booking_info {
    public booking_identifier: string = "";
    public tour_id: string = "";
    public tour_name: string = "";
    public booking_date: string = "";
    public departure_date: string = "";
    public promo_code: string = "";
    public iso_currency_code: string = "";
    public affiliate_app_id: string = "";
    public affiliate: string = "";
    public passengers: client_booking[] = [];
    public reservation_rooms: booking_trip_room[] = [];
}
