﻿<template>

    <div :id="'passenger_' + passenger.passenger_identifier">
        <!--desktop only-->
        <legend :class="{'hidden': isMobile()}" class="border-grey-lightest block w-full p-4 font-semibold text-heading cursor-pointer" @click="isOpen = !isOpen">
            <span class="icon">
                <svg class="inline -mt-1 mr-2 svg-inline--fa fa-user fa-w-14 fa-2x" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="user" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M313.6 288c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4zM416 464c0 8.8-7.2 16-16 16H48c-8.8 0-16-7.2-16-16v-41.6C32 365.9 77.9 320 134.4 320c19.6 0 39.1 16 89.6 16 50.4 0 70-16 89.6-16 56.5 0 102.4 45.9 102.4 102.4V464zM224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm0-224c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z" class=""></path></svg>
                {{ index+1 }}.
            </span>
            {{passenger.first_name}} {{passenger.family_name}}
            <p class="text-sm ">{{passenger.age_category}} <span v-if="isLeadPax()">(Lead)</span></p>
            <button :class="{ 'hidden': !Passenger_Details_Collected() }" class="float-right button button-standard xsmall rounded mr-6 mb-6" >
                <svg class="inline -mt-1 mr-1 w-4 svg-inline--fa fa-edit fa-w-18 fa-2x" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="edit" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M417.8 315.5l20-20c3.8-3.8 10.2-1.1 10.2 4.2V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h292.3c5.3 0 8 6.5 4.2 10.2l-20 20c-1.1 1.1-2.7 1.8-4.2 1.8H48c-8.8 0-16 7.2-16 16v352c0 8.8 7.2 16 16 16h352c8.8 0 16-7.2 16-16V319.7c0-1.6.6-3.1 1.8-4.2zm145.9-191.2L251.2 436.8l-99.9 11.1c-13.4 1.5-24.7-9.8-23.2-23.2l11.1-99.9L451.7 12.3c16.4-16.4 43-16.4 59.4 0l52.6 52.6c16.4 16.4 16.4 43 0 59.4zm-93.6 48.4L403.4 106 169.8 339.5l-8.3 75.1 75.1-8.3 233.5-233.6zm71-85.2l-52.6-52.6c-3.8-3.8-10.2-4-14.1 0L426 83.3l66.7 66.7 48.4-48.4c3.9-3.8 3.9-10.2 0-14.1z" class=""></path></svg>
                edit
            </button>
            <!--AAT message-->
            <div v-if="isAgent_Login()">
                <span class="pax-register text-status-warning mr-12 font-normal float-right" :class="{ 'hidden': Passenger_Details_Collected()}">Add required guest details</span>
                <span class="pax-register text-status-success mr-12 font-normal float-right" :class="{ 'hidden': !Passenger_Details_Collected() }">Required guest details added</span>
            </div >
            <div v-else>
                <!--Hex message-->
                <span class="pax-register text-status-warning mr-12 font-normal float-right" :class="{ 'hidden': Passenger_Details_Collected()}">Some passenger details missing</span>
                <span class="pax-register text-status-success mr-12 font-normal float-right" :class="{ 'hidden': !Passenger_Details_Collected()}">Required details completed</span>
            </div>
            <p :class="{ 'hidden': Passenger_Details_Collected() }"> Please complete all required fields.</p>
        </legend>

        <!--Mobile only-->
        <legend :class="{'hidden': !isMobile()}" class="border-grey-lightest block w-full p-4 font-semibold text-heading cursor-pointer" @click="isOpen = !isOpen">
            <span class="icon">
                <svg class="inline -mt-1 mr-2 svg-inline--fa fa-user fa-w-14 fa-2x" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="user" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M313.6 288c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4zM416 464c0 8.8-7.2 16-16 16H48c-8.8 0-16-7.2-16-16v-41.6C32 365.9 77.9 320 134.4 320c19.6 0 39.1 16 89.6 16 50.4 0 70-16 89.6-16 56.5 0 102.4 45.9 102.4 102.4V464zM224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm0-224c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z" class=""></path></svg>
            </span>
            {{passenger.first_name}} {{passenger.family_name}}
            <div class="mt-4">
                <span class="pax-register text-status-warning font-normal float-left" :class="{ 'hidden': Passenger_Details_Collected() }">Add required guest details</span>
                <span class="pax-register text-status-success font-normal float-left" :class="{ 'hidden': !Passenger_Details_Collected() }">Required guest details added</span>
                <button :class="{ 'hidden': !Passenger_Details_Collected() }" class="float-right button button-standard xsmall rounded mb-6" @click="Update_Details">
                    <svg class="inline -mt-1 mr-1 w-4 svg-inline--fa fa-edit fa-w-18 fa-2x" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="edit" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M417.8 315.5l20-20c3.8-3.8 10.2-1.1 10.2 4.2V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h292.3c5.3 0 8 6.5 4.2 10.2l-20 20c-1.1 1.1-2.7 1.8-4.2 1.8H48c-8.8 0-16 7.2-16 16v352c0 8.8 7.2 16 16 16h352c8.8 0 16-7.2 16-16V319.7c0-1.6.6-3.1 1.8-4.2zm145.9-191.2L251.2 436.8l-99.9 11.1c-13.4 1.5-24.7-9.8-23.2-23.2l11.1-99.9L451.7 12.3c16.4-16.4 43-16.4 59.4 0l52.6 52.6c16.4 16.4 16.4 43 0 59.4zm-93.6 48.4L403.4 106 169.8 339.5l-8.3 75.1 75.1-8.3 233.5-233.6zm71-85.2l-52.6-52.6c-3.8-3.8-10.2-4-14.1 0L426 83.3l66.7 66.7 48.4-48.4c3.9-3.8 3.9-10.2 0-14.1z" class=""></path></svg>
                    edit
                </button>
            </div>
            
        </legend>

        <div class="form-contents" :class="{hidden: !isOpen }">
            <!--auto fills test info, only shows on staging-->
            <button v-if="getIsDebug()" @click="autofill()" class="float-centre button button-standard xsmall rounded mr-6 mb-6" >
                <svg class="inline -mt-1 mr-1 w-4 svg-inline--fa fa-edit fa-w-18 fa-2x" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="edit" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M417.8 315.5l20-20c3.8-3.8 10.2-1.1 10.2 4.2V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h292.3c5.3 0 8 6.5 4.2 10.2l-20 20c-1.1 1.1-2.7 1.8-4.2 1.8H48c-8.8 0-16 7.2-16 16v352c0 8.8 7.2 16 16 16h352c8.8 0 16-7.2 16-16V319.7c0-1.6.6-3.1 1.8-4.2zm145.9-191.2L251.2 436.8l-99.9 11.1c-13.4 1.5-24.7-9.8-23.2-23.2l11.1-99.9L451.7 12.3c16.4-16.4 43-16.4 59.4 0l52.6 52.6c16.4 16.4 16.4 43 0 59.4zm-93.6 48.4L403.4 106 169.8 339.5l-8.3 75.1 75.1-8.3 233.5-233.6zm71-85.2l-52.6-52.6c-3.8-3.8-10.2-4-14.1 0L426 83.3l66.7 66.7 48.4-48.4c3.9-3.8 3.9-10.2 0-14.1z" class=""></path></svg>
                Auto-fill (DEBUG ONLY)
            </button>
            <div class="row flex flex-col lg:flex-row">
                <div class="form-group float-label w-full" :class="{ 'hidden': hide_field('first_name') }">
                    <input type="text" name="fname" id="fname" :required="require_field('first_name')" spellcheck="false" v-capitalise v-model="passenger.first_name" maxlength="30"/>
                    <span class="placeholder">Name</span>
                    <span class="text-2xl text-status-warning" :class="{ hidden: require_field('first_name') == false }">* </span>
                    <span :class="{ hidden: require_field('first_name') == false }">Required</span>
                </div>
                <div class="form-group float-label w-full" :class="{ 'hidden': hide_field('family_name') }">
                    <input type="text" name="lname" id="lname" :required="require_field('family_name')" spellcheck="false" v-capitalise v-model="passenger.family_name" maxlength="30"/>
                    <span class="placeholder">Surname</span>
                    <span class="text-2xl text-status-warning" :class="{ hidden: require_field('family_name') == false }">* </span>
                    <span :class="{ hidden: require_field('family_name') == false }">Required</span>
                </div>
            </div>

            <div class="row flex flex-col lg:flex-row">
                <div v-if="isAgent_Login()" class="form-group float-label w-full" :class="{ 'hidden': hide_field('telephone_number') }">
                    <div class="relative rounded" >
                        <input type="text" :required="require_field('telephone_number')" name="telephone" id="telephone" v-model="passenger.telephone_number" />
                        <span class="placeholder">Passenger Mobile Phone Number</span>
                        <span class="text-2xl text-status-warning" :class="{ hidden: require_field('telephone_number') == false }">* </span>
                    <span :class="{ hidden: require_field('telephone_number') == false }">Required</span>
                    </div>
                </div>
                <div v-else class="flex grid-cols-2 form-group float-label w-full" :class="{ 'hidden': hide_field('telephone_number') }">
                    <div class=" w-2/5" >
                        <select id="dialCode" name="dialCode" autocomplete="dialCode" v-model="dialCodeLead" onclick="this.setAttribute('value', this.value);" value="" >
                            <option></option>
                            <option v-for="country in getDialingCodes(countries)">{{country.country}} (+{{ country.dialing_code }})</option>
                        </select>
                        <span class="placeholder"></span>
                        <span class="text-2xl text-status-warning" :class="{ hidden: require_field('telephone_number') == false }">* </span>
                        <span :class="{ hidden: require_field('telephone_number') == false }">Required</span>
                    </div>
                    <div class="w-3/5" >
                        <input type="text" :required="require_field('telephone_number')" name="telephone" id="telephone" v-model="dialNumberLead" />
                        <span class="placeholder">Passenger Mobile Phone Number</span>
                    </div>
                </div>
                <div class="form-group float-label w-full" :class="{ 'hidden': hide_field('date_of_birth') }">
                    <div :hidden="true">{{date_of_birth = formatDOB()}} </div>
                    <input type="text" placeholder="" :required="require_field('date_of_birth')" spellcheck="false"   v-model="date_of_birth">
                    <span class="placeholder">Date of Birth <em class="px-2 text-grey-light not-italic">dd-mm-yyyy</em></span>
                    <span class="text-2xl text-status-warning" :class="{ hidden: require_field('date_of_birth') == false }">* </span>
                    <span v-if="DoBValid" :class="{ hidden: require_field('date_of_birth') == false }">Required</span>
                    <span class="text-xl text-status-warning" v-if="!DoBValid">Please enter a valid Date of Birth</span>
                </div>
            </div>
            <div class="row flex flex-col lg:flex-row">
                <div class="form-group float-label w-full option-group alt" :class="{ 'hidden': hide_field('gender') }">
                    <div class="flex flex-row" x-ref="radiogroup">
                        <div class="item">
                            <div class="option-input">
                                <input id="Male" name="gender" type="radio" @click="setGender('Male')">
                            </div>
                            <div class="option-content">
                                <label for="Male">Male</label>
                            </div>
                        </div>

                        <div class="item">
                            <div class="option-input" >
                                <input id="Female" name="gender" type="radio"  @click="setGender('Female')">
                            </div>
                            <div class="option-content">
                                <label for="Female">Female</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group float-label w-full" :class="{ 'hidden': hide_field('iso_nationality') }">
                    <select id="iso_nationality" name="iso_nationality" autocomplete="iso_nationality" v-model="countryName" onclick="this.setAttribute('value', this.value);" value="" :required="require_field('iso_nationality')">
                        <option></option>
                        <option v-for="country in countries">{{country.country}}</option>
                    </select>
                    <span class="placeholder">Nationality</span>
                    <span class="text-2xl text-status-warning" :class="{ hidden: require_field('iso_nationality') == false }">* </span>
                    <span :class="{ hidden: require_field('iso_nationality') == false }">Required</span>
                </div>
            </div>

            <div class="row flex flex-col lg:flex-row">
                <div class="form-group float-label w-full" :class="{ 'hidden': hide_field('email') }">
                    <input type="text" name="email" id="email" placeholder="" :required="require_field('email')" spellcheck="false" v-model="passenger.email" />
                    <span class="placeholder">E-mail</span>
                    <span class="text-2xl text-status-warning"  :class="{ hidden: require_field('email') == false }">* </span>
                    <span :class="{ hidden: require_field('email') == false }">Required</span>
                </div>
                <div class="form-group float-label w-full" :class="{ 'hidden': hide_field('email') }">
                    <input type="text" name="confirm-email" id="confirm-email" placeholder="" :required="require_field('email')" spellcheck="false" v-model="confirm_email"/>
                    <span class="placeholder">Confirm E-mail</span>
                    <span class="text-2xl text-status-warning"  :class="{ hidden: require_field('email') == false }">* </span>
                    <span :class="{ hidden: require_field('email') == false }">Required</span>                    
                </div>
            </div>
            <div class="row flex flex-col lg:flex-row">
                <div class="form-group float-label w-full" :class="{ 'hidden': passenger?.email == confirm_email }">
                    <span class="text-xl text-status-warning"> E-mail addresses do not match </span>
                </div>
            </div>
            <div class="row flex flex-col lg:flex-row">
                <div class="form-group float-label w-full" :class="{ 'hidden': hide_field('emergency_contact') }">
                    <input type="text" name="fname" id="fname" :required="require_field('emergency_contact')" spellcheck="false" v-capitalise v-model="passenger.emergency_contact" maxlength="30"/>
                    <span class="placeholder">Emergency contact Name</span>
                    <span class="text-2xl text-status-warning" :class="{ hidden: require_field('emergency_contact') == false }">* </span>
                    <span :class="{ hidden: require_field('emergency_contact') == false }">Required</span>
                </div>
                <div v-if="isAgent_Login()" class="form-group float-label w-full" :class="{ 'hidden': hide_field('emergency_number') }">
                    <input type="text" name="emergency_number" id="emergency_number" placeholder="" :required="require_field('emergency_number')" spellcheck="false" v-model="passenger.emergency_number" />
                    <span class="placeholder">Emergency Phone Number</span>
                    <span class="text-2xl text-status-warning" :class="{ hidden: require_field('emergency_number') == false }">* </span>
                    <span :class="{ hidden: require_field('emergency_number') == false }">Required</span>             
                </div>
                <div v-else class="flex grid-cols-2 form-group float-label w-full" :class="{ 'hidden': hide_field('emergency_contact') }">
                    <div class=" w-2/5" >
                        <select id="emerdialCode" name="emerdialCode" autocomplete="emerdialCode" v-model="dialCodeEmerg" onclick="this.setAttribute('value', this.value);" value="" >
                            <option></option>
                            <option v-for="country in getDialingCodes(countries)">{{country.country}} (+{{ country.dialing_code }})</option>
                        </select>
                        <span class="placeholder"></span>
                        <span class="text-2xl text-status-warning" :class="{ hidden: require_field('emergency_number') == false }">* </span>
                        <span :class="{ hidden: require_field('emergency_number') == false }">Required</span>
                    </div>
                    <div class="w-3/5" >
                        <input type="text" :required="require_field('emergency_number')" name="emergency_number" id="emergency_number" v-model="dialNumberEmerg" />
                        <span class="placeholder">Emergency Phone Number</span>
                    </div>
                </div>

            </div>

            <div class="row flex flex-col lg:flex-row">
                <div class="form-group float-label w-full" :class="{ 'hidden': hide_field('post_code') }">
                    <input type="text" name="post_code" id="post_code" placeholder="" :required="require_field('post_code')" spellcheck="false" v-model="passenger.post_code" />
                    <span class="placeholder">Post Code</span>
                    <span class="text-2xl text-status-warning" :class="{ hidden: require_field('post_code') == false }">* </span>
                    <span :class="{ hidden: require_field('post_code') == false }">Required</span>
                </div>
                <div class="form-group float-label w-full">
                </div>
            </div>

            <div class="row flex flex-col lg:flex-row" v-if="!isAgent_Login() && require_field('email')">
                <div class="flex my-4 mx-4 ">
                    <input v-model="newsletterCheckbox" type="checkbox" class="focus:ring-action text-action border-grey-light rounded mr-5 mt-1 cursor-pointer ">
                    <label class="font-bold">Subscribe to our newsletter</label>
                    <!--<label class="font-bold">Get 10% off your next trip when signing up for our newsletter</label>-->
                </div>
            </div>


            <div class="row">
                <div class="form-group w-full">
                    <button v-if="passenger?.email == confirm_email" class="button button-standard small rounded" @click="Update_Details">
                        <svg class="animate-spin h-5 w-5 mr-3 inline" :class="{ hidden: !saving_passenger }" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 512 512" stroke="currentColor">
                            <path fill="currentColor" d="M440.65 12.57l4 82.77A247.16 247.16 0 0 0 255.83 8C134.73 8 33.91 94.92 12.29 209.82A12 12 0 0 0 24.09 224h49.05a12 12 0 0 0 11.67-9.26 175.91 175.91 0 0 1 317-56.94l-101.46-4.86a12 12 0 0 0-12.57 12v47.41a12 12 0 0 0 12 12H500a12 12 0 0 0 12-12V12a12 12 0 0 0-12-12h-47.37a12 12 0 0 0-11.98 12.57zM255.83 432a175.61 175.61 0 0 1-146-77.8l101.8 4.87a12 12 0 0 0 12.57-12v-47.4a12 12 0 0 0-12-12H12a12 12 0 0 0-12 12V500a12 12 0 0 0 12 12h47.35a12 12 0 0 0 12-12.6l-4.15-82.57A247.17 247.17 0 0 0 255.83 504c121.11 0 221.93-86.92 243.55-201.82a12 12 0 0 0-11.8-14.18h-49.05a12 12 0 0 0-11.67 9.26A175.86 175.86 0 0 1 255.83 432z" class=""></path>
                        </svg>
                        Save
                    </button>
                    <button v-else class="button button-standard small rounded" disabled>
                        Save
                    </button>
                </div>
            </div>

        </div>
    </div>
</template>

<script lang="ts" src="./passenger.ts"></script>