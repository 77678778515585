﻿import { product_price } from "../product/product_price";
import { capacity_capacity_room } from "./capacity_capacity_room";

export class capacity_capacity {
    public start_date_time: string = "";
    public end_date_time: string = "";
    public start_date_time_utc: string = "";
    public end_date_time_utc: string = "";
    public tour_id: string = "";
    public free_seats: number = 0;
    public on_request: boolean = false;
    public free_sale: boolean = false;
    public product_currencies: product_price[] = [];
    public capacity_rooms: capacity_capacity_room[] = [];
}