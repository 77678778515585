export class passenger {
    public passenger_identifier: string | null = null;
    public age_category: string | null = null;
    public first_name: string | null = null;
    public family_name: string | null = null;
    public email: string | null = null;
    public gender: string | null = null;
    public date_of_birth: string | null = null;
    public iso_nationality: string | null = null;
    public iso_nationality_sate: string | null = null;
    public telephone_country_code: string | null = null;
    public telephone_number: string | null = null;
    public contact_telephone_number: string | null = null;
    public emergency_contact: string | null = null;
    public emergency_country_Code: string | null = null;
    public emergency_number: string | null = null;
    public post_code: string | null = null;
    public required_fields: string[] = [];
    public optional_fields: string[] = [];
    public isValid: boolean = false;
}