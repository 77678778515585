﻿<template>

    <header class="header">
        <header_menu></header_menu>
        <h1>My Profile</h1>
        <div class="grid grid-cols-2">
            <div class="col-span-2 p-2 m-2 border-2 rounded border-black"><consultant_information :selected_contact="Get_Contact()"></consultant_information></div>
            <div class="border-2 p-2 m-2 rounded border-black"><account_information></account_information></div>
            <div class="border-2 p-2 m-2 rounded border-black"><change_account_password></change_account_password></div>
        </div>
    </header>

</template>

<script lang="ts" src="./my_profile.ts"></script>