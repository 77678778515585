﻿import { defineComponent } from 'vue';

export default defineComponent({
    components: {
    },
    props: {
    },
    data() {
    },
    methods: {
    },
    mounted() {
    }
});