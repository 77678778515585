﻿<template>

    <div class="summary-terms section pt-8">
        <div class="row">
            <div class="flex items-center">
                <input id="register44" name="register44" type="checkbox" :checked="checked_terms" class="focus:ring-action h-4 w-4 text-action border-grey-light rounded" @click="checked_terms = !checked_terms">
                <label for="register44" class="ml-3 block font-semibold text-sm text-black">
                    I have explained the booking <a :href="terms_policy()" class="inline underline text-black">terms and conditions</a> to the client*
                </label>
            </div>
        </div>
    </div>

    <div class="summary-action section">
        <div class="row">
            <button type="button" class="button button-book large full" @click="Full_Payment()">
                <svg class="animate-spin h-5 w-5 mr-3 inline" :class="{'hidden': full_payment_now == false}" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 512 512" stroke="currentColor">
                    <path fill="currentColor" d="M440.65 12.57l4 82.77A247.16 247.16 0 0 0 255.83 8C134.73 8 33.91 94.92 12.29 209.82A12 12 0 0 0 24.09 224h49.05a12 12 0 0 0 11.67-9.26 175.91 175.91 0 0 1 317-56.94l-101.46-4.86a12 12 0 0 0-12.57 12v47.41a12 12 0 0 0 12 12H500a12 12 0 0 0 12-12V12a12 12 0 0 0-12-12h-47.37a12 12 0 0 0-11.98 12.57zM255.83 432a175.61 175.61 0 0 1-146-77.8l101.8 4.87a12 12 0 0 0 12.57-12v-47.4a12 12 0 0 0-12-12H12a12 12 0 0 0-12 12V500a12 12 0 0 0 12 12h47.35a12 12 0 0 0 12-12.6l-4.15-82.57A247.17 247.17 0 0 0 255.83 504c121.11 0 221.93-86.92 243.55-201.82a12 12 0 0 0-11.8-14.18h-49.05a12 12 0 0 0-11.67 9.26A175.86 175.86 0 0 1 255.83 432z" class=""></path>
                </svg>
                Full Payment Amount of {{get_Nett_Total()}}
            </button>
        </div>
        <div class="row" :hidden="!isDeposit" >
            <button type="button" class="button button-book large full" @click="Deposit_Payment()">
                <svg class="animate-spin h-5 w-5 mr-3 inline" :class="{'hidden': !deposit_payment_now}" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 512 512" stroke="currentColor">
                    <path fill="currentColor" d="M440.65 12.57l4 82.77A247.16 247.16 0 0 0 255.83 8C134.73 8 33.91 94.92 12.29 209.82A12 12 0 0 0 24.09 224h49.05a12 12 0 0 0 11.67-9.26 175.91 175.91 0 0 1 317-56.94l-101.46-4.86a12 12 0 0 0-12.57 12v47.41a12 12 0 0 0 12 12H500a12 12 0 0 0 12-12V12a12 12 0 0 0-12-12h-47.37a12 12 0 0 0-11.98 12.57zM255.83 432a175.61 175.61 0 0 1-146-77.8l101.8 4.87a12 12 0 0 0 12.57-12v-47.4a12 12 0 0 0-12-12H12a12 12 0 0 0-12 12V500a12 12 0 0 0 12 12h47.35a12 12 0 0 0 12-12.6l-4.15-82.57A247.17 247.17 0 0 0 255.83 504c121.11 0 221.93-86.92 243.55-201.82a12 12 0 0 0-11.8-14.18h-49.05a12 12 0 0 0-11.67 9.26A175.86 175.86 0 0 1 255.83 432z" class=""></path>
                </svg>
                Deposit Payment Amount of {{get_Deposit_Total()}}
            </button>
        </div>
    </div>

</template>

<script lang="ts" src="./payment_confirmation.ts"></script>