﻿<template>

    <h6>{{trip.tour_name}} {{Departure_Date()}}</h6>

    <div class="grid grid-cols-4 p-2 m-2">
        <div></div>
        <div class="text-right">Commission</div>
        <div class="text-right">Price</div>
        <div class="text-right">Cancel</div>
        <booking_line_passenger :trip_passenger="trip_passenger" v-for="trip_passenger in trip.passengers"></booking_line_passenger>
    </div>

</template>

<script lang="ts" src="./booking_line.ts"></script>