﻿import { defineComponent, PropType } from 'vue';

import select_passengers from "../common/passenger/select_passengers.vue";
import tour from "./tour.vue";

import { trip } from "../../../objects/v1_2/Booking/Trip";

import { product_search } from "../../../objects/v1_3/product/search/product_search";
import { product_search_product } from "../../../objects/v1_3/product/search/product_search_product";

import { Booking } from '../../../services/booking';
import { Product } from '../../../services/product';
import { Currency_Service } from "../../../../system/services/currency";
import { passenger } from '../../../objects/v1_2/Booking/Passenger';

const Book = new Booking();
const Prod = new Product();
const Currency_Services = new Currency_Service();

export default defineComponent({
    name: "add-ons",
    components: {
        select_passengers,
        tour
    },
    data() {
        return {
            product_search: new product_search() as product_search
        }
    },
    methods: {
        async Download_Product_Search() {
            this.product_search = await Prod.Get_Product_Search();
        },
        get_Tours(): product_search_product[] {
            let Output: product_search_product[] = [];  
            let Index: number = 0;

            Prod.Filtered_Products(this.$store.getters.get_Basket, this.product_search).products.forEach(product => {
                if (Index < 2) {
                    
                    if(this.releventTour(product.tour_id)){
                        Output.push(product);
                        Index++;
                    }
                }
            });

            return Output;
        },
        releventTour(tour_id : string): boolean {
            let productDetails = Prod.Get_Details_Sync(tour_id, this.$store);
            let basketTour = Prod.Get_Details_Sync(this.$store.getters.get_Basket.trips[0].tour_id, this.$store);
            let returnVal: boolean = true;

            productDetails.seller_information.add_ons.forEach(addon => {
                basketTour.seller_information.add_ons.forEach(basketAddon => {
                    if(addon.name == addon.name){
                        returnVal = false;
                        return;
                    }
                });

            });

            if(this.NotBlankString(productDetails.operational_information.location_start.city) && this.NotBlankString(basketTour.operational_information.location_start.city)){
                if(productDetails.operational_information.location_start.city != basketTour.operational_information.location_start.city){
                    returnVal = false;
                }
            }
            return returnVal;
        },
        NotBlankString(Str: string): boolean {
            if (Str == "" || Str == null || Str == undefined) {
                return false;
            }
            else{
                return true;
            }
        },
        View_All_Tours() {
            this.$router.push({ path: "/" });
        },
        DayTourAdded(): boolean {
            let returnVal: boolean = false;

            if(this.isAgent_Login()){
                return returnVal;
            }
            
            this.$store.getters.get_Basket.trips.forEach(trip => {
                if(trip.rooms.length == 0){
                    returnVal = true;
                }
            });
            return returnVal;
        },
        isAgent_Login(): boolean {
            return this.$store.getters.get_Agent_Login?.valid_login == true;
        }
    },
    mounted() {
        this.Download_Product_Search();
    }
})