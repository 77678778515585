﻿import { defineComponent } from 'vue';

import { request_password_reset } from "../../booking_engine/objects/agent/identity/v1_3/reguest_password_reset"
import { response_password_reset } from "../../booking_engine/objects/agent/identity/v1_3/response_password_reset"

import { Agent_Login } from "../../booking_engine/services/agent_login";

let Login: Agent_Login = new Agent_Login();

export default defineComponent({
    components: {
    },
    props: {
    },
    data() {
        return {
            Request_Password_Reset: new request_password_reset()
        }
    },
    methods: {
        async Reset_Password() {
            let Response_Password_Reset: response_password_reset = await Login.Password_Reset(this.Request_Password_Reset);

            if (Response_Password_Reset.reset_sucessful == false) {
                window.alert("Unable to reset password :" + Response_Password_Reset.message);
            } else {
                this.$router.push('/login');
            }
        },
        Register() {
            this.$router.push('/login/register');
        },
        Login() {
            this.$router.push('/login');
        },
        getContactInfo(): string {
            if (import.meta.env.VITE_DEFAULT_CONTACT_LOGIN_TEXT != undefined) {
                return String(import.meta.env.VITE_DEFAULT_CONTACT_LOGIN_TEXT);
            }
            else {
                return String(import.meta.env.VITE_DEFAULT_CONTACT_TEXT);
            }
        }
    },
    mounted() {
    }
});