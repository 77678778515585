﻿<template>
    <div class="w-48">Name</div>
    <div>{{pax.first_name}} {{pax.family_name}}</div>
    <div class="w-48" :hidden="!Lead_Passenger()">Email</div>
    <div :hidden="!Lead_Passenger()">{{pax.email}}</div>
    <div class="w-48" :hidden="!Lead_Passenger()">Phone Number</div>
    <div :hidden="!Lead_Passenger()">{{pax.telephone_number}}</div>
    <div class="w-48" :hidden="!Lead_Passenger()">Postcode</div>
    <div :hidden="!Lead_Passenger()">{{pax.post_code}}</div>
</template>

<script lang="ts" src="./passenger.ts"></script>