﻿import { defineComponent, PropType } from 'vue';

import { response_login } from '../../../objects/agent/identity/v1_3/response_login';
import { response_login_payment } from '../../../objects/agent/identity/v1_3/response_login_payment';

export default defineComponent({
    name: "payment_methods",
    props: {
    },
    data() {
        return {
            isOpen: false as Boolean,
            agent_reference: "" as string,
            selected_payment_method: new response_login_payment() as response_login_payment
        }
    },
    methods: {
        display_paymeny_methods(): boolean {
            return (this.$store.getters.get_Agent_Login?.payment_methods?.length > 0);
        },
        Payment_Method_Selected(payment_method: response_login_payment) {
            this.selected_payment_method = payment_method;
            this.Update_Payment_Method();
            this.isOpen = false;
        },
        Initialise_Payment() {
            if (this.$store.getters.get_Agent_Login?.payment_methods?.length > 0) {
                this.selected_payment_method = this.$store.getters.get_Agent_Login.payment_methods[0];
                this.Update_Payment_Method();
            }
        },
        Update_Payment_Method() {
            this.$store.commit("Set_Payment_Selected", { Selected_Payment: this.selected_payment_method});
            this.$emit("updateMethod", this.selected_payment_method);
        }
    },
    mounted() {
        this.Initialise_Payment();
    }
})