﻿import { response_login_contact } from "./response_login_contact";
import { response_login_agency } from "./response_login_agency";
import { response_login_payment } from "./response_login_payment";
import { reaponse_login_token } from "./response_login_token";

export class response_login {
    public valid_login: boolean = false;
    public error_message: string = "";
    public agency: response_login_agency = new response_login_agency();
    public selected_contact: response_login_contact = new response_login_contact();
    public contacts: response_login_contact[] = [];
    public payment_methods: response_login_payment[] = [];
    public token: reaponse_login_token = new reaponse_login_token();
}