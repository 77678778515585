import { defineComponent } from 'vue';
declare global { interface Window { ttcPaymentgateway: any; } }

import { Booking } from "../../services/booking";

const Book: Booking = new Booking();

import header_menu from "../header/menu.vue"
import footer_item from "../base/common/footer.vue"
import basket_header from "../base/common/header.vue"
import hero from "../header/hero.vue"

import payment_confirmation from "../base/4a_Payment/payment_confirmation.vue"
import payment_credit_card from "../base/4a_Payment/payment_credit_card.vue"
import payment_method from "../base/4a_Payment/payment_method.vue"
import payment_summary from "../base/4a_Payment/payment_summary.vue"

import { response_login_payment } from '../../objects/agent/identity/v1_3/response_login_payment';

export default defineComponent({
    components: {
        footer_item,
        header_menu,
        basket_header,
        payment_confirmation,
        payment_credit_card,
        payment_method,
        payment_summary,
        hero
    },
    data() {
        return {
            selected_payment_method: new response_login_payment() as response_login_payment
        }
    },
    methods: {
        async Update_Payment(full_payment: boolean, deposit_payment: boolean) {
            if (this.$refs.payment_method.agent_reference != "") {
                await Book.Update_Booking_Agent_Payment(this.$store.getters.get_Booking, this.$store.getters.get_Agent_Login, this.$refs.payment_method.selected_payment_method.method, this.$refs.payment_method.agent_reference, this.$store.getters.get_Currency, full_payment, deposit_payment);

                this.$router.push({ path: "/confirmation" });
            } else {
                window.alert("Please enter an agent reference.");
            }
        },
        Update_Method(payment_method: response_login_payment) {
            console.log("Payment Metod received");
            console.log("NETT-" + payment_method?.nett_gross);

            this.selected_payment_method = payment_method;
        },
        Get_Payment_Method(): string {
            return this.selected_payment_method.method ?? "";
        },
        Display_Agent_Payment_Summary(): boolean {
            return this.$store.getters.get_Agent_Login?.payment_methods?.length > 0;
        },
        isValidView(): boolean {
            if(this.$store.getters.get_Booking?.group_booking_id == undefined || this.$store.getters.get_Booking?.group_booking_id == 0 || this.$store.getters.get_Booking?.group_booking_id == null) {
                return false;
            }
            return true;
        },
        getHeroImage(): string {
            return String(import.meta.env.VITE_DEFAULT_HERO_IMAGE);
        },
        home_Click() {
            this.$router.push('/');
        },
        isAgent_Login(): boolean {
            return this.$store.getters.get_Agent_Login?.valid_login == true;
        },
        GetBrand(): string {
            return String(import.meta.env.VITE_DEFAULT_CLASS);
        }
    },
    mounted() {
    }
})