﻿<template>
    <img :hidden="!banner" class="h-80 w-full object-cover -mt-2 rounded-md"  :src="get_Image(product.tour_id,)" :alt="imageAltText()" />
    <div id="tours" class="row w-full xl:w-10/12 mx-auto mt-5">

        <h2 class="tourTitle">{{product.name}}</h2>
        <strong v-if="isAAT()">{{product?.operational_information?.duration.days}} Day Tour Itinerary &amp; Map</strong>
        <strong v-else>{{durationInDays()}} Tour Itinerary &amp; Map</strong>

        <p>
            <a :href="product.marketing_information.web_url" target="_blank" class="more" :hidden="!(product.marketing_information.web_url != '' && product.marketing_information.web_url != null)" >See full tour details</a>
        </p>       

        <div class="tour-itinerary flex flex-col md:flex-row mt-12">

            <div class="tour-content w-full md:w-7/12 md:mr-4">

                <div class="tour-days">
                    <h4 class="tour-day-title">{{this.product?.marketing_information?.description_headline}}</h4>
                    <p class="mt-0 text-l">{{this.product?.marketing_information?.description.replace(/<\/?[^>]+(>|$)/g, "")}}</p>
                </div>

                <div class="tour-days" v-for="itinerary in this.product?.marketing_information?.itineraries">                    
                    <h4 class="tour-day-title">{{itinerary.name}}</h4>
                    <div class="tour-day-text">
                        <p v-for="paragraph in FormatDescription(itinerary.description)">{{paragraph}}</p>
                    </div>
                </div>

            </div>

            <div class="tour-meeting w-full md:w-5/12 md:pl-8">
                <div class="tour-map mt-8 md:mt-0 mb-8">
                <img v-if="get_Map()" :src="get_Map()" alt="tour map" />
                </div>

                <div class="tour-address" v-if="hideLocationBox()">
                    <div v-if="product?.operational_information?.location_start?.url != undefined">
                        <h6>Departure Location</h6>
                        <p>{{product?.operational_information?.location_start?.title}}</p>
                        <iframe width="100%" height="340" id="gmap_canvas" :src="product?.operational_information?.location_start?.url" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" allowfullscreen=""></iframe>
                    </div>
                    <h6 v-if="product?.operational_information?.location_start?.description">Departure Point Description</h6>
                    <p>{{product?.operational_information?.location_start?.description}}</p>
                    <div v-if="product?.operational_information?.location_start?.checkInTime != undefined">
                        <h6>Check-in Time</h6>
                        <p>Check-in closes at {{product?.operational_information?.location_start?.checkInTime}}</p>
                    </div>
                    <h6 v-if="product?.operational_information?.location_start?.time">Departure Time</h6>
                    <p v-if="product?.operational_information?.location_start?.time">Tour departs {{product?.operational_information?.location_start?.time}}</p>
                    <h6 v-if="product?.operational_information?.location_end?.time">Return</h6>
                    <p v-if="product?.operational_information?.location_end?.time">Tour returns at {{product?.operational_information?.location_end?.time}}</p>
                </div>

            </div>


        </div>


    </div>

</template>

<script lang="ts" src="./itinerary.ts"></script>