﻿<template>

    <div class="hero compact">
        <div class="hero-inner">
            <!-- Hero Text-->
            <div v-if="isMobile()" class="hero-text">
                <div v-if="isAgent_Login()">
                    <h1 class="title drop-shadow-[0_8px_8px_rgba(0,0,0,10)]" v-html="title"></h1>
                    <p class="subtitle drop-shadow-[0_4px_4px_rgba(0,0,0,10)]" v-html="subtitle"></p>
                </div>
                <div class="text-center" v-if="!isAgent_Login()">
                    <h1 class="title drop-shadow-[0_8px_8px_rgba(0,0,0,10)]" v-html="title"></h1>
                    <p class="subtitle drop-shadow-[0_4px_4px_rgba(0,0,0,10)]" v-html="subtitle"></p>
                </div>
            </div>
            <div v-if="!isMobile()" class="hero-text">
                <h1 class="title drop-shadow-[0_8px_8px_rgba(0,0,0,10)]" v-html="title"></h1>
                <p class="subtitle drop-shadow-[0_4px_4px_rgba(0,0,0,10)]" v-html="subtitle"></p>
            </div>
        </div>  <!-- end .hero-inner -->
        <!--hero image -->
        <div class="hero-image">
            <img :src="hero_image" sizes="33vw" alt="Image description" class="h-full w-full object-cover" />
        </div>
    </div>

</template>

<script lang="ts" src="./hero.ts"></script>