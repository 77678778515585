﻿<template>
    <div class="w-full">
        
        <!--If statement for when passanger count if > 5 to change formatting to rows-->
        <div class="mt-1 relative">
            <!--Desktop View-->
            <div class="flex flex-wrap" :class="{'hidden': isMobile()}">
                <div  v-for="(passenger, index) in passengers" @click="Toggle_Passenger(index)">
                    <div class="flex items-center">
                        <input :id="Control_Name(index)" type="checkbox" :checked="checkTickBox(Passenger_Selected(index))" class="ml-10 focus:ring-action -mt-1 h-4 w-4 text-action border-grey-light rounded">
                        <label :for="Control_Name(index)" class="ml-3 block text-sm cursor-pointer">{{passenger.first_name}} {{Get_Price(passenger)}}</label>
                    </div>
                </div>
            </div>
            <div class="flex justify-end" :class="{'hidden': isMobile()}">
                    <button v-if="!addingAddon" class="button button-pink-book mx-5" @click="add_AddOn()">Add</button>
                    <button v-else :disabled="true" class="button button-pink-book mx-5" @click="add_AddOn()">
                        <svg class="animate-spin h-5 w-5 mr-3 inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 512 512" stroke="currentColor">
                            <path fill="currentColor" d="M440.65 12.57l4 82.77A247.16 247.16 0 0 0 255.83 8C134.73 8 33.91 94.92 12.29 209.82A12 12 0 0 0 24.09 224h49.05a12 12 0 0 0 11.67-9.26 175.91 175.91 0 0 1 317-56.94l-101.46-4.86a12 12 0 0 0-12.57 12v47.41a12 12 0 0 0 12 12H500a12 12 0 0 0 12-12V12a12 12 0 0 0-12-12h-47.37a12 12 0 0 0-11.98 12.57zM255.83 432a175.61 175.61 0 0 1-146-77.8l101.8 4.87a12 12 0 0 0 12.57-12v-47.4a12 12 0 0 0-12-12H12a12 12 0 0 0-12 12V500a12 12 0 0 0 12 12h47.35a12 12 0 0 0 12-12.6l-4.15-82.57A247.17 247.17 0 0 0 255.83 504c121.11 0 221.93-86.92 243.55-201.82a12 12 0 0 0-11.8-14.18h-49.05a12 12 0 0 0-11.67 9.26A175.86 175.86 0 0 1 255.83 432z"></path>
                        </svg>
                    </button>
                </div>
            
            <!--Mobile view-->
            <div v-if="passengers.length < 6" :class="{'hidden': !isMobile()}">
                <div class="mb-1 mr-0" v-for="(passenger, index) in passengers" @click="Toggle_Passenger(index)">
                    <div class="flex items-center">
                        <input :id="Control_Name(index)" type="checkbox" :checked="Passenger_Selected(index)" class="ml-10 focus:ring-action -mt-1 h-4 w-4 text-action border-grey-light rounded">
                        <label :for="Control_Name(index)" class="ml-3 block text-sm cursor-pointer">{{passenger.first_name}} {{Get_Price(passenger)}}</label>
                    </div>
                </div>
                <div v-if="!addingAddon" class="flex-none"><button class="button button-pink-book mb-5 mx-5" @click="add_AddOn()">Add</button></div>
                <button v-else :disabled="true" class="button button-pink-book mb-5 mx-5" @click="add_AddOn()">
                    <svg class="animate-spin h-5 w-5 mr-3 inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 512 512" stroke="currentColor">
                        <path fill="currentColor" d="M440.65 12.57l4 82.77A247.16 247.16 0 0 0 255.83 8C134.73 8 33.91 94.92 12.29 209.82A12 12 0 0 0 24.09 224h49.05a12 12 0 0 0 11.67-9.26 175.91 175.91 0 0 1 317-56.94l-101.46-4.86a12 12 0 0 0-12.57 12v47.41a12 12 0 0 0 12 12H500a12 12 0 0 0 12-12V12a12 12 0 0 0-12-12h-47.37a12 12 0 0 0-11.98 12.57zM255.83 432a175.61 175.61 0 0 1-146-77.8l101.8 4.87a12 12 0 0 0 12.57-12v-47.4a12 12 0 0 0-12-12H12a12 12 0 0 0-12 12V500a12 12 0 0 0 12 12h47.35a12 12 0 0 0 12-12.6l-4.15-82.57A247.17 247.17 0 0 0 255.83 504c121.11 0 221.93-86.92 243.55-201.82a12 12 0 0 0-11.8-14.18h-49.05a12 12 0 0 0-11.67 9.26A175.86 175.86 0 0 1 255.83 432z"></path>
                    </svg>
                </button>
            </div>
            <div v-else class="flex-col" :class="{'hidden': !isMobile()}">
                <div class="flex-grow" v-for="(passenger, index) in passengers" @click="Toggle_Passenger(index)">
                    <div class="flex items-center">
                            <input :id="Control_Name(index)" type="checkbox" :checked="Passenger_Selected(index)" class="ml-10 focus:ring-action -mt-1 h-4 w-4 text-action border-grey-light rounded">
                            <label :for="Control_Name(index)" class="ml-3 block text-sm cursor-pointer">{{passenger.first_name}} {{Get_Price(passenger)}}</label>
                    </div>
                </div>
                <div class="flex-none"><button class="button button-pink-book center-right mb-5 ml-10" @click="add_AddOn()">Add</button></div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="./select_passengers.ts"></script>