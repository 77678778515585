﻿import { defineComponent } from 'vue';

import { Booking } from "../../../../services/booking";
import { Capacity } from "../../../../services/capacity";

import { capacity } from '../../../../objects/v1_3/product/capacity/capacity';
import { week } from "../../../../objects/front_end/calendar/week";
import { week_day } from "../../../../objects/front_end/calendar/week_day";

import calendar_day from "./calendar_day.vue";

const Book = new Booking();
const Capa = new Capacity();

export default defineComponent({
    name: "calendar_details",
    components: {
        calendar_day
    },
    props: {
        trip_index: Number
    },
    data() {
        return {
            capacity: [] as week[],
            tour_ids: [] as string[],
            selected_tour_id: "" as string,
            year: 0 as number,
            month: 0 as number,
            current_capacity: new capacity() as capacity
        }
    },
    emits: [
        "dateChange"
    ],
    methods: {
        async download_capacity(tour_ids: string[], selected_tour_id: string, year: number, month: number) {
            this.year = year;
            this.month = month;
            this.tour_ids = tour_ids;
            this.selected_tour_id = selected_tour_id;

            this.current_capacity = new capacity();
            this.current_capacity.capacities = [];

            for (var tour_id of tour_ids)
            {
                const capacities = (await Capa.Get_Capacity(tour_id, year, month)).capacities;
                for (var capa of capacities)
                {
                    this.current_capacity.capacities.push(capa);
                }
            }

            this.onCalendarUpdate(this.selected_tour_id);
        },
        async onCalendarUpdate(tour_id: string) {
            this.selected_tour_id = tour_id;

            this.capacity = Capa.Get_Capacity_Week(this.selected_tour_id, this.year, this.month, this.current_capacity, this.$store.getters.get_Currency.currency_info.iso_currency_code, Book.Pricing_Age_Category(this.$store));
        },
        onDateChange(weekday : week_day) {
            this.$emit("dateChange", weekday);
        },
    },
    mounted() {
    }
})