﻿import { defineComponent } from 'vue';

import header_menu from "../header/menu.vue"
import footer_item from "../base/common/footer.vue"
import hero from "../header/hero.vue"

import basket from "../base/common/basket.vue"
import basket_minimal from "../base/common/basket_minimal.vue"
import basket_header from "../base/common/header.vue"

import booking_notes from "../base/4_checkout/booking_notes.vue"
import passengers from "../base/4_checkout/passengers.vue"
import trip_summary from "../base/4_checkout/summary.vue"

export default defineComponent({
    components: {
        booking_notes,
        footer_item,
        header_menu,
        basket,
        basket_minimal,
        basket_header,
        trip_summary,
        passengers,
        hero
    },
    data() {
        return {
        }
    },
    methods: {
        isAgent_Login(): boolean {
            return this.$store.getters.get_Agent_Login?.valid_login == true;
        },
        isValidView(): boolean {
            if(this.$store.getters.get_Booking?.group_booking_id == undefined || this.$store.getters.get_Booking?.group_booking_id == 0 || this.$store.getters.get_Booking?.group_booking_id == null) {
                return false;
            }
            return true;
        },
        getHeroImage(): string {
            return String(import.meta.env.VITE_DEFAULT_HERO_IMAGE);
        },
        home_Click() {
            this.$router.push('/');
        }
    },
    mounted() {
    }
})