﻿<template>
    <div v-if="!isToday()" class="flex flex-col h-full w-full" :class="{'bg-grey-default': isFull()}" @click="date_selected()">
        <div :hidden="isFull()" >
            <a :class="{departures: getDay() != '', selected: isDate_Selected()}">
                <div class="day-label" v-if="getDay() != ''">{{getDay()}}</div>
                <div :hidden="almostFull" class="day-content flex-none overflow-hidden" v-if="getPrice() != '' || getSeats() != ''">
                    <span class="price text-xs md:text-sm" v-if="getPrice() != ''">{{getPrice()}}</span>
                    <small  class="capacity" v-if="getSeats() != ''">{{getSeats()}}</small>
                </div>
                <div :hidden="!almostFull" class="day-content flex-none overflow-hidden" v-if="getPrice() != '' || getSeats() != ''">
                    <span class="price text-xs md:text-sm" v-if="getPrice() != ''">{{getPrice()}}</span>
                    <small  class="capacityAlmostFull" v-if="getSeats() != ''">{{getSeats()}}</small>
                </div>
            </a>
        </div>
        <div :hidden="!isFull()" >
            <div class="day-label" v-if="getDay() != ''">{{getDay()}}</div>
            <div class="day-content flex-none overflow-hidden" v-if="getPrice() != '' || getSeats() != ''">
                <span class="price text-xs md:text-sm" v-if="getPrice() != ''">{{getPrice()}}</span>
                <small class="capacity" v-if="getSeats() != ''">{{getSeats()}}</small>
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="./calendar_day.ts"></script>