﻿import { resp } from "../objects/ttc/payment_response/resp";
import { return_status } from "../objects/v1_3/return_status";

import { request } from "../objects/v1_3/payment/initialise/request";
import { request_payment } from "../objects/v1_3/payment/initialise/request_payment";
import { request_payment_url } from "../objects/v1_3/payment/initialise/request_payment_url";

import { response } from "../objects/v1_3/payment/initialise/response";
import { response_method } from "../objects/v1_3/payment/initialise/response_method";

import { response_login } from "../objects/agent/identity/v1_3/response_login";

import { booking_search_group } from "../objects/v1_2/Booking_Details/booking_search_group";

import { Security } from "./security";
import { Rest_API } from "../../system/services/rest_api";
import { booking } from "../objects/v1_2/Booking_Put/booking";
import { client_booking } from "../objects/v1_2/Booking_Details/client_booking";
import { trip_passenger } from "../objects/v1_2/Booking/Trip_Passenger";

export class Payment {

    Rest: Rest_API = new Rest_API();
    Secure: Security = new Security();

    public async Payment_Initialise(full_payment: boolean, deposit: boolean, booking_info: booking_search_group, agent_response_login: response_login, consumer_sale: boolean, nett_sale: boolean) {

        let Payment_Request: request = new request();
        let payment_urls: request_payment_url = new request_payment_url();
        
        if(import.meta.env.VITE_DEFAULT_URL_SUCCESS != "" && import.meta.env.VITE_DEFAULT_URL_SUCCESS != undefined && import.meta.env.VITE_DEFAULT_URL_SUCCESS != null)
            payment_urls.url_success = import.meta.env.VITE_DEFAULT_URL_SUCCESS;
        if(import.meta.env.VITE_DEFAULT_URL_CANCELLATION != "" && import.meta.env.VITE_DEFAULT_URL_CANCELLATION != undefined && import.meta.env.VITE_DEFAULT_URL_CANCELLATION != null)
            payment_urls.url_cancellation = import.meta.env.VITE_DEFAULT_URL_CANCELLATION;
        if(import.meta.env.VITE_DEFAULT_URL_FAILURE != "" && import.meta.env.VITE_DEFAULT_URL_FAILURE != undefined && import.meta.env.VITE_DEFAULT_URL_FAILURE != null)
            payment_urls.url_failure = import.meta.env.VITE_DEFAULT_URL_FAILURE;

        Payment_Request.booking_id = booking_info.group_booking_id;
        Payment_Request.iso_currency_code = booking_info.trips[0].iso_currency_code;
        Payment_Request.full_payment = full_payment;
        Payment_Request.deposit = deposit;
        Payment_Request.online_sale = consumer_sale;
        Payment_Request.nett_sale = nett_sale;
        Payment_Request.token = agent_response_login?.token;
        Payment_Request.payment_urls = payment_urls;
        Payment_Request.payments = this.get_Payments(nett_sale, full_payment, deposit, booking_info);
        sessionStorage.setItem("booking_id", booking_info.group_booking_id.toString());

        return this.Rest.Post_Data_Secure<request, response>(import.meta.env.VITE_PAYMENT_URL + "/api/v1.3/" + import.meta.env.VITE_APP_ID + "/payment/initialise", await this.Secure.Get_Hash_Password(), Payment_Request);
    }
    public get_Payments(nett_sale: boolean, full_payment: boolean, deposit: boolean, booking_info: booking_search_group): request_payment[] {
        let Output_Payments: request_payment[] = [];
        booking_info.trips.forEach(trip => {
            trip.passengers.forEach(trip_passenger => {
                if (trip_passenger.outstanding > 0) {
                    if (full_payment == true) {
                        Output_Payments.push(this.get_Full_Payment_Item(nett_sale, trip_passenger));
                    }

                    if (deposit == true) {
                        Output_Payments.push(this.get_Deposit_Payment_Item(nett_sale, trip_passenger));
                    }
                }
            });
        });

        return Output_Payments;
    }
    private get_Full_Payment_Item(nett_sale: boolean, trip_passenger: client_booking): request_payment {
        let Output_Payment: request_payment = new request_payment();
        Output_Payment.client_booking_id = trip_passenger.trip_passenger_identifier;

        if (nett_sale == true && (trip_passenger.commission ?? 0) != 0) {
            Output_Payment.amount = trip_passenger.outstanding - trip_passenger.commission;
        } else {
            Output_Payment.amount = trip_passenger.outstanding;
        }

        return Output_Payment;
    }
    private get_Deposit_Payment_Item(nett_sale: boolean, trip_passenger: client_booking): request_payment {
        let Output_Payment: request_payment = new request_payment();
        Output_Payment.client_booking_id = trip_passenger.trip_passenger_identifier;
        Output_Payment.amount = trip_passenger.deposit;
        if (trip_passenger.deposit == trip_passenger.outstanding) {
            Output_Payment.amount = trip_passenger.deposit;
        }

        return Output_Payment;
    }
    public Payment_Method(Response: response): response_method {

        let Response_Method: response_method = new response_method();
        Response.payment_methods.forEach(method => {
            if (method.payment_method == import.meta.env.VITE_PAMENT_METHOD) {
                Response_Method = method;
            }
        });

        return Response_Method;
    }
    public get_cybersource_url(Response: response): string {
        let Response_Method: response_method = new response_method();
        let return_url: string = "";
        Response.payment_methods.forEach(method => {
            if(method.payment_method?.toLowerCase() == "cybersource"){
                return_url = method.form_iframe ?? "";
                return;
            }
        });

        return return_url;
    }
    public async Commit_Payment(Payment: resp): Promise<return_status> {
        return this.Rest.Post_Data<resp, return_status>(import.meta.env.VITE_PAYMENT_TTC_URL + "/api/v1.3/" + import.meta.env.VITE_APP_ID + "/payment/response", Payment).then(response => { return response.json(); });
    }
}