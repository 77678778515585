﻿export class payment_transaction {
    public transactionId: string | null = null;
    public amount: string | null = null;
    public currency: string | null = null;
    public tripName: string | null = null;
    public bookingType: string | null = null;
    public cardTypes: string | null = null;
    public selectedCardType: string | null = null;
    public firstName: string | null = null;
    public lastName: string | null = null;
    public email: string | null = null;
    public addressLine1: string | null = null;
    public addressLine2: string | null = null;
    public addressCity: string | null = null;
    public addressState: string | null = null;
    public addressStateName: string | null = null;
    public addressCountry: string | null = null;
    public addressCountryName: string | null = null;
    public addressPostalCode: string | null = null;
    public comments: string | null = null;
}