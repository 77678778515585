﻿import { defineComponent } from 'vue';

import header_menu from "../../../booking_engine/components/header/menu.vue"

import header_top from "./header.vue";
import booking_details from "./booking_details.vue";
import booking_documentation from "./booking_documentation.vue";
import booking_notes from "./booking_notes.vue";
import passengers from "./passengers.vue";

export default defineComponent({
    components: {
        header_menu,
        header_top,
        booking_details,
        booking_documentation,
        booking_notes,
        passengers
    },
    props: {
    },
    data() {
        return {
            Hide_Cancel: true as boolean
        }
    },
    methods: {
    },
    mounted() {
    }
});