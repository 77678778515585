import { booking_information } from "./Booking_Information";
import { booking_note } from "./Booking_Note";
import { trip } from "./Trip";
import { passenger } from "./Passenger";

export class booking_group {
    public session_id: string | null = null;
    public booking_information: booking_information = new booking_information();
    public booking_notes: booking_note[] = [];
    public trips: trip[] = [];
    public passengers: passenger[] = [];
}
