﻿import { request } from "./../objects/v1_3/security/request";
import { response } from "./../objects/v1_3/security/response";

import { Rest_API } from "../../system/services/rest_api";

export class Security {

    Rest: Rest_API = new Rest_API();

    public async Get_Hash_Password(): Promise<string> {
        return this.Get_Hash_Password_Parts(String(import.meta.env.VITE_APP_URL), String(import.meta.env.VITE_APP_ID), String(import.meta.env.VITE_API_KEY));
    }
    public async Get_Hash_Password_Parts(server: string, app_id: string, api_key: string) {
        const Request_Data = new request;
        Request_Data.api_key = api_key;

        const response = await this.Rest.Post_Data<request, response>(server + "/api/v1.3/" + app_id + "/security/hmac/authentication_hash", Request_Data);
        const data: response = await response.json();

        return data.password_hash;
    }
}