﻿import { booking_search_group } from "../objects/v1_2/Booking_Details/booking_search_group";
import { booking_info } from "../objects/v1_2/Booking_Details/booking_info";
import { client_booking } from "../objects/v1_2/Booking_Details/client_booking";

import { request } from "../objects/v1_2/Booking_Delete/Request/request";
import { request_trip } from "../objects/v1_2/Booking_Delete/Request/request_trip";
import { request_trip_passenger } from "../objects/v1_2/Booking_Delete/Request/request_trip_passenger";

export class Booking {
    public Blank_Booking(): booking_search_group {
        let Booking: booking_search_group = new booking_search_group();
        Booking.trips = [];
        Booking.passengers = [];

        return Booking;
    }
    public Create_Cancellation_Object(Booking_Search: booking_search_group): request {
        var Output: request = new request;

        Booking_Search.trips.forEach((trip: booking_info) => {
            var Output_Trip: request_trip = new request_trip;
            Output_Trip.booking_identifier = trip.booking_identifier;

            trip.passengers.forEach((trip_passenger: client_booking) => {
                var Output_Trip_Passenger: request_trip_passenger = new request_trip_passenger;
                Output_Trip_Passenger.trip_passenger_identifier = trip_passenger.trip_passenger_identifier;
                Output_Trip_Passenger.cancel = true;

                Output_Trip.passengers.push(Output_Trip_Passenger);
            });

            Output.trips.push(Output_Trip);
        });

        return Output;
    }
}