﻿import { defineComponent } from 'vue';

import { response_login_agency } from "../../booking_engine/objects/agent/identity/v1_3/response_login_agency";
import { response_login_payment } from "../../booking_engine/objects/agent/identity/v1_3/response_login_payment";

export default defineComponent({
    components: {
    },
    props: {
    },
    data() {
        return {
            account_information: new response_login_agency() as response_login_agency
        }
    },
    methods: {
        Load_Agency_Information() {
            this.account_information = new response_login_agency();
            this.account_information.code = this.$store.getters.get_Agent_Login?.agency?.code;
            this.account_information.name = this.$store.getters.get_Agent_Login?.agency?.name;
            this.account_information.address_1 = this.$store.getters.get_Agent_Login?.agency?.address_1;
            this.account_information.address_2 = this.$store.getters.get_Agent_Login?.agency?.address_2;
            this.account_information.post_code = this.$store.getters.get_Agent_Login?.agency?.post_code;
            this.account_information.email = this.$store.getters.get_Agent_Login?.agency?.email;
            this.account_information.telephone = this.$store.getters.get_Agent_Login?.agency?.telephone;
        },
        Payment_Methods(): string {
            let Output: string = "";
            this.$store.getters.get_Agent_Login?.payment_methods.forEach((method: response_login_payment) => {
                if (Output == "") {
                    Output = method.description ?? "";
                } else {
                    Output += ", " + method.description;
                }
            });
            return Output;
        },
        Email_Agency() {
            window.open("mailto:sales@aatkings.com.au?subject=" + this.Email_Agency_Subject() + "&body=" + this.Email_Agency_Body());
        },
        Email_Agency_Subject(): string {
            return "Requested Changes to " + this.$store.getters.get_Agent_Login?.agency?.name + " (" + this.$store.getters.get_Agent_Login?.agency?.code + ")";
        },
        Email_Agency_Body(): string {
            let Output: string = "";
            Output += "Please make the following changes to our agency records\r";
            Output += "\r";
            Output += this.Email_Body_Changes("Address Line One", this.$store.getters.get_Agent_Login?.agency?.address_1, this.account_information.address_1 ?? "");
            Output += this.Email_Body_Changes("Address Line Two", this.$store.getters.get_Agent_Login?.agency?.address_2, this.account_information.address_2 ?? "");
            Output += this.Email_Body_Changes("Post Code", this.$store.getters.get_Agent_Login?.agency?.post_code, this.account_information.post_code ?? "");
            Output += this.Email_Body_Changes("Phone Number", this.$store.getters.get_Agent_Login?.agency?.telephone, this.account_information.telephone ?? "");
            Output += this.Email_Body_Changes("Email Address", this.$store.getters.get_Agent_Login?.agency?.email, this.account_information.email ?? "");

            return encodeURIComponent(Output);
        },
        Email_Body_Changes(field: string, original_value: string, new_value: string): string {
            if (original_value == new_value) {
                return "";
            }

            return field + " - Original Value - " + original_value + " - New Value - " + new_value + "\r";
        }
    },
    mounted() {
        this.Load_Agency_Information();
    }
});