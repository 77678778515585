﻿import { defineComponent } from 'vue';

import sales_group_room_type from './sales_group_room_type.vue'

import { product_detail } from "../../../objects/v1_3/product/product/product_detail";
import { product_search } from "../../../objects/v1_3/product/search/product_search";
import { product_search_product } from "../../../objects/v1_3/product/search/product_search_product";

import { Booking } from '../../../services/booking';
import { Product } from '../../../services/product';
import { Room } from '../../../services/room';

import { Currency_Service } from "../../../../system/services/currency";

const Book = new Booking();
const Prod = new Product();
const Rom = new Room();
const Currency_Services = new Currency_Service();

export default defineComponent({
    name: "sales_group",
    components: {
        sales_group_room_type
    },
    emits: [
        "selectedTour"
    ],
    props: {
        tour_id: String,
        year: Number,
        month: Number,
        trip_index: Number
    },
    data() {
        return {
            product_search: new product_search() as product_search,
            product_selected: new product_detail() as product_detail,
            tab: "" as String,
            Option_Is_Open: false as Boolean
        }
    },
    methods: {
        async Download_Sales_Group() {
            if (this.tour_id != "" && this.tour_id != undefined) {
                this.product_search = await Prod.Get_Sales_Group(this.tour_id);
                if (this.product_search?.products?.length > 0) {
                    await this.product_selection(this.product_search?.products[0]?.tour_id);
                }
            }
        },
        async product_selection(tour_id: string) {
            this.Option_Is_Open = false;

            if (tour_id != "" && tour_id != undefined) {
                this.tab = tour_id;
                this.product_selected = await Prod.Get_Details(tour_id, this.$store);

                this.$store.commit("Change_Basket_Primary_Tour", { Trip_Index: this.get_Trip_Index(), Tour_ID: tour_id });
                this.$emit("selectedTour", tour_id);

                await Book.Setup_Booking(this.$store);
            }
        },
        async select_product() {
            await this.product_selection(this.tour_id ?? "");
        },
        async get_product_details(tour_id: string): Promise<product_detail> {
            return await Prod.Get_Details(tour_id, this.$store);
        },
        get_price_decimal(product: product_search_product): number {
            if (Rom.get_Rooms(Prod.Get_Details_Sync(product.tour_id, this.$store)).length == 0) {
                return Prod.product_pricing_month(Book.Pricing_Age_Category(this.$store), this.$store.getters.get_Currency, this.year ?? 0, this.month ?? 0, Prod.Get_Details_Sync(product.tour_id, this.$store));
            }

            return Rom.get_Minimum_Price(Prod.Get_Details_Sync(product.tour_id, this.$store), this.year ?? 0, this.month ?? 0, this.$store);
        },
        get_price(product: product_search_product): string {
            return Currency_Services.Format_Currency(this.get_price_decimal(product), this.$store.getters.get_Currency);
        },
        Display_Tab_Selection(): boolean {
            if (this.get_Sales_Group().length > 1) {
                return true;
            }

            if (this.product_selected?.seller_information?.rooms?.length > 0) {
                return true;
            }

            return false;
        },
        get_Sales_Group(): product_search_product[] {
            let Sales_Groups: product_search_product[] = [];
            this.product_search.products.forEach((product: product_search_product) => {
                if (this.get_price_decimal(product) > 0) {
                    Sales_Groups.push(product);
                }
            });

            return Sales_Groups;
        },
        get_Trip_Index(): number {
            return this.trip_index ?? 0;
        },
        onClickAway(event: any) {
            let Click_Away: boolean = true;

/*            if (event.path[1].id == "Sales_Group_Click_Away") {
                Click_Away = false;
            }*/

            if (Click_Away == true) {
                this.Option_Is_Open = false;
            }
        },
        isAgent_Login(): boolean {
            return this.$store.getters.get_Agent_Login?.valid_login == true;
        },
        accomodation_formating(short_name: string, name: string): string {
            if(!this.isAgent_Login())
            {
                if(short_name){
                    return short_name;
                }
                else{
                    if(name.toUpperCase().includes("HOTEL")){
                        return "Hotel";
                    }
                    else{
                        return "Bed and Breakfast"
                    }
                }
            }
            return short_name ?? name;


        },
        dateSelected(): boolean {
            let Basket = this.$store.getters.get_Basket;
            return (Basket.trips[this.get_Trip_Index()]?.departure_date != null && Basket.trips[this.get_Trip_Index()]?.departure_date != "");
        }
    },
    mounted() {
        this.Download_Sales_Group();
        this.select_product();
    }
})
