﻿import { addSyntheticTrailingComment } from 'typescript';
import { defineComponent } from 'vue';

import { request_password_reset } from "../../booking_engine/objects/agent/identity/v1_3/reguest_password_reset";
import { response_password_reset } from "../../booking_engine/objects/agent/identity/v1_3/response_password_reset";

import { Agent_Login } from "../../booking_engine/services/agent_login";

let Agent_Logins: Agent_Login = new Agent_Login();

export default defineComponent({
    components: {
    },
    props: {
    },
    data() {
        return {
            saving_details: false as boolean,
            current_password: "" as string,
            new_password: "" as string,
            confirm_new_password: "" as string
        }
    },
    methods: {
        async Save_Details() {
            if (this.current_password == "") {
                window.alert("Please enter your current password.");
                return;
            }

            if (this.new_password == "") {
                window.alert("Please enter your new password.");
                return;
            }

            if (this.confirm_new_password == "") {
                window.alert("Please confirm your new password.");
                return;
            }

            if (this.new_password != this.confirm_new_password) {
                window.alert("Please new password and confirmed password don't match.");
                return;
            }

            this.saving_details = true;
            let Request_Password_Reset: request_password_reset = new request_password_reset();
            Request_Password_Reset.user_name = this.$store.getters.get_Agent_Login?.agency?.email;
            Request_Password_Reset.current_password = this.current_password;
            Request_Password_Reset.new_password = this.new_password;

            let Response_Password_Reset: response_password_reset = await Agent_Logins.Password_Reset(Request_Password_Reset);
            this.saving_details = false;

            if (Response_Password_Reset.reset_sucessful == true) {
                window.alert("Sucessfully changed password");
                return;
            }

            window.alert(Response_Password_Reset.message);
        }
    },
    mounted() {
    }
});