﻿<template>

    <div class="summary-payment section">
        <div class="row payment-method flex">

            <div class="payment-title flex-1 mb-4">
                <span class="uppercase relative top-2">Payment Method</span>
            </div>

            <div class="flex-1 vc-title">
                <button type="button" class="select-button" @click="isOpen = !isOpen" :active="isOpen">
                    <strong>{{selected_payment_method.description}}</strong>
                </button>
                <div class="select-dropdown w-80" v-if="isOpen">
                    <ul>
                        <li v-for="payment_method in this.$store.getters.get_Agent_Login?.payment_methods"
                            @click="Payment_Method_Selected(payment_method)">
                            {{payment_method.description}}
                        </li>
                    </ul>
                </div>
            </div>

        </div>

        <div class="row agent-refence flex">
            <div class="agent-title flex-1" :hidden="selected_payment_method.method == 'CC'">
                <span class="uppercase relative top-2">Agent Reference*</span>
            </div>
            <div class="agent-info flex-1" :hidden="selected_payment_method.method == 'CC'">
                <input type="text" name="agent-ref" id="agent-ref" class="py-1" required v-model="agent_reference" />
            </div>
        </div>
    </div>

</template>

<script lang="ts" src="./payment_method.ts"></script>