﻿<template>
    <div class="cart-summary w-full md:w-5/12 lg:w-4/12">
        <div class="basket-summary border border-form rounded w-full p-8 md:p-4 lg:p-8 flex flex-col">

            <itinerary_breakdown display_open=true></itinerary_breakdown>
            <hr />
            <trip_summary></trip_summary>

            <div class="summary-total my-2">
                <div class="row" v-if="Balance_Outstanding()">
                    <h6 class="title text-2xl text-heading font-bold ">Remaining Balance Due</h6>
                    <p class="balance text-black text-2xl text-action font-bold mb-0">{{get_total_outstanding()}} <span class="font-normal text-lg">{{get_Currency()}}</span> <small class="font-normal text-sm">includes all taxes.</small></p>
                    <p class="balance-remaning text-sm mt-2 mb-0">Balance of <span class="font-bold">{{get_total_outstanding()}} </span> due by {{Balance_Due_Date()}}</p>
                </div>
                <div class="row" v-else>
                    <h6 class="title text-base text-heading font-bold">Total Price Paid</h6>
                    <p id="totalPaidPrice" class="balance text-black text-2xl text-action font-bold mb-0">{{get_Total_Price()}} <span class="font-normal text-lg">{{get_Currency()}}</span> <small class="font-normal text-sm">includes all taxes.</small></p>
                </div>
            </div>

        </div>
    </div>
</template>
<script lang="ts" src="./summary.ts"></script>
