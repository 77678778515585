﻿<template>
    
  <div class="cart-summary w-full md:w-4/12 z-0">

    <div v-if="displayPopup" class="bg-opacity-40 bg-black h-full w-full slideout-inner overscroll-none fixed " @click="displayPopup=false">

      <div v-if="!isMobile()" class="mt-2 mx-2 p-5  h-4/5 w-11/12 rounded-md bg-white overflow-hidden overscroll-none fixed">
        <button class="close no-bg" @click="displayPopup=false"></button>
        <div class="pt-5 tour-days text-center ">
          <img v-if="get_popup_image()"  class="h-40 mx-auto rounded-md shadow-xl"  :src="get_popup_image()" alt="Image of available Add-on" />
          <h3 class="tour-day-title">{{get_popup_text()}}</h3>
          
          <p class="mt-0 text-l">Add-ons are popular experiences that complement and enhance your trip. They sell out fast, so the purchase of tickets after checkout cannot be guaranteed.</p>
        </div>
        <div class="flex items-baseline justify-center h-screen">
          <button class="button button-pink-book  w-1/3 mx-1" @click="show_addons()">
            Show add-ons for my trip
          </button>
          <button class="button button-pink-book  w-1/3 mx-1" @click="check_out()">
            Proceed without add-ons
          </button>
        </div>
        
      </div>

      <div v-else class="p-5 h-full bg-white overscroll-none">
        <button class="close no-bg" @click="displayPopup=false"></button>
        <div class="tour-days text-center pb-10">
            <img v-if="get_popup_image()"  class="h-24 mx-auto rounded-md"  :src="get_popup_image()" alt="Image of available Add-on" />
            <h4 class="tour-day-title">Last chance to secure your spot for tour add-ons!</h4>
            <p class="mt-0 text-l">Add-ons are popular experiences that complement and enhance your trip. They sell out fast, so the purchase of tickets after checkout cannot be guaranteed.</p>
        </div>
        <div class="mx-auto">
          <div class="">
            <button class="object-center button button-pink-book w-full m-1" @click="displayPopup = false">
              View add-ons for my trip
            </button>
            <button class="button button-pink-book m-1 w-full" @click="check_out()">
              Proceed without add-on
            </button>
          </div>
        </div>
      </div>

    </div>
   
    <div class="basket-summary">
      <itinerary_breakdown display_open=true></itinerary_breakdown>
      <hr />
      <trip_summary></trip_summary>
      <trip_summary_discount v-if="get_Decimal_Total() > 1"></trip_summary_discount>
      <hr v-if="get_Decimal_Total() > 1"/>
      <div class="summary-total section">
        <div class="row">
          <h6 class="title text-base text-heading font-bold">Total Price</h6>
          <p class="text-black balance text-2xl text-action font-bold mb-0">{{ get_Total() }}<span class="font-normal text-lg"> {{ " " +get_Currency() }}</span><small class="font-normal text-sm"> includes all taxes.</small></p>
        </div>
      </div>
      <div class="summary-action section">
        <div class="row" :class="{ hidden: !get_Decimal_Total() || isAgent_Login() == true }">
          <p class="m-auto font-bold text-black">Check in closes 15 minutes before departure time. We are unable to hold the bus or refund for late arrivals.</p>
          <div class="flex my-4">
            <input v-model="agreeCheckbox" type="checkbox" class="focus:ring-action text-action border-grey-light rounded mr-5 mt-1 cursor-pointer">
            <label>I understand and agree.</label>
          </div>
        </div>
        <div class="row">
          <button v-if="get_Decimal_Total() > 0 && agreeCheckbox" type="button" class="button button-pink-book full" @click="addons_Check()">
            <svg class="animate-spin h-5 w-5 mr-3 inline" :class="{ hidden: !booking_now }" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 512 512" stroke="currentColor">
              <path fill="currentColor" d="M440.65 12.57l4 82.77A247.16 247.16 0 0 0 255.83 8C134.73 8 33.91 94.92 12.29 209.82A12 12 0 0 0 24.09 224h49.05a12 12 0 0 0 11.67-9.26 175.91 175.91 0 0 1 317-56.94l-101.46-4.86a12 12 0 0 0-12.57 12v47.41a12 12 0 0 0 12 12H500a12 12 0 0 0 12-12V12a12 12 0 0 0-12-12h-47.37a12 12 0 0 0-11.98 12.57zM255.83 432a175.61 175.61 0 0 1-146-77.8l101.8 4.87a12 12 0 0 0 12.57-12v-47.4a12 12 0 0 0-12-12H12a12 12 0 0 0-12 12V500a12 12 0 0 0 12 12h47.35a12 12 0 0 0 12-12.6l-4.15-82.57A247.17 247.17 0 0 0 255.83 504c121.11 0 221.93-86.92 243.55-201.82a12 12 0 0 0-11.8-14.18h-49.05a12 12 0 0 0-11.67 9.26A175.86 175.86 0 0 1 255.83 432z" class=""></path>
            </svg>
            {{ checkout }}
          </button>
          <button v-if="get_Decimal_Total() < 1" type="button" class="border-grey-default bg-form border-blank button text-base text-action full" disabled>
            Cart Empty
          </button>
          <button v-if="get_Decimal_Total() > 0 && !agreeCheckbox" type="button" class="border-grey-default bg-form border-blank button text-base text-action full" disabled>
            Agree to continue
          </button>
        </div>
        <div class="row">
                    <small class="block mt-4 text-grey-default">{{get_Contact_Text()}}</small>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" src="./summary.ts"></script>