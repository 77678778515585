﻿import { room_passengers } from "./../objects/front_end/room/room_passengers";
import { room_passengers_type } from "./../objects/front_end/room/room_passengers_type";

import { Room_Information } from "./../objects/front_end/room/data_room";

import { product_detail_seller_information_room } from "../objects/v1_3/product/product/product_detail_seller_information_room";

import { Product } from "../services/product";
import { Booking } from "../services/booking";
import { product_detail } from "../objects/v1_3/product/product/product_detail";
import { passenger } from "../objects/v1_2/Booking/Passenger";
import { room_passengers_type_combination } from "../objects/front_end/room/room_passengers_type_combination";
import { room_passengers_type_combination_passenger } from "../objects/front_end/room/room_passengers_type_combination_passenger";
import { capacity_capacity_room } from "../objects/v1_3/product/capacity/capacity_capacity_room";

let Prod = new Product();
let Book = new Booking();

export class Room {

    public Get_Room(Passenger_Total: number): room_passengers {

        let Output: room_passengers = new room_passengers();

        for (var Room of Room_Information.rooms) {
            if (Room.passengers == Passenger_Total) {
                Output = JSON.parse(JSON.stringify(Room));
                break;
            }
        }

        return Output;
    }
    public Filter_Room_Types(Passengers: passenger[], Room_List: room_passengers, Rooms_Possible: product_detail_seller_information_room[]): room_passengers {

        let Index_Remove: number[] = [];
        let Index: number = 0;

        if (Room_List != undefined) {
            if (Room_List.room_types != undefined) {
                for (var Room_Type of Room_List.room_types) {
                    if (this.Contains_Room(Room_Type, Rooms_Possible) == false) {
                        Index_Remove.push(Index);
                    } else {
                        var Passengers_Room: room_passengers_type_combination_passenger[] = this.Clone_Passengers(Passengers);

                        for (var Room of Room_Type.room_type_combinations) {
                            if (this.Room_Restricted(Room.name, Room.occupants) == true) {
                                Room.passenger_links = this.Room_Passengers_Restricted(Room.name, Room.occupants, Passengers_Room);
                            }
                        }

                        for (var Room of Room_Type.room_type_combinations) {
                            if (this.Room_Restricted(Room.name, Room.occupants) == false) {
                                Room.passenger_links = this.Room_Passengers(Room.name, Room.occupants, Passengers_Room);
                            }
                        }

                        for (var Room of Room_Type.room_type_combinations) {
                            if (Room.passenger_links == []) {
                                Index_Remove.push(Index);
                                break;
                            }
                        }
                    }
                    Index++;
                }
            }
        }

        for (var Remove of Index_Remove.reverse()) {
            Room_List.room_types.splice(Remove, 1);
        }

        return Room_List;
    }
    private Clone_Passengers(Passengers: passenger[]): room_passengers_type_combination_passenger[]
    {
        var Passenger_Output: room_passengers_type_combination_passenger[] = [];
        var Index: number = 0;

        for (var Passenger of Passengers) {
            let Passenger_Room: room_passengers_type_combination_passenger = new room_passengers_type_combination_passenger();
            Passenger_Room.age_category = Passenger.age_category;
            Passenger_Room.index = Index;

            Passenger_Output.push(Passenger_Room);
            Index++;
        }

        return Passenger_Output;
    }
    private Room_Passengers(Room: string | null, Occupants: number | null, Passengers: room_passengers_type_combination_passenger[]): room_passengers_type_combination_passenger[] {
        let Index_Remove: number[] = [];
        let Passenger_Combinations: room_passengers_type_combination_passenger[] = [];
        let Index: number = 0;

        for (var Passenger of Passengers) {
            Passenger_Combinations.push(Passenger);
            Index_Remove.push(Index);

            if (Index_Remove.length == Occupants) {
                break;
            }

            Index++;
        }

        for (var Remove of Index_Remove.reverse()) {
            Passengers.splice(Remove, 1);
        }

        return Passenger_Combinations;
    }
    private Room_Passengers_Restricted(Room: string | null, Occupants: number | null, Passengers: room_passengers_type_combination_passenger[]): room_passengers_type_combination_passenger[] {
        let Index_Remove: number[] = [];
        let Passenger_Combinations: room_passengers_type_combination_passenger[] = [];
        let Index: number = 0;

        for (var Passenger of Passengers) {
            if (Passenger.age_category != "Child") {
                Passenger_Combinations.push(Passenger);
                Index_Remove.push(Index);
                break;
            }
            Index++;
        }

        for (var Remove of Index_Remove.reverse()) {
            Passengers.splice(Remove, 1);
        }

        return Passenger_Combinations;
    }
    private Room_Restricted(Room: string | null, Occupants: number | null): boolean {
        if (Occupants == 1) {
            return true;
        }

        return false;
    }
    private Contains_Room(Room_Type: room_passengers_type, Rooms_Possible: product_detail_seller_information_room[]): boolean {
        let Output: boolean = true;
        for (let Room of Room_Type.room_type_combinations) {
            let Found: boolean = false;
            for (let Tour_Room of Rooms_Possible) {
                if (Tour_Room.name == Room.name) {
                    Found = true;
                    break;
                }
            }

            if (Found == false) {
                Output = false;
                break;
            }
        }

        return Output;
    }
    public get_Rooms(product: product_detail) {
        if (product == undefined) {
            return [];
        }

        if (product.seller_information == undefined) {
            return [];
        }

        return product.seller_information.rooms;
    }
    public get_Room_List(product: product_detail, store: any) {
        let room_list: room_passengers = this.Filter_Room_Types(store.getters.get_Basket.passengers, this.Get_Room(store.getters.get_Basket.passengers.length), this.get_Rooms(product));

        if (room_list == null) {
            return [];
        }

        return room_list.room_types;
    }
    public get_Minimum_Price(product: product_detail, year: number, month: number, store: any): number {
        let Price: number = 0;
        this.get_Room_List(product, store).forEach(Room => {
            let New_Price: number = this.get_Sales_Price(product, Room, year, month, store);

            if (Price == 0 || Price > New_Price) {
                Price = New_Price;
            }
        });

        return Price;
    }
    public get_Sales_Price(product: product_detail, room: room_passengers_type, year: number, month: number, store: any): number {

        let Price: number = 0;
        room.room_type_combinations.forEach((Room: room_passengers_type_combination)=> {
            Room.passenger_links.forEach((Passenger: room_passengers_type_combination_passenger) => {
                Price += Prod.product_price_room(Passenger.age_category, store.getters.get_Currency, year, month, product, Room.name);
            });
        });

        return Price;
    }
    public get_Passenger_Total(room: room_passengers_type, store: any): number {
        let Passenger_Total = 0;
        room.room_type_combinations.forEach((room_type: room_passengers_type_combination) => {
            Passenger_Total += room_type.occupants ?? 0;
        });

        return Passenger_Total;
    }
    public get_Room_Capacity(room: room_passengers_type, store: any): string {
        let Capacity: string = "";
        this.get_Room_Capacity_Merged(room.room_type_combinations).forEach((room_type: room_passengers_type_combination) => {
            store.getters.get_Selected_Capacity.capacity_rooms.forEach((capacity_room: capacity_capacity_room) => {
                if (room_type.name == capacity_room.room_type) {
                    Capacity += ":" + room_type.name + "-" + (room_type.occupants ?? -1) + "-" + (capacity_room.free_seats ?? -1);
                }
            });
        });

        return Capacity;
    }
    public get_Room_Capacity_Available(room: room_passengers_type, store: any): boolean {
        let Capacity: boolean = true;
        this.get_Room_Capacity_Merged(room.room_type_combinations).forEach((room_type: room_passengers_type_combination) => {
            store.getters.get_Selected_Capacity.capacity_rooms.forEach((capacity_room: capacity_capacity_room) => {
                if (room_type.name == capacity_room.room_type) {
                    if ((room_type.occupants ?? -1) > (capacity_room.free_seats ?? -1) && Capacity == true) {
                        Capacity = false;
                    }
                }
            });
        });

        return Capacity;
    }
    public get_Room_Capacity_Merged(room_capacities: room_passengers_type_combination[]): room_passengers_type_combination[] {
        let Capacity_Merged: room_passengers_type_combination[] = [];
        room_capacities.forEach(room_capacity => {
            let Capacity_Found: boolean = false;
            Capacity_Merged.forEach(merged_capacity => {
                if (room_capacity.name == merged_capacity.name) {
                    merged_capacity.occupants = (merged_capacity.occupants ?? 0) + (room_capacity.occupants ?? 0);
                    Capacity_Found = true;
                }
            });

            if (Capacity_Found == false) {
                Capacity_Merged.push(room_capacity);
            }
        });

        return Capacity_Merged;
    }
}