﻿import { defineComponent } from 'vue';

import { product_detail } from '../../../objects/v1_3/product/product/product_detail';

import { Product } from '../../../services/product';

const Prod = new Product();

export default defineComponent({
    name: "itinerary",
    props: {
        tour_id: String,
        banner: Boolean
    },
    data() {
        return {
            product: new product_detail()
        }
    },
    methods: {
        async download_product(tour_id: string): Promise<void> {
            this.product = await Prod.Get_Details(tour_id, this.$store);
        },
        get_Map(): string {
            return Prod.Get_Image_Map(this.product)+"?anchor=center&mode=crop&width=400&height=500";
        },
        get_Image(tour_id: string, width: number, height: number) {
            return Prod.get_image(Prod.Get_Details_Sync(tour_id, this.$store), width, height);
        },
        imageAltText(): string {
            return this.product?.marketing_information?.media[0]?.assets[0]?.name ?? "Image of " +  this.product?.name;
        },
        hideLocationBox(): boolean{
            if(this.product.operational_information?.location_start?.url != undefined &&
                this.product.operational_information?.location_start?.description &&
                this.product?.operational_information?.location_start?.checkInTime &&
                this.product?.operational_information?.location_start?.time &&
                this.product?.operational_information?.location_end?.time
                )
                return true;
           return false;
        },
        durationInDays(): string {
            let days = this.product?.operational_information.duration?.days ?? 0;
            if(this.product?.operational_information.duration?.hours ?? 0 >= 1){
                days++;
            }

            if(days == 1){
                return days.toString() + " Day";
            }
            else
            {
                return days.toString() + " Days";
            }
        },
        isAgent_Login(): boolean {
            return this.$store.getters.get_Agent_Login?.valid_login == true;
        },
        isAAT(): boolean {
            if (import.meta.env.VITE_DEFAULT_PARENT == "AAT") {
                return true;
            }
            return false;
        },
        FormatDescription(description: string): string[] {
            var descriptions = description.split("\n");
            return descriptions;
        }
    },
    mounted() {
        this.download_product(this.tour_id ?? "");
    }
})