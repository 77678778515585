﻿import { defineComponent } from 'vue';

export default defineComponent({
    name: "header_menu",
    props: {
        position: Number
    },
    methods: {
        Item_Selected(item: number): boolean {
            return (this.position ?? 0) >= (item ?? 0);
        }
    },
    mounted() {
    }
})