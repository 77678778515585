﻿import { defineComponent } from 'vue';

import login_screen from "../login_screen.vue";

export default defineComponent({
    components: {
        login_screen
    },
    props: {
    },
    data() {
    },
    methods: {
    },
    mounted() {
    }
});