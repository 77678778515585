﻿<template>
    <div class="flex flex-col h-full w-full" @click="date_selected()">
        <a :class="{departures: getDay() != '', selected: isDate_Selected()}">
                <div class="day-label" v-if="getDay() != ''">{{getDay()}}</div>
                <div class="day-content flex-none overflow-hidden">
                    <span class="price text-xs md:text-sm">{{get_Tours()}}</span>
                </div>
        </a>
    </div>
</template>

<script lang="ts" src="./calendar_day.ts"></script>