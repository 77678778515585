﻿import { defineComponent, PropType } from 'vue';

import itinerary from "../common/itinerary.vue";
import tour_details from "../2_tour_details/tour_details.vue"

import { product_search } from "../../../objects/v1_3/product/search/product_search";
import { product_search_product } from "../../../objects/v1_3/product/search/product_search_product";

import { Booking } from '../../../services/booking';
import { Product } from '../../../services/product';
import { Currency_Service } from "../../../../system/services/currency";
import { passenger } from '../../../objects/v1_2/Booking/Passenger';

const Book = new Booking();
const Prod = new Product();
const Currency_Services = new Currency_Service();

export default defineComponent({
    components: {
        itinerary,
        tour_details
    },
    props: {
        tour: {type: Object as PropType<product_search_product>, default: new product_search_product()}
    },
    data() {
        return {
            Display_Itinerary: false as boolean,
            Display_Add_To_Cart: false as boolean,
            trip_index: 0 as number
        }
    },
    methods: {
        image(): string {
            if (this.tour?.media_asset != null) {
                return this.tour?.media_asset?.asset_url + "?anchor=center&mode=crop&width=600";
            }

            return "";
        },
        imageAltText(): string 
        {
            return this.tour?.media_asset?.name ?? "Image of " + this.tour?.name;
        },
        display_description(): string {
            if (this?.tour?.description_headline != null) {
                return this.tour.description_headline;
            }

            let Output_Text: string = this?.tour?.description_short ?? this?.tour?.description ?? "";

            if (Output_Text != null) {
                if (Output_Text.length > 61) {
                    return Output_Text.slice(0, 60);
                } else {
                    return Output_Text;
                }
            }

            return "";
        },
        Add_To_Cart() {
            this.Display_Add_To_Cart = false;
        },
        Full_Tour_To_Cart() {
            this.$router.push({ path: '/', query: { open: this?.tour?.tour_id }});
            this.Display_Add_To_Cart = false;
        },
        Action_Add_To_Cart() {
            this.trip_index = this.$store.getters.get_Basket?.trips.length;

            this.$refs.tour_details.download_product(this?.tour?.tour_id);
            this.$store.commit('Initialise_Basket', { Currency: this.$store.getters.get_Currency?.currency_info?.iso_currency_code, Trip_Index: this.trip_index, Tour_ID: this?.tour?.tour_id });

            this.Display_Add_To_Cart = true;
        },
        durationInDays(): string {
            let days = this.tour?.duration?.days ?? 0;
            if(this.tour?.duration?.hours ?? 0 >= 1){
                days++;
            }

            if(days == 1){
                return days.toString() + " day";
            }
            else
            {
                return days.toString() + " days";
            }
        },
        isAgent_Login(): boolean {
            return this.$store.getters.get_Agent_Login?.valid_login == true;
        },
        isAAT(): boolean {
            if (import.meta.env.VITE_DEFAULT_PARENT == "AAT") {
                return true;
            }
            return false;
        },
        isMobile(): boolean{
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    mounted() {
    }
})