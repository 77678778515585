﻿import { request_payment } from "./request_payment";
import { request_payment_token } from "./request_payment_token";
import { request_payment_url } from "./request_payment_url";

export class request {
    public booking_id: number | null = null;
    public iso_currency_code: string | null = null;
    public full_payment: boolean | null = null;
    public deposit: boolean | null = null;
    public online_sale: boolean | null = null;
    public nett_sale: boolean = false;
    public amount: number | null = null;
    public token: request_payment_token = new request_payment_token();
    public payments: request_payment[] = [];
    public payment_urls: request_payment_url = new request_payment_url();
}