﻿<template>
  <div class="cart-summary md:mx-4">
    <div class="basket-summary" x-data="{ open: false }">
      <itinerary_breakdown></itinerary_breakdown>

      <div class="summary-total section">
        <div class="row">
          <h6 class="title text-base text-heading font-bold">Price from</h6>
          <p class="balance text-2xl text-black text-action font-bold mb-0">{{ get_Total() }} <span class="font-normal text-lg">{{ get_Curreny_Code()+" " }}</span><small class="font-normal text-sm">includes all taxes.</small></p>
          <p v-if="!get_Booking_Summary().includes('Please select passenger')" class="basket-summary-text text-sm mt-2 mb-0" v-html="get_Booking_Summary()"></p>
        </div>
      </div>
      <div class="summary-action section">
        <div class="row">
          <button type="button" v-if="!$store.getters.get_isQuoteCalculating && !get_Booking_Summary().includes('Please select passenger')" class="button button-pink-book full" :class="{ hidden: !isBookable() }" @click="Add_To_Cart()">
            Add to Cart
          </button>

          <button type="button" :disabled="true" v-if="$store.getters.get_isQuoteCalculating" class="button button-pink-book full">
            <svg class="animate-spin h-5 w-5 mr-3 inline" :class="{ hidden: !this.$store.getters.get_isQuoteCalculating }" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 512 512" stroke="currentColor">
              <path fill="currentColor" d="M440.65 12.57l4 82.77A247.16 247.16 0 0 0 255.83 8C134.73 8 33.91 94.92 12.29 209.82A12 12 0 0 0 24.09 224h49.05a12 12 0 0 0 11.67-9.26 175.91 175.91 0 0 1 317-56.94l-101.46-4.86a12 12 0 0 0-12.57 12v47.41a12 12 0 0 0 12 12H500a12 12 0 0 0 12-12V12a12 12 0 0 0-12-12h-47.37a12 12 0 0 0-11.98 12.57zM255.83 432a175.61 175.61 0 0 1-146-77.8l101.8 4.87a12 12 0 0 0 12.57-12v-47.4a12 12 0 0 0-12-12H12a12 12 0 0 0-12 12V500a12 12 0 0 0 12 12h47.35a12 12 0 0 0 12-12.6l-4.15-82.57A247.17 247.17 0 0 0 255.83 504c121.11 0 221.93-86.92 243.55-201.82a12 12 0 0 0-11.8-14.18h-49.05a12 12 0 0 0-11.67 9.26A175.86 175.86 0 0 1 255.83 432z"></path>
            </svg>
          </button>
          <span :class="{ hidden: isBookable() }">Please select passengers and departure date to proceed and see price.</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" src="./basket.ts"></script>
