<template>

    <header class="header">
        <header_menu></header_menu>
        <hero v-if="!isValidView()" title="404 (page not found)" subtitle="Whoops! You've hit a 404 (page not found). Sorry, you'll have to turn around. " :hero_image="getHeroImage()" ></hero>
        <div v-if="!isValidView()" class="flex flex-col items-center"><button type="button" class="m-10 px-10 py-2 button button-pink-book" @click="home_Click()">Return to Home Page</button></div>
    </header>

    <main v-if="isValidView()" class="main">

        <!--TEMPORARY MESSAGE DURING PAYMENT FAILURES-->
        <div v-if="!isAgent_Login() && GetBrand() == 'HEX'" class="cart-addon m-10" >
            <p class="m-auto font-bold text-black pt-2 text-center">If you don't receive a confirmation email please contact us at info@highlandexplorertours.com</p>
        </div>
        <div v-if="!isAgent_Login() && GetBrand() == 'HAG'" class="cart-addon m-10" >
            <p class="m-auto font-bold text-black pt-2 text-center">If you don't receive a confirmation email please contact us at info@haggisadventures.com</p>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2">
            <h1 class="pl-4">Payment</h1>
            <basket_header :position=4></basket_header>
            <p class="mt-0 text-grey-default"></p>
        </div>

        <div class="row mt-16" :hidden="!Display_Agent_Payment_Summary()">
            <div class="cart-summary w-11/12 lg:w-8/12 xl:w-8/12 mx-auto">
                <div class="basket-summary border-aat-grey px-28 rounded-xl">
                    <payment_summary :isGross="selected_payment_method?.nett_gross"></payment_summary>
                    <payment_method ref="payment_method" @updateMethod="Update_Method"></payment_method>
                    <div :hidden="selected_payment_method?.method != 'CC'"><payment_credit_card credit_card_div="payment-gateway-component" consumer_display="false"></payment_credit_card></div>
                    <div :hidden="selected_payment_method?.method == 'CC'"><payment_confirmation @updatePayment="Update_Payment"></payment_confirmation></div>
                </div>
            </div>
        </div>

        <div :hidden="Display_Agent_Payment_Summary()"><payment_credit_card class="min-h-fit" credit_card_div="payment-gateway-component-consumer" consumer_display="true"></payment_credit_card></div>

    </main>

    <footer_item></footer_item>

</template>

<script lang="ts" src="./4a_Payment.ts"></script>