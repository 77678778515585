﻿<template>
    <h5 class="mb-0 clear">
        Trip Summary
        <button v-if="get_total_price() > 0" type="button" class="text-dropdown text-right float-right" @click="isOpen = !isOpen" :active="isOpen" :hidden="display_open">
            <span class="text-sm text-heading block">Itinerary breakdown</span>
        </button>
    </h5>
    <div :hidden="display_details()">
        <div class="summary-price section my-4" v-for="trip in get_Trips()">
            <div :hidden="!zeroPriceCheck(trip)">
                <div class="row item flex flex-wrap py-1 leading-normal">
                    <div class="w-9/12 md:w-8/12 font-semibold">
                        {{product_name(trip)}}
                    </div>
                    <div v-if="isAAT()" class="w-3/12 md:w-4/12 text-right font-semibold" v-html="product_duration(trip)"></div>
                    <div v-else class="w-3/12 md:w-4/12 text-right font-semibold" v-html="durationInDays(trip)"></div>
                    <div class="breakdown w-full my-2 text-xs text-grey-default leading-normal" >
                        <div :class="{hidden: isAddon(trip)}">
                            <div name="item timings" :class="{ hidden: product_check_dates(trip) }">
                                <div class="items flex flex-wrap" :hidden="product_start_end_date(trip) == ''">
                                    {{product_start_end_date(trip)}}
                                    <p></p>
                                </div>
                                <div class="items flex flex-wrap" :hidden="product_start_end_date(trip) != ''">
                                    Start date: {{product_start_date(trip)}}
                                </div>
                                <div class="items flex flex-wrap" :hidden="product_start_end_date(trip) != ''">
                                    End date: {{product_end_date(trip)}}
                                </div>
                            </div>
                        </div>
                        <div class="items flex flex-wrap" v-for="passenger in trip.passengers">
                            <div class="w-9/12 md:w-8/12">{{passenger_info(passenger)}}</div>
                            <div class="w-3/12 md:w-4/12 text-right">{{passenger_price(passenger)}}</div>
                        </div>
                    </div>
                </div>

                <div class="row item flex flex-wrap py-1 leading-normal" v-if="room_total(trip) == ''">
                    <div class="w-9/12 md:w-8/12 font-semibold" :class="{'hidden': room_breakdown(trip).length < 1}">
                        Accommodation
                    </div>
                    <div class="w-3/12 md:w-4/12 text-right font-semibold" v-html="room_total(trip)"></div>
                    <div class="breakdown w-full my-2 text-xs text-grey-default leading-normal">
                        <div class="items flex flex-wrap" v-for="room in room_breakdown(trip)">
                            <div class="w-9/12 md:w-8/12">{{room.room_type}}</div>
                            <div class="w-3/12 md:w-4/12 text-right">{{room.room_price}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <h6 v-if="getRef() != '0' && display_open">Booking reference number: #{{ getRef() }}</h6>

</template>

<script lang="ts" src="./itinerary_breakdown.ts"></script>