﻿import { defineComponent } from 'vue';

import { Booking } from "../../../services/booking";
import { booking_search_group } from "../../../objects/v1_2/Booking_Details/booking_search_group";
import { document } from "../../../objects/v1_2/Booking_Details/document";

let Book: Booking = new Booking();

export default defineComponent({
    name: "documentation_item",
    props: {
        document: document
    },
    data() {
        return {
            Send_Documents: false,
            email: ""
        }
    },
    methods: {
        async Email_Document(message_type: string) {
            this.Send_Documents = true;
            await Book.Booking_Message(this.$store, this.message(message_type), this.email, String(this.$store.getters.get_Booking?.group_booking_id));
            this.Send_Documents = false;
        },
        message(message_type: string): string {

            switch (message_type) {
                case "Booking Statement":
                    return "Consumer_Statement";

                case "Booking Itinerary":
                    return "Consumer_Itinerary";

                case "Agent Statement":
                    return "Agent_Statement";
            }

            return message_type;
        }
    },
    mounted() {
    }
})