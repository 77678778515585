﻿<template>
    <header class="header">
        <header_menu></header_menu>
        
        <hero title="404 (page not found)" subtitle="Whoops! You've hit a 404 (page not found). Sorry, you'll have to turn around. " :hero_image="getHeroImage()" ></hero>
        <div class="flex flex-col items-center"><button type="button" class="m-10 px-10 py-2 button button-pink-book" @click="home_Click()">Return to Home Page</button></div>
     </header>
     

    

</template>
<script lang="ts" src="./NotFound.ts"></script>