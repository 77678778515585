﻿import { defineComponent } from 'vue';
import { week_day } from '../../../objects/front_end/calendar/week_day';
import { local_filter } from '../../../objects/front_end/search/local_filter';

import { filtertag } from "../../../objects/v1_3/web_engine/filtertag";
import { Booking } from "../../../services/booking";
export default defineComponent({
    name: "agent_options",
    components: {
        

    },
    emits: [
        "onFilterChange"
    ],
    props: {
    },
    data() {
        return {
            displayPopup: true,
            inner: false,
        }
    },
    methods: {
        setfilter(option: number){
            switch(option){
                case 1:
                    //HAG DAY
                    this.setTag("38444f03-bd83-4e76-a697-08dcad59f08d", "Brand");
                    this.setTag("5added73-426f-4859-a696-08dcad59f08d", "Duration");
                    this.displayPopup = false;
                    break;
                case 2:
                    //HAG MULTI
                    this.setTag("38444f03-bd83-4e76-a697-08dcad59f08d", "Brand");
                    this.setTag("c537e7e6-101d-41fb-a694-08dcad59f08d", "Duration");
                    this.displayPopup = false;
                    break;
                case 3:
                    //HEX DAY
                    this.setTag("73472bb6-f324-44fd-a695-08dcad59f08d", "Brand");
                    this.setTag("5added73-426f-4859-a696-08dcad59f08d", "Duration");
                    this.displayPopup = false;
                    break;
                case 4:
                    this.setTag("73472bb6-f324-44fd-a695-08dcad59f08d", "Brand");
                    this.setTag("c537e7e6-101d-41fb-a694-08dcad59f08d", "Duration");
                    this.displayPopup = false;
                    break;
                default:
                    this.displayPopup = false;

                    break;
            }
            this.onFilterChange();

        },
        setTag(tagID: string, filterName: string){
            this.$store.getters.get_Search_Filters.forEach((filter: local_filter) => {

                if(filter.name?.toUpperCase().trim() == filterName.toUpperCase().trim()){

                    filter.tags.forEach((tag: filtertag) => {
                        if(tag.tag_id == tagID){
                            filter.selectedTag = tag;
                            this.onFilterChange()
                        }
                    });
                }
            });
        },
        onFilterChange() {
            this.$store.commit("Toggle_Filters", { filters: this.Get_Selected_Filters() });
            this.$emit("onFilterChange");
        },
        Get_Selected_Filters(): filtertag[] {
            let selectedFilterTags: filtertag[] = [];

            this.getFilters().forEach((filterstatus: local_filter) => {
                if (filterstatus.selectedTag.tag_id != null) {
                    filterstatus.selectedTag.filter_name = filterstatus.name;
                    selectedFilterTags.push(filterstatus.selectedTag);
                }
            });

            return selectedFilterTags;
        },
        getFilters(): local_filter[] {
            let Output: local_filter[] = [];

            this.$store.getters.get_Search_Filters.forEach((filter: local_filter) => {
                Output.push(filter);
            });

            return Output;
        },
        isMobile(): boolean{
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Tablet/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
        MobileCardClass(): string{
            if(this.isMobile()){
                if(/iPad/i.test(navigator.userAgent)) {
                    return "grid grid-cols-2 gap-5 w-11/12"
                }
                else{
                    return "grid grid-cols-2 gap-5 w-full";
                }
            }
            return "w-full"
        },
        MobileButtonClass(): string{
            if(this.isMobile()){
                return "w-3/4 m-1 my-8"
            }
            return "w-1/4 m-1 my-3"
        },
        MobileTitleClass(): string{
            if(this.isMobile()){
                return ""
            }
            return "pt-3"
        }

    },
    mounted() {
        if(this.isMobile()){
            this.displayPopup = false;
        }
    }
})