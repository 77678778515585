﻿import { defineComponent } from 'vue';

import booking_cancel from "./booking_cancel.vue";
import booking_line from "./booking_line.vue";

import { Booking } from "../../../booking_engine/services/booking";
import { Currency_Service } from "../../../system/services/currency";

let Book: Booking = new Booking();
let Currency_Services: Currency_Service = new Currency_Service();

export default defineComponent({
    components: {
        booking_cancel,
        booking_line
    },
    props: {
    },
    data() {
        return {
            Hide_Cancel: true as boolean,
            Cancelling_Booking: false as boolean
        }
    },
    methods: {
        get_Trip_Price(): string {
            return Currency_Services.Format_Currency(Book.get_full_total_price(this.$store.getters.get_Booking, this.$store), this.$store.getters.get_Currency);
        },
        get_Trip_Nett_Price(): string {
            return Currency_Services.Format_Currency(Book.get_full_nett_total(this.$store.getters.get_Booking, this.$store), this.$store.getters.get_Currency);
        },
        get_Trip_Discount(): string {
            return Currency_Services.Format_Currency(Book.get_discount(this.$store.getters.get_Booking), this.$store.getters.get_Currency);
        },
        get_Trip_Balance(): string {
            return Currency_Services.Format_Currency(Book.get_Balance(this.$store.getters.get_Booking), this.$store.getters.get_Currency);
        },
        get_Trip_Paid(): string {
            return Currency_Services.Format_Currency(Book.get_Paid(this.$store.getters.get_Booking), this.$store.getters.get_Currency);
        },
        Display_Discount(): boolean {
            return Book.get_discount(this.$store.getters.get_Booking) > 0;
        },
        Cancel_Booking() {
            this.Hide_Cancel = false;
        },
        Make_Payment() {
            this.$router.push('/creditcard');
        },
        async Action_Cancel_Booking() {
            this.Cancelling_Booking = true;
            let Cancel_Response = await Book.Cancel_Booking(this.$store, this.$store.getters.get_Booking.group_booking_id, this.$store.getters.get_Booking_Cancellation);
            this.$store.commit("Setup_Booking", { Booking_Details: await Book.Get_Book(this.$store.getters.get_Booking.group_booking_id, this.$store.getters.get_Agent_Login) });

            this.$store.commit("Initialise_Booking_Cancellation");
            this.Hide_Cancel = true;
            this.Cancelling_Booking = false;

            if (this.$store.getters.get_Booking.trips.length == 0) {
                this.$router.push('/manage_bookings');
            }
        }
    },
    mounted() {
    }
});