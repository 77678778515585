﻿import { booking_group } from "../objects/v1_2/Booking/Booking_Group";
import { booking_search_group } from "../objects/v1_2/Booking_Details/booking_search_group";
import { currency } from "../objects/v1_2/Currency/currency";

import { local_filter } from "../objects/front_end/search/local_filter";
import { product_detail } from "../objects/v1_3/product/product/product_detail";
import { capacity_capacity } from "../objects/v1_3/product/capacity/capacity_capacity";

import { request_login } from "../objects/agent/identity/v1_3/request_login";
import { response_login } from "../objects/agent/identity/v1_3/response_login";

import { response_login_payment } from "../objects/agent/identity/v1_3/response_login_payment";

import { request } from "../objects/v1_2/Booking_Delete/Request/request";

import Vuex from 'vuex'
import { Basket } from "./basket";
import { Booking } from "./booking";
import { Product } from "./product";

import { filtertag } from "../objects/v1_3/web_engine/filtertag";
import { currency_info } from "../objects/v1_2/Currency/currency_info";
import { pickup_point } from "../objects/v1_3/product/pickup/pickup_point";

let basket: Basket = new Basket();
let booking: Booking = new Booking();
let product: Product = new Product();

const state = ({
    booking_basket: undefined,
    booking_details: undefined,
    booking_details_unedited: undefined,
    agent_login_info: new response_login(),
    agent_login_request: new request_login(),
    currency: undefined,
    selected_capacity: new capacity_capacity(),
    selected_payment: new response_login_payment(),
    products:  undefined,
    filters: [],
    product_search: "",
    search_result: "",
    filter_tags: [],
    tags: [],
    payment_full: true,
    payment_deposit: false,
    isQuoteCalculating: false,
    booking_cancelation: new request(),
    payment_attempts: 0,
    isValidPassengers: false,
    seats_free_selected_date: -1
})

const getters = {
    get_Basket: (state: { booking_basket: booking_group; }) => {
        if (state.booking_basket == undefined) {
            if (sessionStorage.getItem("booking_basket") != undefined) {
                state.booking_basket = JSON.parse(sessionStorage.booking_basket);
            } else {
                state.booking_basket = basket.Initialise_Basket(new booking_group(), "", 0, "");
            }
        }

        return state.booking_basket;
    },
    get_Booking: (state: { booking_details: booking_search_group; }) => {
        if (state.booking_details == undefined) {
            if (sessionStorage.getItem("booking_details") != undefined) {
                state.booking_details = JSON.parse(sessionStorage.booking_details);
            } else {
                state.booking_details = booking.Blank_Booking();
            }
        }

        return state.booking_details;
    },
    get_Booking_Un_Edited: (state: { booking_details_unedited: booking_search_group; }) => {
        if (state.booking_details_unedited == undefined) {
            if (sessionStorage.getItem("booking_details_unedited") != undefined) {
                state.booking_details_unedited = JSON.parse(sessionStorage.booking_details_unedited);
            } else {
                state.booking_details_unedited = booking.Blank_Booking();
            }
        }

        return state.booking_details_unedited;
    },
    get_Products: (state: { products: product_detail[] }) => {
        if (state.products == undefined) {
            if (sessionStorage.getItem("products") != undefined) {
                state.products = JSON.parse(sessionStorage.products);
            } else {
                state.products = [];
            }
        }

        return state.products;
    },
    get_Search_Filters: (state: { filters: local_filter[] }) => {
        if (state.filters == undefined) {
            state.filters = [];
        }

        return state.filters;
    },
    get_Search_Filter_Tags: (state: { filter_tags: filtertag[] }) => {
        if (state.filter_tags == undefined) {
            state.filter_tags = [];
        }

        return state.filter_tags;
    },
    get_Search_Tags: (state: { tags: filtertag[] }) => {
        if (state.tags == undefined) {
            state.tags = [];
        }

        return state.tags;
    },
    get_Search_Products: (state: { product_search: string; }) => {
        return state.product_search;
    },
    get_Search_Result: (state: { search_result: string }) => {
        if (state.search_result == undefined) {
            state.search_result = "";
        }

        return state.search_result;
    },
    get_Currency: (state: { currency: currency; }) => {
        if (state.currency == undefined) {
            if (sessionStorage.getItem("currency_info") != undefined) {
                state.currency = JSON.parse(sessionStorage.currency_info);
            } else {
                state.currency = new currency();
            }
        }

        return state.currency;
    },
    get_Agent_Login: (state: { agent_login_info: response_login; }) => {
        return state.agent_login_info; 
    },
    get_Agent_Login_Request: (state: { agent_login_request: request_login; }) => {
        return state.agent_login_request;
    },
    get_Payment_Full_Payment: (state: { payment_full: boolean; }) => {
        return state.payment_full;
    },
    get_Payment_Attempts: (state: { payment_attempts: number; }) => {
        return state.payment_attempts;
    },
    get_seats_free_selected_date: (state: { seats_free_selected_date: number; }) => {
        return state.seats_free_selected_date;
    },
    get_Payment_Deposit: (state: { payment_deposit: boolean; }) => {
        return state.payment_deposit;
    },
    get_isQuoteCalculating: (state: { isQuoteCalculating: boolean; }) => {
        return state.isQuoteCalculating;
    },
    get_isValidPassengers: (state: { isValidPassengers: boolean; }) => {
        return state.isValidPassengers;
    },
    get_Selected_Capacity: (state: { selected_capacity: capacity_capacity }) => {
        return state.selected_capacity;
    },
    get_Selected_Payment: (state: { selected_payment: response_login_payment }) => {
        return state.selected_payment;
    },
    get_Booking_Cancellation: (state: { booking_cancelation: request }) => {
        return state.booking_cancelation;
    }
}

const actions = {
}

const mutations = {
    Initialise_Basket(state: { booking_basket: booking_group; }, { Currency, Trip_Index, Tour_ID }: any) {
        state.booking_basket = basket.Initialise_Basket(state.booking_basket, Currency, Trip_Index, Tour_ID);
        sessionStorage.booking_basket = JSON.stringify(state.booking_basket);
    },
    Initialise_Basket_Departure(state: { booking_basket: booking_group; selected_capacity: capacity_capacity; }, { Trip_Index, Departure_Date, Capacity }: any) {
        state.selected_capacity = Capacity;
        state.booking_basket = basket.Initialise_Basket_Departure(state.booking_basket, Trip_Index, Departure_Date);
        sessionStorage.booking_basket = JSON.stringify(state.booking_basket);
    },
    Change_Basket_Primary_Tour(state: { booking_basket: booking_group; }, { Trip_Index, Tour_ID }: any) {
        state.booking_basket = basket.Change_Basket_Primary_Tour(state.booking_basket, Trip_Index, Tour_ID);
        sessionStorage.booking_basket = JSON.stringify(state.booking_basket);
    },
    Add_Add_On(state: { booking_basket: booking_group; }, { Parent_Tour_ID, Tour_ID, Passengers }: any) {
        state.booking_basket = basket.Add_Add_On(state.booking_basket, Parent_Tour_ID, Tour_ID, Passengers);
        sessionStorage.booking_basket = JSON.stringify(state.booking_basket);
    },
    Add_Add_On_Passenger_Change(state: { booking_basket: booking_group; }, { Parent_Tour_ID, Tour_ID, Index }: any) {
        state.booking_basket = basket.Add_Add_On_Passenger_Change(state.booking_basket, Parent_Tour_ID, Tour_ID, Index);
        sessionStorage.booking_basket = JSON.stringify(state.booking_basket);
    },
    Remove_Product(state: { booking_basket: booking_group; }, { Tour_ID }: any) {
        state.booking_basket = basket.Remove_Product(state.booking_basket, Tour_ID);
        sessionStorage.booking_basket = JSON.stringify(state.booking_basket);
    },
    Initialise_Passenger(state: { booking_basket: booking_group; }) {
        state.booking_basket = basket.Initialise_Passenger(state.booking_basket);
        sessionStorage.booking_basket = JSON.stringify(state.booking_basket);
    },
    Passenger_Add(state: { booking_basket: booking_group; }, { Age_Category }: any) {
        state.booking_basket = basket.Passenger_Add(state.booking_basket, Age_Category);
        sessionStorage.booking_basket = JSON.stringify(state.booking_basket);
    },
    Passenger_Remove(state: { booking_basket: booking_group; }, { Age_Category }: any) {
        state.booking_basket = basket.Passenger_Remove(state.booking_basket, Age_Category);
        sessionStorage.booking_basket = JSON.stringify(state.booking_basket);
    },
    Initialise_Booking_Room_Type(state: { booking_basket: booking_group; }, { Trip_Index, Room_Type }: any) {
        state.booking_basket = basket.Initialise_Booking_Room_Type(state.booking_basket, Trip_Index, Room_Type);
        sessionStorage.booking_basket = JSON.stringify(state.booking_basket);
    },
    Initialise_Pickup_Point(state: { booking_basket: booking_group; }, { Trip_Index, Pickup_Point }: any) {
        state.booking_basket = basket.Initialise_Pickup_Point(state.booking_basket, Trip_Index, Pickup_Point);
        sessionStorage.booking_basket = JSON.stringify(state.booking_basket);
    },
    Setup_Booking(state: { booking_details: any; booking_details_unedited: any; }, { Booking_Details }: any) {
        state.booking_details = Booking_Details;
        state.booking_details_unedited = JSON.parse(JSON.stringify(Booking_Details));

        sessionStorage.booking_details = JSON.stringify(state.booking_details);
        sessionStorage.booking_details_unedited = JSON.stringify(state.booking_details_unedited);
    },
    Product_Add(state: { products: product_detail[]; }, { Product_Details }: any) {
        state.products = product.Add_Product(state.products, Product_Details);

        sessionStorage.products = JSON.stringify(state.products);
    },
    Initialise_Currency(state: { currency: any; }, { Currency_Details }: any) {
        state.currency = Currency_Details;

        sessionStorage.currency_info = JSON.stringify(state.currency);
    },
    Setup_Filter(state: { filters: any; }, { Filter_Items }: any) {
        state.filters = Filter_Items;
    },
    Toggle_Filters(state: { filter_tags: any; }, { filters }: any) {
        state.filter_tags = filters;
    },
    Toggle_Tags(state: { tags: filtertag[]; }, { Tag }: any) {
        state.tags = product.Toggle_Tag(Tag, state.tags);
    },
    Reset_Filter_Tags(state: { tags: filtertag[]; }) {
        state.tags = [];
    },
    Set_Search_Products(state: { product_search: any; }, { Search_Product }: any) {
        state.product_search = Search_Product;
    },
    Set_Search_Result(state: { search_result: any; }, { Result }: any) {
        state.search_result = Result;
    },
    Set_Payment_Full_Payment(state: { payment_full: any; }, { Payment }: any) {
        state.payment_full = Payment;
    },
    Set_Payment_Attempts(state: { payment_attempts: number; }, { Attempts }: any) {
        state.payment_attempts = Attempts;
    },
    Set_seats_free_selected_date(state: { seats_free_selected_date: number; }, { Seats }: any) {
        state.seats_free_selected_date = Seats;
    },
    Set_Payment_Deposit(state: { payment_deposit: any; }, { Payment }: any) {
        state.payment_deposit = Payment;
    },
    Set_isQuoteCalculating(state: { isQuoteCalculating: any; }, { isQuoteCalculating }: any) {
        state.isQuoteCalculating = isQuoteCalculating;
    },
    Set_isValidPassengers(state: { isValidPassengers: any; }, { isValidPassengers }: any) {
        state.isValidPassengers = isValidPassengers;
    },
    Logout_Agent(state: { agent_login_request: request_login, agent_login_info: response_login, booking_basket: booking_group, booking_details: booking_search_group, booking_details_unedited: booking_search_group}) {
        state.agent_login_request = new request_login();
        state.agent_login_info = new response_login();

        sessionStorage.removeItem("agent_login_info");
        sessionStorage.removeItem("booking_basket");
        sessionStorage.removeItem("booking_details");
        sessionStorage.removeItem("booking_details_unedited");

        state.booking_basket = basket.Initialise_Basket(new booking_group(), "", 0, "");
        state.booking_details = booking.Blank_Booking();
        state.booking_details_unedited = booking.Blank_Booking();
    },
    Initialise_Agent_Login(state: { agent_login_info: any; }) {
        if (sessionStorage.getItem("agent_login_info") != undefined) {
            state.agent_login_info = JSON.parse(sessionStorage.agent_login_info);
        }
    },
    Set_Agent_Login(state: { agent_login_info: any; agent_login_request: any; }, { Agent_Login, Agent_Request_Login }: any) {
        state.agent_login_info = Agent_Login;
        state.agent_login_request = Agent_Request_Login;

        sessionStorage.agent_login_info = JSON.stringify(Agent_Login);
    },
    Set_Agent_Request_Login(state: { agent_login_request: any; }, { Agent_Request_Login }: any) {
        state.agent_login_request = Agent_Request_Login;
    },
    Set_Payment_Selected(state: { selected_payment: any; }, { Selected_Payment }: any) {
            state.selected_payment = Selected_Payment;
    },
    Initialise_Booking_Cancellation(state: { booking_details: booking_search_group, booking_cancelation: request }) {
        state.booking_cancelation = booking.Create_Cancellation_Object(state.booking_details);
    }
}

export default new Vuex.Store({
    state,
    getters,
    actions,
    mutations
})