﻿export class Date_Service {

    month_names: string[] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    day_names: string[] = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    constructor() {
    }
    is_today(test_date: Date): boolean {
        return test_date.getDate() == new Date(Date.now()).getDate() && test_date.getMonth() == new Date(Date.now()).getMonth() && test_date.getFullYear() == new Date(Date.now()).getFullYear();
    }
    string_date(test_date: Date): string {
        return test_date.getFullYear() + "/" + (test_date.getMonth() + 1) + "/" + (test_date.getDate());
    }
    string_time(test_date: Date): string {
        return test_date.getHours() + ":" + test_date.getMinutes();
    }
    string_full_date(date: string): string {
        var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        var format_date = new Date(date);

        return format_date.toLocaleDateString("en-US", options);
    }
    string_date_output(test_date: Date): string {
        return test_date.getFullYear() + "-" + this.padded_date(test_date.getMonth() + 1) + "-" + this.padded_date(test_date.getDate());
    }
    compare_date(date: string | null): string {
        if (date == null) {
            return "";
        }

        if (date.length == 19) {
            date = date.substring(0, 10);
        }

        var input_date: Date = new Date(Date.parse(date));

        return input_date.getFullYear() + "/" + this.padded_date(input_date.getMonth()) + "/" + this.padded_date(input_date.getDate());
    }
    compare_date_realnojs(date: string | null): string {
        if (date == null) {
            return "";
        }

        if (date.length == 19) {
            date = date.substring(0, 10);
        }

        var input_date: Date = new Date(Date.parse(date));

        return input_date.getFullYear() + "/" + this.padded_date(input_date.getMonth()+1) + "/" + this.padded_date(input_date.getDate());
    }
    compare_dates(date1: string | null, date2: string | null): number {
        let compare_date1 = this.compare_date(date1);
        let compare_date2 = this.compare_date(date2);

        if (compare_date1 === "") { return -2;}
        if (compare_date2 === "") { return 2;}

        var input_date1: Date = new Date(Date.parse(compare_date1));
        var input_date2: Date = new Date(Date.parse(compare_date2));

        if (input_date1 < input_date2) {return -1};
        if (input_date1 == input_date2) {return 0};
        if (input_date1 > input_date2) {return 1};
        
        return 0;
    }
    compare_dates_between(compare_date: string | null, date_from: string | null, date_to: string | null): boolean {
        var is_between :boolean = false;

        let compare_date_date = this.compare_date_realnojs(compare_date);
        let compare_date_from = this.compare_date_realnojs(date_from);
        let compare_date_to = this.compare_date_realnojs(date_to);

        var date_from_isnull : boolean = compare_date_from == "";
        var date_to_isnull : boolean = compare_date_to == "";

        var input_date_date: Date = new Date(Date.parse(compare_date_date));
        var input_date_from: Date = new Date(Date.parse(compare_date_from));
        var input_date_to: Date = new Date(Date.parse(compare_date_to));

        var date_from_islargerorequel = input_date_date >= input_date_from;
        var date_to_islessorequel = input_date_date <= input_date_to;

        if ((date_from_isnull || date_from_islargerorequel) && (date_to_isnull || date_to_islessorequel)) {
            is_between = true
        };
    
        return is_between;
    }
    string_compare_dates_between(compare_date: string | null, date_from: string | null, date_to: string | null): boolean {
        var is_between :boolean = false;

        var str_compare_date = compare_date ?? "";
        var str_date_from = date_from ?? "";
        var str_date_to = date_to ?? "";

        if(str_compare_date == "" || str_date_from == "" || str_date_to == "") {
            return true;
        }

        if(str_date_from < str_compare_date && str_date_to > str_compare_date){
            is_between = true;
        }

        return is_between;
    }
    output_date(date: string): string {
        var input_date: Date = new Date(date);

        return input_date.getFullYear() + "/" + this.padded_date(input_date.getMonth() + 1) + "/" + this.padded_date(input_date.getDate());
    }

    short_date(date: string): string {
        var input_date: Date = new Date(date);

        return this.padded_date(input_date.getDate()) + "/" + this.padded_date(input_date.getMonth() + 1) + "/" + input_date.getFullYear();
    }

    padded_date(input_info: number): string {
        return ("0" + input_info).substr(input_info.toString().length - 1);
    }

    full_date(date: string): string {
        if (date == undefined) { return ""; }

        var date_string = new Date(date.replace("-", "/")); //replacing changes from users local time to forced UK time

        return this.day_names[date_string.getDay()] + " " + date_string.getDate() + " " + this.month_names[date_string.getMonth()] + " " + date_string.getFullYear();
    }

    display_date(date: string): string {
        if (date == undefined) { return ""; }

        var date_string = new Date(date);

        return date_string.getDate() + " " + this.month_names[date_string.getMonth()] + " " + date_string.getFullYear();
    }

    remove_time(date: Date) {
        return date.setHours(0, 0, 0, 0);
    }

    previous_monday(from_date: Date): Date {

        var mDifference = from_date.getDay() - 1;

        if (mDifference < 0) {
            mDifference += 7;
        }

        from_date.setDate(from_date.getDate() + (mDifference * -1));

        return from_date;
    }

    next_month(year: number, month: number): Date {
        var Current_Month = new Date(year, month, 1);

        return Current_Month;
    }

    month_name_month(month: number): string {
        return this.month_names[month - 1];
    }

    month_name_date(date: Date): string {
        return this.month_names[date.getMonth()];
    }

    utc_time_from_1970(): number {
        return new Date(Date.now()).getTime() / 1000 | 0;
    }

    getReturnDate(startDate: string, duration: number): string {
        if (startDate == undefined || startDate == "") return "";

        var retrunDate = new Date(startDate.replace("-", "/")); //replacing changes from users local time to forced UK time
        retrunDate.setDate(retrunDate.getDate() + (duration - 1));

        return this.string_date(retrunDate);
    }

    getReturnTime(startTime: string, hours: number, minutes: number): string {
        if (startTime == undefined || startTime == "") return "";

        var returnTime = new Date(startTime);
        returnTime.setHours(returnTime.getHours() + hours);
        returnTime.setMinutes(returnTime.getMinutes() + minutes);

        return this.string_time(returnTime);
    }

    getReturnDateAsLongString(startDate: string, duration: number): string {
        var returnDate: string = this.getReturnDate(startDate, duration);
        return this.full_date(returnDate);
    }
}