﻿<template>
    <div class="slideout-content">

        <div id="tours" class="w-full">
            <h3>Select A Trip</h3>
            <p v-if="taglineCheck() == 'TOURSDATE'">There are <strong>{{FilteredProducts(tours_selected.products).length}} tours</strong> departing on <strong>{{search_date()}}</strong>.</p>
            <p v-if="taglineCheck() == 'DATE' ">These tours are departing on <strong>{{search_date()}}</strong>.</p>
            <p v-if="taglineCheck() == 'TOURS'">There are <strong>{{FilteredProducts(tours_selected.products).length}} tours</strong> departing on this day.</p>
            <p v-if="taglineCheck() == ''">Theres tours are departing on this day.</p>
            
            <div class="card-group">
                <div class="card-h list mt-8" v-for="product in FilteredProducts(tours_selected.products)">
                    <div class="flex flex-wrap md:flex-nowrap border border-form rounded mx-0 md:mx-4">
                        <div class="w-4/12 cover relative sm:flex-none h-62 w-32 md:w-36 lg:w-52 overflow-hidden rounded-l">
                            <a @click="show_itinerary(product)" class="map-thumb absolute right-4 top-3 bg-black text-white p-2 rounded-full opacity-90 hover:bg-white hover:text-action hover:opacity-100">
                                <span class="icon small inline">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="map" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="svg-inline--fa fa-map fa-w-18 fa-2x"><path fill="currentColor" d="M560.02 32c-1.96 0-3.98.37-5.96 1.16L384.01 96H384L212 35.28A64.252 64.252 0 0 0 191.76 32c-6.69 0-13.37 1.05-19.81 3.14L20.12 87.95A32.006 32.006 0 0 0 0 117.66v346.32C0 473.17 7.53 480 15.99 480c1.96 0 3.97-.37 5.96-1.16L192 416l172 60.71a63.98 63.98 0 0 0 40.05.15l151.83-52.81A31.996 31.996 0 0 0 576 394.34V48.02c0-9.19-7.53-16.02-15.98-16.02zM30.63 118.18L176 67.61V387.8L31.91 441.05l-1.28-322.87zM208 387.71V67.8l160 56.48v319.91l-160-56.48zm192 56.68V124.2l144.09-53.26 1.28 322.87L400 444.39z"></path></svg>
                                </span>
                            </a>
                            <a href="#undefined">
                                <img class="h-full w-full object-cover" :src="image(product)" :alt="imageAltText(product)">
                            </a>
                        </div>
                        <div class="w-8/12 content px-8 py-4 md:flex-grow justify-between leading-normal">
                            <div class="innner">
                                <p class="mt-0">
                                    <span class="icon small inline">
                                        <svg class="inline -mt-1 mr-2 svg-inline--fa fa-clock fa-w-16 fa-2x" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="clock" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm216 248c0 118.7-96.1 216-216 216-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216zm-148.9 88.3l-81.2-59c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h14c6.6 0 12 5.4 12 12v146.3l70.5 51.3c5.4 3.9 6.5 11.4 2.6 16.8l-8.2 11.3c-3.9 5.3-11.4 6.5-16.8 2.6z"></path></svg>
                                        <div v-if="isAAT()">{{duration(product)}}</div>
                                        <div v-else>{{durationInDays(product)}}</div>
                                    </span>
                                </p>
                                <h5><a href="" class="text-heading">{{product.name}}</a></h5>
                                <p>Departs from {{product.start_location.city}}</p>
                                <p>{{removeHtmlTags(product.description_short)}}</p>
                            </div>
                            <div class="flex items-center">
                                <p class="icon inline my-2">
                                    <a :href="product.web_url" target="_blank" class="text-sm font-semibold text-link cursor-pointer">
                                        See tour details
                                    </a>
                                </p>
                            </div>
                        </div>
                        <div class="price flex-none w-full md:w-44 bg-form md:bg-white">
                            <div class="py-2 md:py-0 px-4 inner relative text-right md:text-center h-full md:flex flex-wrap md:content-center justify-center">
                                <p class="text-heading md:text-action inline md:block mr-4 md:mr-0 text-2xl">
                                    <strong>From {{price(product)}}</strong>
                                    <small class="font-semibold text-sm">{{currency()}}</small>
                                    <span class="md:block text-sm text-body">per person</span>
                                </p>
                                <a hef="" @click="product_selected(product)" class="btn inline-block md:block md:w-full px-4 py-2 bg-heading text-white rounded text-sm hover:bg-action hover:text-white hover:no-underline cursor-pointer">Dates & prices</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="slideout" :hidden="isHidden" 
         x-transition:enter="transition ease-out duration-500 -mr-64"
         x-transition:enter-start=""
         x-transition:enter-end="transform -translate-x-64"
         x-transition:leave="transform ease-out duration-75"
         x-transition:leave-start=""
         x-transition:leave-end="transform translate-x-64"
         style="z-index: 201 !important;">
        <div class="slideout-inner">
            <button class="close no-bg" @click="isHidden = true"></button>

            <div class="slideout-content">
                <itinerary :tour_id="tour_id" :banner="true" ref="itinerary" v-if="!isHidden"></itinerary>
            </div>

        </div>
    </div>
</template>

<script lang="ts" src="./tour_list.ts"></script>