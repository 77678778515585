﻿<template>

    <h2>BOOKING DETAILS</h2>

    <booking_line :trip="trip" v-for="trip in this.$store.getters.get_Booking?.trips"></booking_line>

    <hr @hidden="!Display_Discount()" />

    <div class="grid grid-cols-2" :hidden="!Display_Discount()">
        <div>Trip Price</div>
        <div class="text-right">{{get_Trip_Price()}}</div>
        <div>Discount</div>
        <div class="text-right">{{get_Trip_Discount()}}</div>
    </div>

    <hr class="m-2" />

    <div class="grid grid-cols-2 p-2 m-2">
        <div><h6>TOTAL</h6></div>
        <div class="text-right"><h6>{{get_Trip_Price()}}</h6></div>
        <div><h6>NETT</h6></div>
        <div class="text-right"><h6>{{get_Trip_Nett_Price()}}</h6></div>
    </div>

    <hr class="m-2" />

    <div class="grid grid-cols-2 p-2 m-2">
        <div><h6>BALANCE REMAINING</h6></div>
        <div class="text-right"><h6>{{get_Trip_Balance()}}</h6></div>
        <div><h6>PAID</h6></div>
        <div class="text-right"><h6>{{get_Trip_Paid()}}</h6></div>
    </div>

    <div class="grid grid-cols-2">
        <div><button type="button" class="button button-pink-book full" @click="Make_Payment()">Make Payment</button></div>
        <div class="text-right">
            <div class="button outline button-default w-full sm:w-3/4 rounded-full" @click="Cancel_Booking()">Cancel Booking</div>
        </div>
    </div>

    <div class="fixed z-10 overflow-y-auto top-0 w-full left-0" :hidden="Hide_Cancel">
        <div class="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity">
                <div class="absolute inset-0 bg-gray-900 opacity-75" />
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>
            <div class="p-6 inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <booking_cancel></booking_cancel>
                <div class="grid grid-cols-2">
                    <div>
                        <button type="button" class="button button-pink-book full" @click="Action_Cancel_Booking()">
                            <svg class="animate-spin h-5 w-5 mr-3 inline" :hidden="Cancelling_Booking == false" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 512 512" stroke="currentColor">
                                <path fill="currentColor" d="M440.65 12.57l4 82.77A247.16 247.16 0 0 0 255.83 8C134.73 8 33.91 94.92 12.29 209.82A12 12 0 0 0 24.09 224h49.05a12 12 0 0 0 11.67-9.26 175.91 175.91 0 0 1 317-56.94l-101.46-4.86a12 12 0 0 0-12.57 12v47.41a12 12 0 0 0 12 12H500a12 12 0 0 0 12-12V12a12 12 0 0 0-12-12h-47.37a12 12 0 0 0-11.98 12.57zM255.83 432a175.61 175.61 0 0 1-146-77.8l101.8 4.87a12 12 0 0 0 12.57-12v-47.4a12 12 0 0 0-12-12H12a12 12 0 0 0-12 12V500a12 12 0 0 0 12 12h47.35a12 12 0 0 0 12-12.6l-4.15-82.57A247.17 247.17 0 0 0 255.83 504c121.11 0 221.93-86.92 243.55-201.82a12 12 0 0 0-11.8-14.18h-49.05a12 12 0 0 0-11.67 9.26A175.86 175.86 0 0 1 255.83 432z" class=""></path>
                            </svg>
                            Cancel Booking
                        </button>
                    </div>
                    <div class="text-right"><div class="button outline button-default w-full sm:w-3/4 rounded-full" @click="Hide_Cancel = true">Exit</div></div>
                </div>
            </div>
        </div>
    </div>

</template>
<script lang="ts" src="./booking_details.ts"></script>
