﻿import { defineComponent } from 'vue';

import booking_line_passenger from "./booking_line_passenger.vue";

import { booking_info } from "../../../booking_engine/objects/v1_2/Booking_Details/booking_info";

import { Date_Service } from "../../../system/services/date_service";

let Date_Services: Date_Service = new Date_Service();

export default defineComponent({
    components: {
        booking_line_passenger
    },
    props: {
        trip: booking_info
    },
    data() {
    },
    methods: {
        Departure_Date(): string {
            return Date_Services.full_date(this.trip?.departure_date ?? "");
        }
    },
    mounted() {
    }
});