﻿import { booking_confirmation } from "./booking_confirmation";
import { booking_passenger } from "./booking_passenger";
import { booking_information } from "./booking_information";
import { trip } from "../Booking/Trip";

export class booking {
    passengers: booking_passenger[] = [];
    trips: trip[] = [];
    booking_confirmation: booking_confirmation = new booking_confirmation();
    booking_information: booking_information = new booking_information();
}