﻿<template>

    <div class="form-group float-label w-full option-group alt">

        <div class="w-full text-center mb-8">
            <h3 class="uppercase mt-0">Select Consultant</h3>
            <p>Account: {{get_Agent_Info()?.agency?.name}}</p>
        </div>

        <div class="form-data">
            <div class="flex" id="Select_Consultant_Click_Away" v-click-away="Consultant_Click_Away">
                <svg class="inline mt-4 mr-1 h-5 w-8 flex-none svg-inline--fa fa-user fa-w-14 fa-2x" aria-hidden="true" focusable="false" data-prefix="far" data-icon="user" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M313.6 304c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4 14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16 47.6 0 86.4 38.8 86.4 86.4V464zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0 80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z" class=""></path></svg>
                <button class="select-button" @click="isOpen = true" :active="isOpen">
                    <small>{{selected_contact.first_name}} {{selected_contact.second_name}} - {{selected_contact.email}}</small>
                </button>
                <div class="select-dropdown" v-if="isOpen">
                    <ul>
                        <li class="overflow-x-hidden" v-for="contact in get_Agent_Info().contacts" :key="contact.contact_id"
                            :selected="contact.contact_id === selected_contact.contact_id"
                            @click="contact_selected(contact)">
                            {{contact.first_name}} {{contact.second_name}} - {{contact.email}}
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <p class="ml-12 mb-8 textButton" @click="NewProfile()"><small>Can't find your name? Create a profile </small></p>

        <div v-if="newProfileOpen" class="form-data flex">
            <svg class="inline mt-4 mr-1 h-5 w-8 flex-none svg-inline--fa fa-user-plus fa-w-20 fa-2x" aria-hidden="true" focusable="false" data-prefix="far" data-icon="user-plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M224 288c79.5 0 144-64.5 144-144S303.5 0 224 0 80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96zm89.6 256c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4 14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16 47.6 0 86.4 38.8 86.4 86.4V464zm224-248h-72v-72c0-8.8-7.2-16-16-16h-16c-8.8 0-16 7.2-16 16v72h-72c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h72v72c0 8.8 7.2 16 16 16h16c8.8 0 16-7.2 16-16v-72h72c8.8 0 16-7.2 16-16v-16c0-8.8-7.2-16-16-16z" class=""></path></svg>
            <input class="flex-1" type="text" name="name" id="name" v-model="new_contact.first_name" placeholder="Consultant Name">
        </div>
        <div v-if="newProfileOpen" class="form-data flex">
            <svg class="inline mt-5 mr-1 h-5 w-8 flex-none svg-inline--fa fa-envelope fa-w-16 fa-2x" aria-hidden="true" focusable="false" data-prefix="far" data-icon="envelope" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z" class=""></path></svg>
            <input class="flex-1" type="text" name="email" id="email" v-model="new_contact.email" placeholder="consultant@email.com">
        </div>

        <p class="mt-16 text-center">
            <a @click="Click_Continue()" class="button button-book w-full sm:w-3/4 rounded-full">
                <svg class="animate-spin h-5 w-5 mr-3 inline"  :class="{ hidden: !logging_in }" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 512 512" stroke="currentColor">
                    <path fill="currentColor" d="M440.65 12.57l4 82.77A247.16 247.16 0 0 0 255.83 8C134.73 8 33.91 94.92 12.29 209.82A12 12 0 0 0 24.09 224h49.05a12 12 0 0 0 11.67-9.26 175.91 175.91 0 0 1 317-56.94l-101.46-4.86a12 12 0 0 0-12.57 12v47.41a12 12 0 0 0 12 12H500a12 12 0 0 0 12-12V12a12 12 0 0 0-12-12h-47.37a12 12 0 0 0-11.98 12.57zM255.83 432a175.61 175.61 0 0 1-146-77.8l101.8 4.87a12 12 0 0 0 12.57-12v-47.4a12 12 0 0 0-12-12H12a12 12 0 0 0-12 12V500a12 12 0 0 0 12 12h47.35a12 12 0 0 0 12-12.6l-4.15-82.57A247.17 247.17 0 0 0 255.83 504c121.11 0 221.93-86.92 243.55-201.82a12 12 0 0 0-11.8-14.18h-49.05a12 12 0 0 0-11.67 9.26A175.86 175.86 0 0 1 255.83 432z" class=""></path>
                </svg>
                {{ButtonText()}}
            </a>
        </p>

    </div>

</template>

<script lang="ts" src="./select_consultant.ts"></script>