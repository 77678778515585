﻿<template>

    <div class="w-100">
        <label class="float-left py-1">{{pass.name}}</label>
        <label :hidden="isMobile()" class="text-sm float-left text-blue w-48 text-grey-default">{{get_passenger_description(pass.name)}}</label>
        <label :hidden="!isMobile()" class="text-sm float-left text-blue w-28 text-grey-default">{{get_passenger_description(pass.name)}}</label>
    </div>
    <div class="flex-0 w-full text-right">
        <button class="minus icon small text-heading bg-form  border-2 border-form hover:border-blue-light rounded relative inline-flex items-center px-4 py-2" @click="remove_passenger">
            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="minus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-minus fa-w-12 fa-2x"><path fill="currentColor" d="M368 224H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h352c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z" class=""></path></svg>
        </button>
        <label class="leading-none relative inline-flex items-center px-5 py-1 font-bold">
            {{passenger_total()}}
        </label>
        <button class="plus icon small text-heading bg-form border-2 border-form hover:border-blue-light rounded relative inline-flex items-center px-4 py-2" @click="add_passenger">
            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-plus fa-w-12 fa-2x"><path fill="currentColor" d="M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z" class=""></path></svg>
        </button>
    </div>

</template>

<script lang="ts" src="./passenger.ts"></script>