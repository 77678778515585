﻿<template>

    <header class="header">
        <header_menu></header_menu>
        <hero v-if="!isValidView()" title="404 (page not found)" subtitle="Whoops! You've hit a 404 (page not found). Sorry, you'll have to turn around. " :hero_image="getHeroImage()" ></hero>
        <div v-if="!isValidView()" class="flex flex-col items-center"><button type="button" class="m-10 px-10 py-2 button button-pink-book" @click="home_Click()">Return to Home Page</button></div>
    </header>

    <main v-if="isValidView()" class="main">

        <div class="grid grid-cols-1 md:grid-cols-2">
            <h1 class="ml-1">Checkout</h1>
            <basket_header :position=3></basket_header>
        </div>

        <p class="ml-1 mt-0 text-grey-default">Enter all passenger details and choose your payment option.</p>

        <div class="cart-content flex flex-col md:flex-row">

            <div class="payment cart-content flex flex-col md:flex-row">

                <div class="cart-items w-full md:w-7/12 lg:w-8/12 pr-0 md:pr-4 xl:pr-8 mb-8">

                    <basket v-if="isAgent_Login()"></basket>
                    <basket_minimal v-else></basket_minimal>
                    <passengers></passengers>
                    <booking_notes></booking_notes>

                </div>

                <trip_summary></trip_summary>

            </div>
        </div>

    </main>

    <footer_item></footer_item>

</template>

<script lang="ts" src="./4_check_out.ts"></script>