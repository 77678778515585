﻿import { defineComponent, PropType } from 'vue';

import itinerary from "../../common/itinerary.vue";

import { product_search } from "../../../../objects/v1_3/product/search/product_search";
import { product_search_product } from "../../../../objects/v1_3/product/search/product_search_product";

import { Booking } from "../../../../services/booking";
import { Product } from "../../../../services/product";
import { Currency_Service } from "../../../../../system/services/currency";
import { Date_Service } from "../../../../../system/services/date_service";

const Book = new Booking();
const Prod = new Product();
const Currency_Services = new Currency_Service();
const Date_Services = new Date_Service();

export default defineComponent({
    name: "tour_list",
    components: {
        itinerary
    },
    emits: [
        "tourSelected"
    ],
    props: {
        tours_selected: Object as PropType<product_search>,
        travel_date: String
    },
    data() {
        return {
            isHidden: true as boolean,
            tour_id: "" as string
        }
    },
    methods: {
        duration(product_details: product_search_product): string {
            return Prod.product_search_duration(product_details);
        },
        currency(): string {
            return this.$store.getters.get_Currency.currency_info.iso_currency_code;
        },
        price(product_details: product_search_product): string {
            return Currency_Services.Format_Currency(Prod.product_pricing(Book.Pricing_Age_Category(this.$store), this.$store.getters.get_Currency, product_details), this.$store.getters.get_Currency);
        },
        image(product_details: product_search_product): string {
            if (product_details?.media_asset != null) {
                return product_details?.media_asset?.asset_url + "?anchor=center&mode=crop&width=900";
            }

            return "";
        },
        imageAltText(tour_details: product_search_product): string {
            return tour_details?.media_asset?.name ?? "Image of " + tour_details.name;
        },
        product_selected(product_details: product_search_product) {
            this.$emit("tourSelected", this.travel_date, product_details.tour_id);
        },
        search_date(): string {
            return Date_Services.full_date(this.travel_date ?? "");
        },
        show_itinerary(product_details: product_search_product) {
            this.isHidden = false;
            this.tour_id = product_details?.tour_id;

            this.$refs.itinerary.download_product(product_details?.tour_id);
        },
        isMobile(): boolean{
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
        isAgent_Login(): boolean {
            return this.$store.getters.get_Agent_Login?.valid_login == true;
        },
        isAAT(): boolean {
            if (import.meta.env.VITE_DEFAULT_PARENT == "AAT") {
                return true;
            }
            return false;
        },
        durationInDays(product_details: product_search_product): string {
            let days = product_details?.duration?.days ?? 0;
            if(product_details?.duration?.hours ?? 0 >= 1){
                days++;
            }

            if(days == 1){
                return days.toString() + " day";
            }
            else
            {
                return days.toString() + " days";
            }
        },
        removeHtmlTags(text: string): string {
            return text.replace(/<[^>]*>?/gm, '').replace(/&nbsp;/g, ' ');
        },
        FilteredProducts(productList: product_search_product[]): product_search_product[] {
            let returnList: product_search_product[] = [];
            let add: boolean = true;

            productList.forEach(product => {
                returnList.forEach(returnProduct => {
                    //removes whitespace and accomodation type from name
                    if(returnProduct.name.replace(/ *\([^)]*\) */g, "").trim().toLowerCase() == product.name.replace(/ *\([^)]*\) */g, "").trim().toLowerCase()){
                        if(Prod.product_pricing(Book.Pricing_Age_Category(this.$store), this.$store.getters.get_Currency, product) > Prod.product_pricing(Book.Pricing_Age_Category(this.$store), this.$store.getters.get_Currency, returnProduct)){
                            add = false;
                        }
                        else{
                            returnList.splice(returnList.indexOf(returnProduct), 1);
                        }
                    }
                });
                if(add) returnList.push(product);
                add = true;
                
            });

            return returnList;
        },
        taglineCheck(): string {
            let tourCount = this.FilteredProducts(this.tours_selected.products).length
            let searchDate = this.search_date();
            let returnString = "";

            if(tourCount > 0 && tourCount != null){
                returnString += "TOURS"
            }
            if(!searchDate.includes("NaN") && !searchDate.includes("undefined") && searchDate != null){
                returnString += "DATE"
            }
            return returnString;
        }
    }
})