﻿import { Security } from "./security";
import { Rest_API } from "../../system/services/rest_api";

import { request_login } from "../../booking_engine/objects/agent/identity/v1_3/request_login";
import { response_login } from "../../booking_engine/objects/agent/identity/v1_3/response_login";
import { response_login_contact } from "../../booking_engine/objects/agent/identity/v1_3/response_login_contact";

import { return_status } from "../../booking_engine/objects/v1_3/return_status";

import { request_password_reset } from "../../booking_engine/objects/agent/identity/v1_3/reguest_password_reset";
import { response_password_reset } from "../../booking_engine/objects/agent/identity/v1_3/response_password_reset";

import { request_register } from "../../booking_engine/objects/agent/identity/v1_3/request_register";
import { response_register } from "../../booking_engine/objects/agent/identity/v1_3/response_register";
import agent_login from "../../agent/login/agent_login";

export class Agent_Login {

    Rest: Rest_API = new Rest_API();
    Secure: Security = new Security();

    public async Login(Login_Request: request_login): Promise<response_login> {
        return await this.Rest.Post_Data_Secure<request_login, response_login>(import.meta.env.VITE_AGENT_IDENTITY_URL + "/api/v1.3/" + import.meta.env.VITE_AGENT_IDENTITY_APP_ID + "/identity/login", await this.Secure.Get_Hash_Password_Parts(String(import.meta.env.VITE_AGENT_IDENTITY_URL), String(import.meta.env.VITE_AGENT_IDENTITY_APP_ID), String(import.meta.env.VITE_AGENT_IDENTITY_API_KEY)), Login_Request).then(response => { return response.json(); });
    }
    public async Password_Reset(Password_Reset_Request: request_password_reset): Promise<response_password_reset> {
        return await this.Rest.Post_Data_Secure<request_password_reset, response_password_reset>(import.meta.env.VITE_AGENT_IDENTITY_URL + "/api/v1.3/" + import.meta.env.VITE_AGENT_IDENTITY_APP_ID + "/identity/reset_password", await this.Secure.Get_Hash_Password_Parts(String(import.meta.env.VITE_AGENT_IDENTITY_URL), String(import.meta.env.VITE_AGENT_IDENTITY_APP_ID), String(import.meta.env.VITE_AGENT_IDENTITY_API_KEY)), Password_Reset_Request).then(response => { return response.json(); });
    }
    public async Register(Register_Request: request_register): Promise<response_register> {
        return await this.Rest.Post_Data_Secure<request_register, response_register>(import.meta.env.VITE_AGENT_IDENTITY_URL + "/api/v1.3/" + import.meta.env.VITE_AGENT_IDENTITY_APP_ID + "/identity/register", await this.Secure.Get_Hash_Password_Parts(String(import.meta.env.VITE_AGENT_IDENTITY_URL), String(import.meta.env.VITE_AGENT_IDENTITY_APP_ID), String(import.meta.env.VITE_AGENT_IDENTITY_API_KEY)), Register_Request).then(response => { return response.json(); });
    }
    public async Update_Contact(Agent_ID: string, Consultant: response_login_contact): Promise<return_status> {
        return await this.Rest.Put_Data_Secure<response_login_contact, return_status>(import.meta.env.VITE_AGENT_IDENTITY_URL + "/api/v1.3/" + import.meta.env.VITE_AGENT_IDENTITY_APP_ID + "/identity/login/" + Agent_ID, await this.Secure.Get_Hash_Password_Parts(String(import.meta.env.VITE_AGENT_IDENTITY_URL), String(import.meta.env.VITE_AGENT_IDENTITY_APP_ID), String(import.meta.env.VITE_AGENT_IDENTITY_API_KEY)), Consultant).then(response => { return response.json(); });
    }
}