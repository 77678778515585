﻿import { defineComponent } from 'vue';

import { response_login_contact } from "../../booking_engine/objects/agent/identity/v1_3/response_login_contact";

import header_menu from "../../booking_engine/components/header/menu.vue"

import account_information from "./account_information.vue";
import change_account_password from "./change_account_password.vue";
import consultant_information from "./consultant_information.vue";

export default defineComponent({
    components: {
        header_menu,
        account_information,
        change_account_password,
        consultant_information
    },
    props: {
    },
    data() {
    },
    methods: {
        Get_Contact(): response_login_contact {
            return this.$store.getters.get_Agent_Login?.selected_contact;
        }
    },
    mounted() {
    }
});