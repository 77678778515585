﻿<template>
    <div class="border border-black text-center" style="background-color: rgb(228 228 231)" :hidden="Even_Row()">{{Departure_Date()}}</div>
    <div class="border border-black text-center" style="background-color: rgb(228 228 231)" :hidden="Even_Row()">{{booking.group_booking_id}}</div>
    <div class="border border-black text-center" style="background-color: rgb(228 228 231)" :hidden="Even_Row()">{{booking.your_reference}}</div>
    <div class="border border-black text-center" style="background-color: rgb(228 228 231)" :hidden="Even_Row()">{{Lead_Passenger()}}</div>
    <div class="border border-black text-center" style="background-color: rgb(228 228 231)" :hidden="Even_Row()">{{Booking_Date()}}</div>
    <div class="border border-black text-center" style="background-color: rgb(228 228 231)" :hidden="Even_Row()">{{Get_Consultant()}}</div>
    <div class="border border-black text-center" style="background-color: rgb(228 228 231)" :hidden="Even_Row()">{{Booking_Status()}}</div>
    <div class="border border-black text-center" style="background-color: rgb(228 228 231)" :hidden="Even_Row()" @click="Booking_Details(booking.group_booking_id)">
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="external-link-alt" class="inline h-5 w-8 flex-none svg-inline--fa fa-external-link-alt fa-w-14 fa-2x" :class="{ hidden: search_booking == true }"  role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"></path></svg>
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="external-link-alt" class="animate-spin inline h-5 w-8 flex-none svg-inline--fa fa-external-link-alt fa-w-14 fa-2x" :class="{ hidden: search_booking == false }" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"></path></svg>
    </div>

    <div class="border border-black text-center" :hidden="!Even_Row()">{{Departure_Date()}}</div>
    <div class="border border-black text-center" :hidden="!Even_Row()">{{booking.group_booking_id}}</div>
    <div class="border border-black text-center" :hidden="!Even_Row()">{{booking.your_reference}}</div>
    <div class="border border-black text-center" :hidden="!Even_Row()">{{Lead_Passenger()}}</div>
    <div class="border border-black text-center" :hidden="!Even_Row()">{{Booking_Date()}}</div>
    <div class="border border-black text-center" :hidden="!Even_Row()">{{Get_Consultant()}}</div>
    <div class="border border-black text-center" :hidden="!Even_Row()">{{Booking_Status()}}</div>
    <div class="border border-black text-center" :hidden="!Even_Row()" @click="Booking_Details(booking.group_booking_id)">
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="external-link-alt" class="inline h-5 w-8 flex-none svg-inline--fa fa-external-link-alt fa-w-14 fa-2x" :class="{ hidden: search_booking == true }" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"></path></svg>
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="external-link-alt" class="animate-spin inline h-5 w-8 flex-none svg-inline--fa fa-external-link-alt fa-w-14 fa-2x" :class="{ hidden: search_booking == false }" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"></path></svg>
    </div>
</template>
<script lang="ts" src="./booking_line.ts"></script>
