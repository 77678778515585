﻿import { defineComponent } from 'vue';

import passenger from "./passenger.vue";

import { booking_search_group } from "../../../objects/v1_2/Booking_Details/booking_search_group";

export default defineComponent({
    name: "passengers",
    components: {
        passenger
    },
    props: {
    },
    methods: {
        get_Booking(): booking_search_group {
            return this.$store.getters.get_Booking;
        },
        Prevent_Submit() {
            console.log("Override Submit");
        },
        isAgent_Login(): boolean {
            return this.$store.getters.get_Agent_Login?.valid_login == true;
        },
        GetBrand(): string {
            return String(import.meta.env.VITE_DEFAULT_CLASS);
        }
    },
    mounted() {
    }
})