﻿<template>

    <div class="flex-1 filter-tags">
        <ul>
            <li v-for="tag in getTags()"><a :active="tag_Selected(tag)" @click="toggleTag(tag)">{{tag.name}}</a></li>
        </ul>
    </div>

</template>

<script lang="ts" src="./filter_buttons.ts"></script>