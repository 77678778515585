import { add_on } from "./Add_On";
import { room } from "./Room";
import { trip_passenger } from "./Trip_Passenger";

export class trip {
    public booking_identifier: string | null = null;
    public tour_id: string | null = null;
    public departure_date: string | null = null;
    public promo_code: string | null = null;
    public pickup_point: string | null = null;
    public passengers: trip_passenger[] = [];
    public add_ons: add_on[] = [];
    public rooms: room[] = [];
}
