﻿<template>

    <header class="header">
        <header_menu></header_menu>
        <hero :title=getConfirmationText() :subtitle="getSubtitle()" :hero_image="'https://contenthub.radicaltravel.com/media/2083/payment_failed.jpg'" class="bg-blend-hue"></hero>
    </header>
  
    <main class="main">
  
        <div class="row mt-12">
            <h2>{{ getFailReason() }}</h2>
        </div>
  
        <div class="row">
            
  
            <div class="payment cart-content flex flex-col md:flex-row">
  
              
                <div class="cart-items w-full md:w-7/12 lg:w-8/12 pr-0 md:pr-4 xl:pr-8 mb-8">
                  <button v-if="attempts < 3 " type="button" class="button button-standard full" @click="retryPayment()">
                    Retry Payment
                  </button>
                  <button v-else type="button" class="border-grey-default bg-form border-blank button text-base text-action full" disabled>
                    Max Attempts Reached, please contact us or try again later.
                  </button>
                    <h3>{{get_Contact_Text()}}</h3>
                </div>
                
  
                <trip_summary></trip_summary>
            </div>
         </div>
  
    </main>
  
    <footer_item></footer_item>
  
  </template>
  
  <script lang="ts" src="./5b_failed.ts"></script>