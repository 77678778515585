﻿import { defineComponent } from 'vue';

import documentation_item from "./documentation_item.vue";

import { Booking } from "../../../services/booking";
import { booking_search_group } from "../../../objects/v1_2/Booking_Details/booking_search_group";

let Book: Booking = new Booking(); 

export default defineComponent({
    name: "documentation",
    components: {
        documentation_item
    },
    props: {
    },
    data() {
    },
    methods: {
        get_Booking(): booking_search_group {
            return this.$store.getters.get_Booking;
        }
    },
    mounted() {
    }
})