﻿import { defineComponent, PropType } from 'vue'

import { Product } from "../../../../services/product";
import { Dates } from "../../../../filters/dates";

import { product_detail } from '../../../../objects/v1_3/product/product/product_detail';
import { product_detail_seller_information_month } from '../../../../objects/v1_3/product/product/product_detail_seller_information_month';

const dates = new Dates();
const Prod = new Product();

export default defineComponent({
    name: "calendar_month",
    emits: [
        "monthChange"
    ],
    props: {
        tour_ids: { type: Array as PropType<string[]>, default: [] },
        selected_tour_id: String,
        selected_month: Object as PropType<product_detail_seller_information_month>
    },
    data() {
        return {
            isOpen: false as Boolean
        }
    },
    methods: {
        First_Month(): boolean {
            return this.month_index() == 0;
        },
        Last_Month(): boolean {
            return this.month_index() == (this.months().length - 1);
        },
        months() {
            if (this.tour_ids?.length == 0) {
                return [];
            }

            let Mounts_Output: product_detail_seller_information_month[] = [];
            this.tour_ids.forEach((tour_id: string) => {
                Prod.Get_Details_Sync(tour_id, this.$store)?.seller_information?.months?.forEach(month => {
                    if (Mounts_Output.findIndex(f => f.year == month.year && f.month == month.month) == -1) {
                        Mounts_Output.push(month);
                    }
                });
            });

            Mounts_Output = Mounts_Output.sort((obj1, obj2) => {
                if (obj1.year < obj2.year || (obj1.year == obj2.year && obj1.month < obj2.month)) {
                    return -1;
                }

                if (obj2.year > obj2.year || (obj1.year == obj2.year && obj1.month > obj2.month)) {
                    return 1;
                }

                return 0;
            });

            if (Mounts_Output.length > 0 && this.selected_month?.year == undefined) {
                this.month_selected(Mounts_Output[0]);
            }

            return Mounts_Output;
        },
        month_selected(month: product_detail_seller_information_month) {
            this.isOpen = false;

            this.$emit("monthChange", month);
        },
        format_month(month: product_detail_seller_information_month): string {
            return dates.Month_Text(month?.month) + " " + String(month?.year);
        },
        key(month: product_detail_seller_information_month): string {
            return month.year + "-" + month.month;
        },
        previous_Month() {
            let Month_Index: number = this.month_index();
            Month_Index--;

            this.month_selected(this.months()[Month_Index]);
        },
        next_Month() {
            let Month_Index: number = this.month_index();
            Month_Index++;

            this.month_selected(this.months()[Month_Index]);
        },
        month_index(): number {
            let Index: number = 0;
            let Selected_Index: number = -1;
            let Month_Selected = this.selected_month;

            this.months().forEach(function (month: product_detail_seller_information_month) {
                if (Month_Selected?.year == month.year && Month_Selected?.month == month.month) {
                    Selected_Index = Index;
                    return;
                }
                Index++;
            });

            return Selected_Index;
        }
    },
    mounted() {
    }
})