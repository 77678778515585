﻿import { currency } from "../../booking_engine/objects/v1_2/Currency/currency";

export class Currency_Service {

    Format_Currency(Price: number, Currency: currency): string {
        if (Currency.currency_info.iso_currency_code == "") {
            return String(Price);
        }

        return Price.toLocaleString(window.navigator.language, { style: 'currency', currency: Currency.currency_info.iso_currency_code, currencyDisplay: 'symbol' }).replace("A", "");
    }
}