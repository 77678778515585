﻿export class pickup_point {
    public point_id: string | null = null;
    public original_id: string | null = null;
    public name: string | null = null;
    public address: string | null = null;
    public description: string | null = null;
    public notes: string | null = null;
    public original_time: string | null = null;
    public minutes_before: number | null = null;
    public latitude: string | null = null;
    public longitude: string | null = null;
}