import { createApp } from 'vue'
import VueClickAway from "vue3-click-away";
import VueGtag from "vue-gtag";

import App from "@mod/main/web_booking_agent/App.vue";

import "@mod/css/style2.css";

if(import.meta.env.VITE_DEFAULT_CLASS == 'RTG') { 
    import("@mod/css/styleRTG.css");
}
if(import.meta.env.VITE_DEFAULT_CLASS == 'aatkings') { 
    import("@mod/css/styleAAT.css");
}

import router from "@mod/main/web_booking_agent/routes";
import store from "@mod/tms/booking_engine/store/index";

createApp(App)
    .use(store)
    .use(VueGtag, {
        config: { id: import.meta.env.VITE_GA_ID },
        appName: import.meta.env.VITE_GA_APP_NAME,
        pageTrackerScreenviewEnabled: true
    }, router)
    .use(router)
    .use(VueClickAway)
    .mount('#app');