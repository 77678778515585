﻿import { request_login_contact } from "./request_login_contact";

export class request_login {
    request_login() {
        this.new_contact = new request_login_contact();
    }
    public user_name: string | null = null;
    public password: string | null = null;
    public contact_id: string | null = null;
    public new_contact: request_login_contact = new request_login_contact();
}