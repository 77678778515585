﻿import { defineComponent } from 'vue';

import { Date_Service } from "../../../system/services/date_service";

let Date_Services = new Date_Service();

export default defineComponent({
    components: {
    },
    props: {
    },
    data() {
    },
    methods: {
        Get_Booking_Reference(): string {
            return this.$store.getters.get_Booking?.group_booking_id;
        },
        Get_Agent_Reference(): string {
            return this.$store.getters.get_Booking?.your_reference;
        },
        Get_Consultant(): string {
            if (this.$store.getters.get_Booking?.trips.length > 0) {
                return this.$store.getters.get_Booking?.trips[0]?.booking_info?.agency_contact ?? "";
            }

            return "";
        },
        Get_Booking_Date(): string {
            if (this.$store.getters.get_Booking?.trips.length > 0) {
                return Date_Services.full_date(this.$store.getters.get_Booking?.trips[0].booking_date ?? "");
            }

            return "";
        }
    },
    mounted() {
    }
});