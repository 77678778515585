﻿import { defineComponent } from 'vue';
import header_menu from "../../../modules/tms/booking_engine/components/header/menu.vue"
import footer_item from "../../../modules/tms/booking_engine/components/base/common/footer.vue"
import basket_header from "../../../modules/tms/booking_engine/components/base/common/header.vue"
import hero from "../../../modules/tms/booking_engine/components/header/hero.vue"

export default defineComponent({
    components: {
        footer_item,
        header_menu,
        basket_header,
        hero
    },
    methods: {
        getHeroImage(): string {
            return String(import.meta.env.VITE_DEFAULT_HERO_IMAGE);
        },
        home_Click() {
            this.$router.push('/');
        },
    }
})