﻿import { defineComponent } from 'vue';

import { Product } from '../../../services/product';
import { Booking } from '../../../services/booking';
import { Web_Engine } from "../../../services/web_engine"

import { age_cat } from "../../../objects/v1_3/web_engine/age_cat"

import { Currency_Service } from '../../../../system/services/currency';
import { Date_Service } from '../../../../system/services/date_service';
import { List_Service } from '../../../../system/services/list_processing';
import { createObjectBindingPattern } from 'typescript';
import { room } from '../../../objects/v1_2/Booking/Room';
import { trip_passenger } from '../../../objects/v1_2/Booking/Trip_Passenger';

import { booking_info } from '../../../objects/v1_2/Booking_Details/booking_info';
import { client_booking } from '../../../objects/v1_2/Booking_Details/client_booking';
import { booking_trip_room } from '../../../objects/v1_2/Booking_Details/booking_trip_room';

const Book: Booking = new Booking();
const Prod: Product = new Product();
const Web: Web_Engine = new Web_Engine();
const Currency_Services: Currency_Service = new Currency_Service();
const List_Services: List_Service = new List_Service();

export default defineComponent({
    name: "itinerary_breakdown",
    props: {
        display_open: String
    },
    data() {
        return {
            age_cat: new age_cat(),
            isOpen: false
        }
    },
    methods: {
        async download_passenger() {
            const web_engine = new Web_Engine();
            this.age_cat = await web_engine.Get_Passengers();
            this.age_cat.categories = this.age_cat.categories.sort((first, second): number => {
                return 0 - (first.name > second.name ? -1 : 1)
            });
        },
        display_details(): boolean {
            let Return_Value: boolean = false;

            if (this.display_open == "true") {
                Return_Value = false;
            } else {
                Return_Value = !this.isOpen;
            }

            return Return_Value;
        },
        get_Trips(): booking_info[]  {
            let Trips: booking_info[] = [];

            this.$store.getters.get_Booking?.trips?.forEach((Trip:booking_info) => {
                Trips.push(Trip);
            });

            return Trips;
        },
        product_name(Trip: booking_info): string {
            return Prod.Get_Details_Sync(Trip?.tour_id, this.$store).name;
        },
        getRef(): string {
            return this.$store.getters.get_Booking?.group_booking_id;
        },
        product_duration(Trip: booking_info): string {
            return Prod.product_duration_information(Prod.Get_Details_Sync(Trip?.tour_id, this.$store));
        },
        durationInDays(Trip: booking_info): string {
            let return_value: string = Prod.product_durationInDays(Prod.Get_Details_Sync(Trip?.tour_id, this.$store));
            if (return_value == "0 days") {
                return_value = "";
            }
            return return_value;
        },
        isAAT(): boolean {
            if (import.meta.env.VITE_DEFAULT_PARENT == "AAT") {
                return true;
            }
            return false;
        },
        product_overnight_duration(Trip: booking_info): string {
            return Prod.product_overnight_duration(Prod.Get_Details_Sync(Trip?.tour_id, this.$store));
        },
        product_check_dates(Trip: booking_info): boolean {
            if(Prod.trip_starts_ends(Trip?.departure_date, Prod.Get_Details_Sync(Trip?.tour_id, this.$store)).includes("0:0"))
            {
                return true
            }
            else
            {
                return false
            }
        },
        product_start_date(Trip: booking_info): string {
            return this.DateTrim(Prod.trip_starts(Trip?.departure_date, Prod.Get_Details_Sync(Trip?.tour_id, this.$store)));
        },
        product_start_end_date(Trip: booking_info): string {
            return this.DateTrim(Prod.trip_starts_ends(Trip?.departure_date, Prod.Get_Details_Sync(Trip?.tour_id, this.$store)));
        },
        product_end_date(Trip: booking_info): string {
            return this.DateTrim(Prod.trip_ends(Trip?.departure_date, Prod.Get_Details_Sync(Trip?.tour_id, this.$store)));
        },
        passenger_total(): string {
            if (this.$store.getters.get_Booking?.passengers?.length == null || this.$store.getters.get_Booking?.passengers?.length == 0) {
                return "";
            }

            if (this.$store.getters.get_Booking?.passengers?.length == 1) {
                return "1 <span class='text - sm'>guest</span>";
            }

            return this.$store.getters.get_Booking?.passengers?.length + " <span class='text - sm'>guests</span>";
        },
        DateTrim(date: string): string {
            while (date.includes("NaN") || date.includes("undefined") || date.includes("  ")) {
                date = date.replace("NaN", "").replace("undefined", "").replace("  ","").replace("at", "From").replace("to", "To");
            }
            return  date;
        },
        passenger_summary(Trip: booking_info): string {
            return Book.Passenger_Details(false, this.age_cat, Trip?.tour_id, this.$store.getters.get_Booking);
        },
        room_total(Trip: booking_info): string {
            if (this.$store.getters.get_Booking?.trips[0]?.reservation_rooms?.length == null || Trip?.reservation_rooms?.length == 0 || 1==1) {
                return "";
            }

            if (Trip?.reservation_rooms?.length == 1) {
                return "1 <span class='text - sm'>room</span>";
            }

            return this.$store.getters.get_Booking?.trips[0]?.reservation_rooms?.length + " <span class='text - sm'>rooms</span>";
        },
        room_breakdown(Trip: booking_info): { room_type: string, room_price: string }[] {
            var Room_Output: {room_type: string, room_price: string}[] = [];

            Trip?.reservation_rooms?.forEach((room: booking_trip_room )=> {
                Room_Output.push({ room_type: (room.room_type ?? ""), room_price: Currency_Services.Format_Currency(Book.getRoom_Indevidual_Price(room, Trip), this.$store.getters.get_Currency) });
            });

            return Room_Output;
        },
        passenger_info(passenger: client_booking): string {
            return Book.get_Passenger(passenger.passenger_identifier, this.$store.getters.get_Booking);
        },
        passenger_price(passenger: client_booking): string {
            return Currency_Services.Format_Currency(passenger.price, this.$store.getters.get_Currency);
        },
        isAddon(Trip: booking_info): boolean {
            if (Prod.Get_Details_Sync(Trip?.tour_id, this.$store).seller_information.sales_group.products.length < 1) 
            {
                return true;
            }
            return false;
        },
        zeroPriceCheck(trip: booking_info): boolean {
            return Book.get_price(trip) != 0;

        },
        get_total_price(): number {
            return Book.get_total_price(this.$store.getters.get_Booking, this.$store);
        },
    },
    mounted() {
        this.download_passenger();
    }
})