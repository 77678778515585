﻿import { capacity } from "./../objects/v1_3/product/capacity/capacity";

import { week } from "../objects/front_end/calendar/week";
import { week_day } from "../objects/front_end/calendar/week_day";

import { Date_Service } from "../../system/services/date_service";
import { Rest_API } from "../../system/services/rest_api";

import { capacity_capacity } from '../objects/v1_3/product/capacity/capacity_capacity';
import { product_search } from "../objects/v1_3/product/search/product_search";

export class Capacity {

    Rest: Rest_API = new Rest_API();

    public async Get_Capacity(tour_id: string, year: number, month: number): Promise<capacity> {
        return await this.Rest.Get_Data<capacity>(import.meta.env.VITE_APP_URL + "/api/v1.3/" + import.meta.env.VITE_APP_ID + "/products/" + tour_id + "/capacity/" + year + "/" + month).then(response => { return response.json(); });
    }
    public Get_Capacity_Week(tour_id: string, year: number, month: number, capacity_info: capacity, iso_currency_code: string, age_category: string): week[] {
        let Week_Output = [];
        let start_date: Date = new Date(year, month - 1, 1);

        let month_Index = start_date.getMonth();

        start_date.setDate(start_date.getDate() - (start_date.getDate() - 1));
        start_date = this.previous_monday(start_date);

        let Done: Boolean = false;
        let date: Date = start_date;
        let count = 0;

        while (!Done) {
            Week_Output.push(this.build_week(tour_id, new Date(date), month_Index, capacity_info, iso_currency_code, age_category));
            date.setDate(date.getDate() + 7);
            Done = count++ > 2 && month_Index !== date.getMonth();
            month_Index = date.getMonth();
        }

        return Week_Output;
    }
    public Get_Capacity_Search_Week(year: number, month: number, product_search: product_search, iso_currency_code: string, age_category: string): week[] {
        let Week_Output = [];
        let start_date: Date = new Date(year, month - 1, 1);

        let month_Index = start_date.getMonth();

        start_date.setDate(start_date.getDate() - (start_date.getDate() - 1));
        start_date = this.previous_monday(start_date);

        let Done: Boolean = false;
        let date: Date = start_date;
        let count = 0;

        while (!Done) {
            Week_Output.push(this.build_search_week(new Date(date), month_Index, product_search, iso_currency_code, age_category));
            date.setDate(date.getDate() + 7);
            Done = count++ > 2 && month_Index !== date.getMonth();
            month_Index = date.getMonth();
        }

        return Week_Output;
    }
    private build_search_week(date: Date, current_month: number, product_search: product_search, iso_currency_code: string, age_category: string): week {
        let Week_Output: week = new week();
        Week_Output.days = [];
        let date_service: Date_Service = new Date_Service();

        for (var i = 0; i < 7; i++) {

            var day: week_day = new week_day();
            day.name = date.getDate().toString();
            day.number = date.getDate();
            day.is_current_month = date.getMonth() === current_month;
            day.is_today = date_service.is_today(date);
            day.totalCapacity = 0;
            day.date = date_service.string_date(date);
            day.output_date = date_service.string_date_output(date);
            day.tour_id = [];

            Week_Output.days.push(this.Capacity_Search_Update(day, product_search, iso_currency_code, age_category));

            date.setDate(date.getDate() + 1);
        }

        return Week_Output;
    }
    private Capacity_Search_Update(day: week_day, product_search: product_search, iso_currency_code: string, age_category: string): week_day {
        let date_service: Date_Service = new Date_Service();        

        if (product_search != undefined) {
            if (product_search.products != null) {
                product_search.products.forEach(Product => {
                    Product.departures.forEach(Departure => {
                        if (date_service.compare_date(Departure.start_date_time) == date_service.compare_date(day.output_date)) {
                            day.totalCapacity = day.totalCapacity + 1;
                            day.tour_id.push(Product.tour_id);
                        }
                    });
                });
            }
        }

        return day;
    }
    private build_week(tour_id: string, date: Date, current_month: number, capacity_info: capacity, iso_currency_code: string, age_category: string): week {
        let Week_Output: week = new week();
        Week_Output.days = [];
        let date_service: Date_Service = new Date_Service();

        for (var i = 0; i < 7; i++) {

            var day: week_day = new week_day();
            day.name = date.getDate().toString();
            day.number = date.getDate();
            day.is_current_month = date.getMonth() === current_month;
            day.is_today = date_service.is_today(date);
            day.date = date_service.string_date(date);
            day.output_date = date_service.string_date_output(date);
            day.tour_id = [];

            Week_Output.days.push(this.Capacity_Update(tour_id, day, capacity_info, iso_currency_code, age_category));

            date.setDate(date.getDate() + 1);
        }

        return Week_Output;
    }
    private Get_Price(capacity: capacity_capacity, iso_currency_code: string, age_category: string): number {
        let Amount: number = 0;

        capacity.product_currencies.forEach(currency => {
            if (currency.iso_currency_code == iso_currency_code) {
                currency.amounts.forEach(amount => {
                    if (amount.age_category == age_category) {
                        if(Amount == 0 || amount.amount < Amount)
                        Amount = amount.amount;
                    }
                });
            }
        });
        return Amount;
    }
    private Capacity_Update(tour_id: string, day: week_day, capacity_info: capacity, iso_currency_code: string, age_category: string): week_day {
        let date_service: Date_Service = new Date_Service();

        if (capacity_info != undefined) {
            if (capacity_info.capacities != null) {
                capacity_info.capacities.forEach(Item => {
                    if (date_service.compare_date(Item.start_date_time) == date_service.compare_date(day.output_date)) {
                        day.totalCapacity = Item.free_seats;
                        day.capacity_info = Item;
                        day.tour_id.push(Item.tour_id);
                        if (Item.tour_id == tour_id) {
                            day.fromPrice = this.Get_Price(Item, iso_currency_code, age_category);
                        }
                    }
                });
            }
        }

        return day;
    }
    previous_monday(from_date: Date): Date {

        var mDifference = from_date.getDay() - 1;

        if (mDifference < 0) {
            mDifference += 7;
        }

        from_date.setDate(from_date.getDate() + (mDifference * -1));

        return from_date;
    }
}