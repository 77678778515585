﻿import { defineComponent } from 'vue';

import calendar_details from "./calendar_details.vue";
import calendar_month from "./calendar_month.vue";

import tour_list from "../tour_list/tour_list.vue";
import filter_list from "../tour_list/filter_list.vue";

import { Product } from '../../../../services/product';

import { product_search } from "../../../../objects/v1_3/product/search/product_search";
import { product_detail_seller_information_month } from '../../../../objects/v1_3/product/product/product_detail_seller_information_month';
import { product_detail } from "../../../../objects/v1_3/product/product/product_detail";
import { filtertag } from "../../../../objects/v1_3/web_engine/filtertag";

const Prod = new Product;

export default defineComponent({
    name: "calendar",
    components: {
        calendar_month,
        calendar_details,
        tour_list,
        filter_list
    },
    emits: [
        "tourSelected", "onSearchChange"
    ],
    props: {
    },
    data() {
        return {
            selected_month: new product_detail_seller_information_month() as product_detail_seller_information_month,
            product_search: new product_search() as product_search,
            tours_selected: new product_search() as product_search,
            travel_date: "" as string,
            months: [] as product_detail_seller_information_month[],
            isTourListOpen: false as boolean,
            search_results: "" as string
        }
    },
    methods: {
        async onMonthChange(month: product_detail_seller_information_month) {
            this.selected_month = month;
            await this.$refs.calendar_details.onCalendarUpdate(this.$store.getters.get_Search_Filter_Tags, month);
        },
        Date_Selected(products_select: product_search, travel_date: string) {
            this.tours_selected = products_select;
            this.travel_date = travel_date;
            this.isTourListOpen = true;
        },
        Tour_Selected(travel_date: string, tour_id: string)
        {
            this.$emit("tourSelected", travel_date, tour_id);
        },
        async Download_Product_Search() {
            this.months = Prod.Get_Product_Search_Months(await Prod.Get_Product_Search());

            await this.Calendar_Update();
        },
        async Calendar_Update() {
            if (this.months?.length > 0) {
                this.onMonthChange(this.months[0]);
                await this.onFilterChanges();
            }
        },
        async onFilterChanges() {
            await this.$refs.calendar_details.onCalendarUpdate(this.$store.getters.get_Search_Filter_Tags, this.selected_month);
        },
        Clear_Filter() {
            this.$refs.filter_list.Clear_Filter();
        },
        Apply_Chanages() {
            this.$emit("onSearchChange", this.$refs.calendar_details.day_from, this.$refs.calendar_details.day_to);
        },
        search_result(): string {
            let Search_Result: string = "";

            this.$store.getters.get_Search_Filter_Tags.forEach((tag: any) => {
                Search_Result += tag.name + " ";
            });

            return Search_Result;
        }
    },
    mounted() {
        this.Download_Product_Search();
    }
})