﻿import { product_detail_operational_information_duration } from "./product_detail_operational_information_duration";
import { product_detail_operational_information_location } from "./product_detail_operational_information_location";

export class product_detail_operational_information {
    public duration: product_detail_operational_information_duration = new product_detail_operational_information_duration();
    public location_start: product_detail_operational_information_location = new product_detail_operational_information_location();
    public location_end: product_detail_operational_information_location = new product_detail_operational_information_location();
    public pickup_default: string | null = null;
    public booking_notes: string | null = null;
    public pickup_run_id: string | null = null;
}