﻿import { defineComponent } from 'vue';

import { response_login } from "../../booking_engine/objects/agent/identity/v1_3/response_login"
import { response_login_contact } from "../../booking_engine/objects/agent/identity/v1_3/response_login_contact"
import { request_login } from "../../booking_engine/objects/agent/identity/v1_3/request_login"

import { Agent_Login } from "../../booking_engine/services/agent_login";
import { Web_Engine } from "../../booking_engine/services/web_engine";

import { request_login_contact } from "../../booking_engine/objects/agent/identity/v1_3/request_login_contact";

let Login: Agent_Login = new Agent_Login();
let Web_Engines: Web_Engine = new Web_Engine();

export default defineComponent({
    components: {
    },
    props: {
    },
    data() {
        return {
            isOpen: false as boolean,
            active: false as boolean,
            selected_contact: new request_login_contact() as request_login_contact,
            new_contact: new request_login_contact() as request_login_contact,
            logging_in: false as boolean,
            contactSelected: false as boolean,
            newProfileOpen: false as boolean
        }
    },
    methods: {
        get_Agent_Info(): response_login {
            this.FirstContactInList();
            return this.$store.getters.get_Agent_Login;
        },
        async Click_Continue() {
            let Login_Request = this.$store.getters.get_Agent_Login_Request;
            Login_Request.contact_id = this.selected_contact.contact_id;
            Login_Request.new_contact = this.new_contact;

            this.logging_in = true;
            this.$store.commit("Set_Agent_Login", { Agent_Login: await Login.Login(Login_Request), Agent_Request_Login: Login_Request });
            await Web_Engines.Initialise_Currency(this.$store, '');

            this.logging_in = false;

            if (this.$store.getters.get_Agent_Login?.token?.access_token != null) {
                this.$router.push("/search");
            } else {
                window.alert("Please select or create a contact for your login");
            }
        },
        contact_selected(contact: response_login_contact) {
            this.selected_contact = contact;
            this.isOpen = false;
        },
        Get_Selected_Contact(): string {
            if (this.selected_contact.contact_id != "") {
                return this.selected_contact.first_name + " " + this.selected_contact.second_name + " - " + this.selected_contact.email;
            }

            return "Select Contact";
        },
        Consultant_Click_Away(event: any) {
            let Click_Away: boolean = true;

            if (event.path[1].id == "Select_Consultant_Click_Away") {
                Click_Away = false;
            }

            if (Click_Away == true) {
                this.isOpen = false;
            }
        },
        FirstContactInList(){
            if (this.$store.getters.get_Agent_Login.contacts[0] != null && this.contactSelected == false) {
                this.selected_contact = this.$store.getters.get_Agent_Login.contacts[0];
                this.contactSelected = true;
            }
        },
        upKey(){
            let index = this.$store.getters.get_Agent_Login.contacts.indexOf(this.selected_contact);
            if(index == 0){
                this.selected_contact = this.$store.getters.get_Agent_Login.contacts[this.$store.getters.get_Agent_Login.contacts.length - 1];
            }
            else{
                this.selected_contact = this.$store.getters.get_Agent_Login.contacts[index - 1];
            }
            
        },
        downKey(){
            let index = this.$store.getters.get_Agent_Login.contacts.indexOf(this.selected_contact);
            if(index == this.$store.getters.get_Agent_Login.contacts.length - 1){
                this.selected_contact = this.$store.getters.get_Agent_Login.contacts[0];
            }
            else{
                this.selected_contact = this.$store.getters.get_Agent_Login.contacts[index + 1];
            }
        },
        handleKeydown (e) {
            if(this.$store.getters.get_Agent_Login.contacts[0] != undefined){
                switch (e.keyCode) {
                case 13:
                    this.Click_Continue();
                    break;
                case 38:
                    if(this.$store.getters.get_Agent_Login_Request.contact_id == null)
                    {
                        e.preventDefault();
                        this.upKey();
                    }
                    break;
                case 40: 
                    if(this.$store.getters.get_Agent_Login_Request.contact_id == null)
                    {
                        e.preventDefault();
                        this.downKey();
                    }
                    break;
                }
          }
        },
        NewProfile(){
            if(this.newProfileOpen == false){
                this.newProfileOpen = true;
            }
            else{
                this.newProfileOpen = false;
            }
        },
        ButtonText(): string {
            if(this.logging_in == true)
            {
                return "Loading";
            }
            else
            {
                return "Continue";
            }
        }
    },
    mounted() {
    },
    beforeMount () {
        window.addEventListener('keydown', this.handleKeydown);
    },
    beforeDestroy () {
        window.removeEventListener('keydown', this.handleKeydown);
    }
});