﻿import { defineComponent } from 'vue';

import itinerary_breakdown from "../common/itinerary_breakdown.vue"
import trip_summary from "../common/trip_summary.vue"

import { Booking } from "../../../services/booking";
import { Date_Service } from "../../../../system/services/date_service";
import { Currency_Service } from "../../../../system/services/currency";

const Book: Booking = new Booking();
const Currency_Services: Currency_Service = new Currency_Service();
const Date_Services: Date_Service = new Date_Service();

export default defineComponent({
    name: "summary",
    components: {
        itinerary_breakdown,
        trip_summary
    },
    props: {
    },
    data() {
    },
    methods: {
        get_total_outstanding(): string {
            return Currency_Services.Format_Currency(Book.get_total_outstanding(this.$store.getters.get_Booking), this.$store.getters.get_Currency);
        },
        Balance_Outstanding(): boolean {
            return Book.get_total_outstanding(this.$store.getters.get_Booking) > 0;
        },
        Balance_Due_Date(): string {
            return Date_Services.full_date(Book.get_Expiry_Date(this.$store.getters.get_Booking));
        },
        get_Currency(): string {
            return this.$store.getters.get_Currency.currency_info.iso_currency_code;
        },
        get_Total_Price(): string {
            return Currency_Services.Format_Currency(Book.get_total_price(this.$store.getters.get_Booking, this.$store), this.$store.getters.get_Currency);
        }
    },
    mounted() {
    }
})