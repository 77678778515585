﻿<template>
    <div class="docs">
        <h6>Print Documents</h6>
        <p>Please check the booking details carefully and read through all the travel documents before going on your tour. </p>
        <div class="pdf flex flex-wrap" v-for="document in get_Booking().documents">
            <documentation_item :document="document"></documentation_item>
        </div>
    </div>
</template>

<script lang="ts" src="./documentation.ts"></script>