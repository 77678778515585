﻿<template>


    <div class="flex flex-col lg:flex-row mt-8 md:mt-12 ">
        <div class="left-col w-full lg:w-7/12 xl:w-7/12 lg:mr-4">
            <calendar id="calendar_div" :trip_index=trip_index :product=product :tour_ids="tour_ids" :selected_tour_id="selected_tour_id" @monthChange="onMonthChange" @dateChange="onDateChange" ref="calendar"></calendar>
        </div>
        <div class="right-col w-full lg:w-7/12 xl:w-6/12 lg:ml-4 pb-12 lg:pb-0 mt-8 md:mt-0">
            <button type="button" class="m-5 button button-pink-book " @click="StartBooking()">
                Start booking
            </button>               

        </div>


    </div>

</template>

<script lang="ts" src="./embedded_calendar.ts"></script>
