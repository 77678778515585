﻿import { defineComponent, PropType } from 'vue';

import { Capacity } from "../../../services/capacity";

import { Product } from "../../../services/product";
import { product_detail } from '../../../objects/v1_3/product/product/product_detail';
import { product_detail_seller_information_month } from '../../../objects/v1_3/product/product/product_detail_seller_information_month';

import { week_day } from "../../../objects/front_end/calendar/week_day";

import { capacity } from "../../../objects/v1_3/product/capacity/capacity";

import calendar_month from "./calendar/calendar_month.vue";
import calendar_details from "./calendar/calendar_details.vue";

const Prod = new Product();
var queryDate = "" as string;

export default defineComponent({
    name: "calendar",
    components: {
        calendar_details,
        calendar_month
    },
    emits: [
        "monthChange",
        "dateChange"
    ],
    props: {
        tour_ids: { type: Array as PropType<string[]>, default: [] },
        selected_tour_id: String,
        trip_index: Number
    },
    data() {
        return {
            selected_month: new product_detail_seller_information_month(),
            loading_calendar: true as boolean
        }
    },
    methods: {
        async onMonthChange(month: product_detail_seller_information_month) {
            this.selected_month = month;

            this.loading_calendar = true;
            await this.$refs.calendar_details.download_capacity(this.tour_ids, this.selected_tour_id, month.year, month.month);
            await this.onCalendarUpdate();
            this.loading_calendar = false;

            this.$emit("monthChange", month.year, month.month);
        },
        async onCalendarUpdate() {
            if (queryDate == "") {
                queryDate = this.GetDepartureDateFromQuery();
                if (queryDate != "") {
                   let queryDateParts = queryDate.split("-");
                   this.selected_month.year = parseInt(queryDateParts[0]);
                   this.selected_month.month = parseInt(queryDateParts[1]);
                   await this.$refs.calendar_details.download_capacity(this.tour_ids, this.selected_tour_id,this.selected_month.year, this.selected_month.month);
                }     
           }
           await this.$refs.calendar_details.onCalendarUpdate(this.selected_tour_id);
        },
        onDateChange(weekday : week_day) {
            this.$emit("dateChange" , weekday);
        },
        months() {
            if (this.tour_ids?.length == 0) {
                return [];
            }

            let Mounts_Output: product_detail_seller_information_month[] = [];
            this.tour_ids.forEach((tour_id: string) => {
                Prod.Get_Details_Sync(tour_id, this.$store)?.seller_information?.months?.forEach(month => {
                    if (Mounts_Output.findIndex(f => f.year == month.year && f.month == month.month) == -1) {
                        Mounts_Output.push(month);
                    }
                });
            });

            Mounts_Output = Mounts_Output.sort((obj1, obj2) => {
                if (obj1.year < obj2.year || (obj1.year == obj2.year && obj1.month < obj2.month)) {
                    return -1;
                }

                if (obj2.year > obj2.year || (obj1.year == obj2.year && obj1.month > obj2.month)) {
                    return 1;
                }

                return 0;
            });

            if (Mounts_Output.length > 0 && this.selected_month?.year == undefined) {
                //this.month_selected(Mounts_Output[0]);
            }

            return Mounts_Output;
        },
        GetDepartureDateFromQuery() : string {
            let url = window.location.search.substring(1).replace("%20"," ");
            let params = url.split("&");
            let depDate = "";

            if(params.length > 0)
            {
                params.forEach((param: string) => {
                    if(param.includes("departuredate"))
                    {
                        depDate = param.split("=")[1];
                    }  
                });
            }

            return depDate;
        }

    },
    mounted() {
    }
})