﻿<template>
    <div class="cart-addon" :hidden="get_Add_Ons()?.length == 0">
        <h6>Optional Add-ons for {{Trip_Name()}}</h6>
        <p>{{Add_On_Message()}}</p>

        <!--Desktop view-->
        <div class="addon-content flex flex-col" :class="{'hidden': isMobile()}">
            <div class="addon w-full bg-white " v-for="product in sort_Add_ons(get_Add_Ons())">
                <div :id="'add_on_' + product.tour_id" class="addon-content flex mb-0">
                    <div class="cover ">
                        <img class="addon-image h-full w-full object-cover " :src="image(product)" :alt="imageAltText(product)">
                    </div>
                    <div class="p-4 flex flex-col justify-between leading-normal">
                        <div class="add">
                            <h6>{{product.name}}</h6>
                            <p class="mt-2 mb-4 text-sm" v-html="product.description_short"></p>
                        </div> 
                        <div class="addon-selection">
                            <select_passengers class="" :isEcoScheme="isEcoScheme(product)" :price="price(product)" :tour_details="product" :passengers="get_Booking_Passengers()" :parent_tour_id="trip?.tour_id" :tour_id="product.tour_id"></select_passengers>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--mobile view-->
        <div class="addon-content flex flex-col" :class="{'hidden': !isMobile()}">
            <div class="addon w-full rounded-md bg-white " v-for="product in sort_Add_ons(get_Add_Ons())">
                <div :id="'add_on_' + product.tour_id">
                    <div class="cover-mobile">
                        <img class="addon-image h-full w-full object-cover " :src="image(product)" alt="Man looking at item at a store">
                    </div>  
                    <div class="p-4 flex flex-col justify-between leading-normal">
                        <div class="add">
                            <h6>{{product.name}}</h6>
                            <p class="mt-2 mb-0 text-sm" v-html="product.description_short"></p>
                        </div> 
                    </div>
                </div>
                <div  class="addon-selection">
                    <select_passengers class="" :price="price(product)" :tour_details="product" :passengers="get_Booking_Passengers()" :parent_tour_id="trip?.tour_id" :tour_id="product.tour_id"></select_passengers>
                </div>
            </div>
        </div>

    </div>
</template>

<script lang="ts" src="./add-ons.ts"></script>