﻿import { product_detail_seller_information_month } from "./product_detail_seller_information_month";
import { product_detail_seller_information_room } from "./product_detail_seller_information_room";
import { product_detail_seller_information_add_on } from "./product_detail_seller_information_add_on";
import { product_sales_group } from "./product_sales_group";
import { product_price } from "./product_price";


export class product_detail_seller_information {
    public months: product_detail_seller_information_month[] = [];
    public prices: product_price[] = [];
    public rooms: product_detail_seller_information_room[] = [];
    public sales_group: product_sales_group = new product_sales_group();
    public add_ons: product_detail_seller_information_add_on[] = [];
}