﻿<template>
        <!--TEMPORARY MESSAGE DURING PAYMENT FAILURES-->
    <div v-if="!isAgent_Login() && GetBrand() == 'HEX'" class="cart-addon" >
        <p class="m-auto font-bold text-black pt-2 text-center">If you don't receive a confirmation email please contact us at info@highlandexplorertours.com</p>
    </div>
    <div v-if="!isAgent_Login() && GetBrand() == 'HAG'" class="cart-addon" >
        <p class="m-auto font-bold text-black pt-2 text-center">If you don't receive a confirmation email please contact us at info@haggisadventures.com</p>
    </div>
    
    <div class="pax-details mt-12">
        <h6 class="ml-2">Add Passenger Information</h6>

        <form class="border border-grey-lightest rounded mt-8" @submit.prevent="Prevent_Submit()" v-for="(passenger, index) in get_Booking().passengers">

            <fieldset class="relative">
                <passenger :passenger=passenger :index=index></passenger>
            </fieldset>

        </form>

    </div>
</template>

<script lang="ts" src="./passengers.ts"></script>