export class product_detail_operational_information_location {
    public description: string = "";
    public city: string = "";
    public country: string = "";
    public longitude: number = 0;
    public latitude: number = 0;
    public time: string = "";
    public title: string = "";
    public url: string = "";
    public checkInTime: string = "";
    public departureComment: string = "";
}
