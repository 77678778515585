﻿import { defineComponent } from 'vue';

import passengers from './passenger.vue'

import { Booking } from '../../services/booking';
import { Web_Engine } from "../../services/web_engine"

import { age_category } from "../../objects/v1_3/web_engine/age_category";
import { age_cat } from "../../objects/v1_3/web_engine/age_cat"
import { passenger } from '../../objects/v1_2/Booking/Passenger';
import { trip } from '../../objects/v1_2/Booking/Trip';
import { trip_passenger } from '../../objects/v1_2/Booking/Trip_Passenger';
import { add_on } from '../../objects/v1_2/Booking/Add_On';
import { booking_details } from '../../objects/v1_2/Booking_Details/booking_details';
import { booking_info } from '../../objects/v1_2/Booking_Details/booking_info';
import { booking_group } from '../../objects/v1_2/Booking/Booking_Group';

const Book: Booking = new Booking();

export default defineComponent({
    components: {
        passengers
    },
    emits: [
        "calendar_update"
    ],
    props: {
        add_ons: Boolean,
        hidden_initially: Boolean,
        tour_id: String
    },
    data() {
        return {
            age_cat: new age_cat() as age_cat,
            isHidden: false
        }
    },
    methods: {
        get_Booking(): booking_group {
            return this.$store.getters.get_Booking;
        },
        get_Basket() {
            return this.$store.getters.get_Basket;
        },
        async download_passenger() {
            const web_engine = new Web_Engine();
            this.age_cat = await web_engine.Get_Passengers();
            this.age_cat.categories = this.age_cat.categories.sort((first, second): number => {
                return 0 - (first.name > second.name ? -1 : 1)
            });
        },
        Passenger_Details(): string {
            let Output: string = "Please select passenger(s)";
            var agecat = new Array<string>();

            if (this.Get_Passengers().length > 0) {
                Output = this.Get_Passengers().length + " passenger(s)";

                var distintagecat = this.age_cat.categories;
                if (distintagecat != null) {
                    Output = "";
                    for (var i = 0; i < distintagecat?.length; i++) {
                        var catname = distintagecat[i].name;
                        let catcount = this.Get_Passengers().filter((passenger: passenger) => passenger.age_category == catname).length;
                        
                        if (catcount > 0) {
                            let paxcatdisplay = catcount + " " + catname

                            if (catcount > 1) {
                                let catsuffix = "s";

                                if (catname == "Child") {
                                    catsuffix = "ren";
                                }

                                paxcatdisplay = paxcatdisplay + catsuffix;
                            }
                            
                            if (Output != "") {
                                Output = Output + ", ";
                            }
                            Output = Output + paxcatdisplay;
                        }
                    }
                }


            }

            return Output;
        }, 
        Get_Passengers(): passenger[] {
            let Pax_Output: passenger[] = this.$store.getters.get_Basket.passengers;
            let passengers: passenger[] = this.$store.getters.get_Basket.passengers;

            if (this.add_ons == true) {
                this.$store.getters.get_Basket.trips.forEach((Trip: trip) => {
                    Trip.add_ons.forEach((add_on: add_on) => {
                        if (add_on.tour_id == this.tour_id) {
                            Pax_Output = [];
                            add_on.passengers.forEach(function (passenger: trip_passenger) {
                                Pax_Output.push(passengers[passenger.index]);
                            });
                        }
                    });
                });
            }

            return Pax_Output;
        },
        Add_On_Passenger(tour_id: string, index: number): boolean {

            let Return_Value: boolean = false;

            this.$store.getters.get_Basket.trips.forEach(function (Trip: trip) {
                Trip.add_ons.forEach(function (add_on: add_on) {
                    if (add_on.tour_id == tour_id) {
                        Return_Value = (add_on.passengers.findIndex(f => f.index == index, 0) != -1);
                    }
                });
            });

            return Return_Value;
        },
        Toggle_Passenger(tour_id: string, index: number) {
            let trip_pax: trip_passenger = new trip_passenger();
            trip_pax.index = index;

            this.$store.getters.get_Basket.trips.forEach(function (Trip: trip) {
                Trip.add_ons.forEach(function (add_on: add_on) {
                    if (add_on.tour_id == tour_id) {
                        let index_location: number = add_on.passengers.findIndex(f => f.index == trip_pax.index, 0);
                        if (index_location == -1) {
                            add_on.passengers.push(trip_pax);
                        } else {
                            add_on.passengers.splice(index_location, 1);
                        }
                    }
                });
            });
        },
        onPassengerUpdate() {
            this.$emit("calendar_update");
        },
        async Clear_Passengers() {
            this.$store.commit("Initialise_Passenger");
            this.onPassengerUpdate();

            await Book.Setup_Booking(this.$store);
        },
        Collapse_Panel() {
            this.isHidden = false;

            if (this.Get_Passengers().length > 0) {
                this.isHidden = true;
            }
        },
        onClickAway(event: any) {
            let Click_Away: boolean = true;

/*            if (event.path[1].id == "Passenger_Click_Away") {
                Click_Away = false;
            }*/

            if (Click_Away == true) {
                this.Collapse_Panel();
            }
        },
        CategorySort(categories: age_category[]): age_category[] {

            let sorted_categories: age_category[] = [];
            let child_category: age_category = new age_category();

            categories.forEach(catagory => {
                if(catagory.name.toLowerCase() == "child") {
                    child_category = catagory;
                }
                else{
                    sorted_categories.push(catagory);
                }
                
            });

            if(child_category.name != "") {
                sorted_categories.push(child_category);
            }

            return sorted_categories;
        

        }
    },
    mounted() {
        this.isHidden = this.hidden_initially;
        this.download_passenger();
    }
})