﻿<template>
    <div class="vc-header">
        <div class="flex flex-wrap">
            <div class="flex-none justify-start items-center" :class="{'hidden': isMobile()}">
                <button v-if="First_Month()" type="button" class="button naked border-none vc-arrow vc-arrow-disabled">
                    <svg fill="white" width="26px" height="26px" viewBox="0 -1 16 34"><path data-v-19b6cf78="" d="M11.196 10c0 0.143-0.071 0.304-0.179 0.411l-7.018 7.018 7.018 7.018c0.107 0.107 0.179 0.268 0.179 0.411s-0.071 0.304-0.179 0.411l-0.893 0.893c-0.107 0.107-0.268 0.179-0.411 0.179s-0.304-0.071-0.411-0.179l-8.321-8.321c-0.107-0.107-0.179-0.268-0.179-0.411s0.071-0.304 0.179-0.411l8.321-8.321c0.107-0.107 0.268-0.179 0.411-0.179s0.304 0.071 0.411 0.179l0.893 0.893c0.107 0.107 0.179 0.25 0.179 0.411z"></path></svg>
                </button>
                <button v-else type="button" class="button naked button-bluelight vc-arrow arrow-left" @click="previous_Month()">
                    <svg fill="black" width="26px" height="26px" viewBox="0 -1 16 34"><path data-v-19b6cf78="" d="M11.196 10c0 0.143-0.071 0.304-0.179 0.411l-7.018 7.018 7.018 7.018c0.107 0.107 0.179 0.268 0.179 0.411s-0.071 0.304-0.179 0.411l-0.893 0.893c-0.107 0.107-0.268 0.179-0.411 0.179s-0.304-0.071-0.411-0.179l-8.321-8.321c-0.107-0.107-0.179-0.268-0.179-0.411s0.071-0.304 0.179-0.411l8.321-8.321c0.107-0.107 0.268-0.179 0.411-0.179s0.304 0.071 0.411 0.179l0.893 0.893c0.107 0.107 0.179 0.25 0.179 0.411z"></path></svg>
                </button>
            </div>
            <div class="flex-1 vc-title flex justify-center ">
                <button type="button" class="select-button " @click="isOpen = !isOpen" :class="{'active': isOpen}">
                    <strong>{{format_month(selected_month)}}</strong>
                </button>
                <div class="select-dropdown w-80 mx-14" v-if="isOpen">
                    <ul>
                        <li v-for="month in months" :key="key(month)"
                            :class="[ key(month) == key(selected_month) ? 'selected' : '' ]"
                            @click="month_selected(month)">
                            {{format_month(month)}}
                        </li>
                    </ul>
                </div>
            </div>
            <div class="flex-none justify-end items-center" :class="{'hidden': isMobile()}">
                <button v-if="Last_Month()" type="button" class="button border-none naked vc-arrow vc-arrow-disabled">
                    <svg fill="white" width="26px" height="26px" viewBox="-5 -1 16 34"><path data-v-19b6cf78="" d="M10.625 17.429c0 0.143-0.071 0.304-0.179 0.411l-8.321 8.321c-0.107 0.107-0.268 0.179-0.411 0.179s-0.304-0.071-0.411-0.179l-0.893-0.893c-0.107-0.107-0.179-0.25-0.179-0.411 0-0.143 0.071-0.304 0.179-0.411l7.018-7.018-7.018-7.018c-0.107-0.107-0.179-0.268-0.179-0.411s0.071-0.304 0.179-0.411l0.893-0.893c0.107-0.107 0.268-0.179 0.411-0.179s0.304 0.071 0.411 0.179l8.321 8.321c0.107 0.107 0.179 0.268 0.179 0.411z"></path></svg>
                </button>
                <button v-else type="button" class="button naked button-bluelight vc-arrow arrow-right" @click="next_Month()">
                    <svg fill="black" width="26px" height="26px" viewBox="-5 -1 16 34"><path data-v-19b6cf78="" d="M10.625 17.429c0 0.143-0.071 0.304-0.179 0.411l-8.321 8.321c-0.107 0.107-0.268 0.179-0.411 0.179s-0.304-0.071-0.411-0.179l-0.893-0.893c-0.107-0.107-0.179-0.25-0.179-0.411 0-0.143 0.071-0.304 0.179-0.411l7.018-7.018-7.018-7.018c-0.107-0.107-0.179-0.268-0.179-0.411s0.071-0.304 0.179-0.411l0.893-0.893c0.107-0.107 0.268-0.179 0.411-0.179s0.304 0.071 0.411 0.179l8.321 8.321c0.107 0.107 0.179 0.268 0.179 0.411z"></path></svg>
                </button>
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="./calendar_month.ts"></script>