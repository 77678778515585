﻿import { stringifyQuery } from 'vue-router';

const Months: string[] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export class Dates {
    public Month_Text(month: number): string {
        if (month == null) {
            return "";
        }

        let Output: string = String(month)

        if (month >= 1 && month <= 12) {
            Output = Months[month - 1];
        }
        
        return Output;
    }
}