﻿<template>

    <h4>Account Information</h4>

    <div class="form-contents">
        <div class="grid grid-cols-2">
            <div>Name</div>
            <div>{{account_information.name}}</div>
            <div>Address</div>
            <div><input type="text" name="address_1" id="address_1" spellcheck="false" v-model="account_information.address_1" /></div>
            <div></div>
            <div><input type="text" name="address_2" id="address_2" spellcheck="false" v-model="account_information.address_2" /></div>
            <div>Post Code</div>
            <div><input type="text" name="post_code" id="post_code" spellcheck="false" v-model="account_information.post_code" /></div>
            <div>Phone Number</div>
            <div><input type="text" name="telephone" id="telephone" spellcheck="false" v-model="account_information.telephone" /></div>
            <div>Email</div>
            <div><input type="text" name="email" id="email" spellcheck="false" v-model="account_information.email" /></div>
            <div></div>
            <div>.</div>
            <div>Payment Method</div>
            <div>{{Payment_Methods()}}</div>
            <div>Agent Code</div>
            <div>{{account_information.code}}</div>
        </div>
        <br />
        <div class="grid">
            <div><div class="button outline button-default w-full sm:w-3/4 rounded-full" @click="Email_Agency()">Email sales@aatkings.com.au</div></div>
            <div>Has something changed? Contact us to update your details</div>
        </div>
    </div>
</template>
<script lang="ts" src="./account_information.ts"></script>
