﻿<template>
    <div class="pick-up mt-8 flex border border-aat-greylight rounded-sm" :class="{ hidden: !display_pickup_points() }">

        <div class="text-heading flex-0 pt-4 pb-3 px-8">
            <strong class="text-black">Select your hotel pick-up:</strong>
        </div>

        <div id="Pickup_Point_Click_Away" class="flex-1 vc-title" v-click-away="onClickAway">
            <button type="button" class="select-button" @click="isOpen = !isOpen" :class="{'active': isOpen}">
                <strong>{{selected_pickup_point.name}}</strong>
            </button>
            <div class="select-dropdown w-80" v-if="isOpen">
                <ul>
                    <li v-for="pickup_point in pickup_points?.points"
                        class="text-sm"
                        :class="[ pickup_point.point_id === selected_pickup_point.point_id ? 'selected' : '' ]"
                        @click="Pickup_Click(pickup_point)">
                        {{pickup_point.name}}
                    </li>
                </ul>
            </div>
        </div>

    </div>
</template>

<script lang="ts" src="./pickup.ts"></script>