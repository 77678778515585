﻿import { defineComponent, PropType } from 'vue';

import { passenger } from "../../../../objects/v1_2/Booking/Passenger";
import { trip_passenger } from "../../../../objects/v1_2/Booking/Trip_Passenger";
import { product_price } from "../product/product_price";

import { Booking } from "../../../../services/booking";
import { Product } from "../../../../services/product";
import { Currency_Service } from "../../../../../system/services/currency";

import passengers from '../../4_checkout/passengers';
import { product_search_product } from '../../../../objects/v1_3/product/search/product_search_product';

const Book = new Booking();
const Prod = new Product();
const Currency_Services = new Currency_Service();
class GooglePassengerDetail {
    public age_cat: string = "";
    public count: number = 0;
    public price: number = 0;

}

export default defineComponent({
    name: "select_passengers",
    props: {
        price: String,
        passengers: { type: Array as PropType<passenger[]>, default: [] },
        parent_tour_id: String,
        tour_id: String,
        tour_details: {type: Object as PropType<product_search_product>, default: new product_search_product()},
        isEcoScheme: Boolean
    },
    data() {
        return {
            isHidden: true,
            selected_passengers: [] as trip_passenger[],
            addingAddon: false
        }
    },
    methods: {
        Setup_Controls() {
            for (var index = 0; index < this.passengers.length; index++) {
                let trip_pax: trip_passenger = new trip_passenger();
                trip_pax.index = index;

                this.selected_passengers.push(trip_pax);
            }
        },
        async add_AddOn() {
            this.addingAddon = true;
            this.$store.commit("Add_Add_On", { Parent_Tour_ID: this.parent_tour_id, Tour_ID: this.tour_id, Passengers: this.selected_passengers });
            this.isHidden = true

            let Book = new Booking();
            this.$store.commit("Setup_Booking", { Booking_Details: await Book.Quote_Booking(this.$store) }); 
            this.addingAddon = false;
            this.$router.push({ path: "/cart", hash: "#basket_" + this.tour_id });
        },
        Passenger_Selected(index: number): boolean {
            return (this.selected_passengers.findIndex((f: trip_passenger) => f.index == index, 0) != -1);
        },
        Get_Price(Passenger: passenger): string {
            return Currency_Services.Format_Currency(Prod.product_pricing(Passenger.age_category ?? Book.Pricing_Age_Category(this.$store), this.$store.getters.get_Currency, this.tour_details ?? new product_search_product()), this.$store.getters.get_Currency);
        },
        Toggle_Passenger(index: number) {
            let trip_pax: trip_passenger = new trip_passenger();
            trip_pax.index = index;

            let index_location: number = this.selected_passengers.findIndex((f: trip_passenger) => f.index == trip_pax.index, 0);
            if (index_location == -1) {
                this.selected_passengers.push(trip_pax);
            } else {
                this.selected_passengers.splice(index_location, 1);
            }
        },
        Control_Name(index: number): string {
            return "passenger_" + index;
        },
        isMobile(): boolean{
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
        checkTickBox(passengerSelected: boolean): boolean {
            if(this.isEcoScheme){
                return false;
            }

            return passengerSelected;
        }
    },
    mounted() {
        this.Setup_Controls();
    }
})