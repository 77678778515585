﻿import { product_detail } from "../objects/v1_3/product/product/product_detail";

import { filtertag } from "../objects/v1_3/web_engine/filtertag";


export class Product {
    public Add_Product(Products: product_detail[], Product: product_detail): product_detail[] {
        Products.push(Product);

        return Products;
    }
    public Toggle_Tag(Tag: filtertag, Tags: filtertag[]): filtertag[]{
        let index_location: number = Tags.findIndex(f => f.tag_id == Tag.tag_id, 0);
        if (index_location == -1) {
            Tags.push(Tag);
        } else {
            Tags.splice(index_location, 1);
        }

        return Tags;
    }
}