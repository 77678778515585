﻿import { defineComponent, PropType } from 'vue';

import { product_detail_seller_information_month } from '../../../../objects/v1_3/product/product/product_detail_seller_information_month';

import { Dates } from "../../../../filters/dates";

const dates = new Dates();

export default defineComponent({
    name: "calendar_month",
    emits: [
        "monthChange"
    ],
    props: {
        selected_month: Object as PropType<product_detail_seller_information_month>,
        months: { type: Object as PropType<product_detail_seller_information_month[]>, default: [] }
    },
    data() {
        return {
            isOpen: false
        }
    },
    methods: {
        First_Month(): boolean {
            return this.month_index() == 0;
        },
        Last_Month(): boolean {
            return this.month_index() == (this.months.length - 1);
        },
        month_selected(month: product_detail_seller_information_month) {
            this.isOpen = false;

            this.$emit("monthChange", month);
        },
        format_month(month: product_detail_seller_information_month): string {
            return dates.Month_Text(month?.month) + " " + String(month?.year);
        },
        key(month: product_detail_seller_information_month): string {
            return month.year + "-" + month.month;
        },
        previous_Month() {
            let Month_Index: number = this.month_index();
            Month_Index--;

            this.month_selected(this.months[Month_Index]);
        },
        next_Month() {
            let Month_Index: number = this.month_index();
            Month_Index++;

            this.month_selected(this.months[Month_Index]);
        },
        month_index(): number {
            let Index: number = 0;
            let Selected_Index: number = -1;
            let Month_Selected = this.selected_month;

            this.months.forEach(function (month: product_detail_seller_information_month) {
                if (Month_Selected?.year == month.year && Month_Selected?.month == month.month) {
                    Selected_Index = Index;
                    return;
                }
                Index++;
            });

            return Selected_Index;
        },
        isMobile(): boolean{
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    }
})