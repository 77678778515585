﻿<template>
    <button type="button" class="text-dropdown text-sm lg:text-base" @click="isOpen_Item_List = !isOpen_Item_List" :hidden="!add_on" :active="isOpen_Item_List">
        <span class="icon small inline">
            <svg class="inline small w-4 -mt-1 mr-2 svg-inline--fa fa-puzzle-piece fa-w-18 fa-2x" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="puzzle-piece" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M506.584 256c-52.307 0-72.012 46.513-87.263 27.506-20.125-25.082-2.028-107.233 3.475-131.942-34.229 6.371-137.243 24.274-163.836 2.178-16.619-13.81 31.313-43.496 31.313-86.443C290.272 26.025 256.447 0 214.842 0c-43.559 0-84.792 25.609-84.792 68.824 0 53.02 45.898 71.605 24.351 88.606C125.985 179.846 35.346 160.524 0 152.041v345.313c33.315 8.012 70.681 14.649 106.163 14.646 42.28 0 85.837-11.839 85.837-54.125 0-29.344-32-40.832-32-73.875 0-24.437 22.534-32 46.978-32C245.675 352 256 372.114 256 384c0 28.783-34.272 36.348-34.272 76.58 0 13.748 5.013 25.445 14.498 33.828 35.153 31.069 106.717 6.319 187.085 6.285-.958-3.426-26.807-86.724-7.702-111.907 16.715-22.023 48.578 29.106 92.52 29.106C550.227 417.893 576 377.616 576 336c0-42.835-26.227-80-69.416-80zm1.544 129.893c-30.002 0-41.364-33.893-81.513-33.893-53.566 0-54.841 64.979-44.272 117.816-36.396 3.424-107.025 16.434-124.926.614C237.293 452.645 288 428.279 288 384c0-37.683-33.317-64-81.022-64-74.981 0-102.885 59.829-56.167 122.037 4.726 6.293 9.189 12.237 9.189 15.838 0 33.69-94.005 20.629-128 13.925V191.971c63.255 11.657 160 18.136 160-46.505 0-28.567-29.95-42.982-29.95-76.642C162.05 44.146 190.265 32 214.842 32c20.035 0 43.43 9.244 43.43 35.298 0 29.426-34.272 40.752-34.272 80.61 0 57.828 100.845 50.931 158.22 43.093C374.142 245.294 373.959 320 429.086 320c29.143 0 43.674-32 77.498-32C531.543 288 544 311.301 544 336c0 34.413-20.977 49.893-35.872 49.893z" class=""></path></svg>
        </span>
        {{getTrip_Items()}}
    </button>
    <div class="select-dropdown" :hidden="!isOpen_Item_List">
        <ul>
            <p class="my-2 mx-8 text-heading font-semibold">{{getTrip_Heading()}}</p>
            <li  v-for="(passenger, index) in this.$store.getters.get_Booking.passengers">
                <div class="flex items-center" @click="Toggle_Passenger(index)">
                    <input type="checkbox" :checked="Passenger_Selected(passenger)" class="focus:ring-action -mt-1 h-4 w-4 text-action border-grey-light rounded" />
                    <label class="ml-3 block text-sm cursor-pointer">{{getTrip_Pricing(passenger)}}</label>
                </div>
            </li>
        </ul>
    </div>
</template>

<script lang="ts" src="./basket_item.ts"></script>