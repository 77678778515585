export class client_booking {
  public trip_passenger_identifier: string = "";
  public passenger_identifier: string = "";
  public price: number = 0;
  public discount: number = 0;
  public discount_percentage: number = 0;
  public discount_promo_code: number = 0;
  public discount_description: string = "";
  public commission: number = 0;
  public paid: number = 0;
  public outstanding: number = 0;
  public expiry_time: string = "";
  public card_fee: number = 0;
  public debit_card_fee: number = 0;
  public deposit: number = 0;
  public deposit_card_fee: number = 0;
  public deposit_debit_card_fee: number = 0;
  public paid_card_fee: number = 0;
  public on_request: boolean = false;
  public load: boolean = false;
  public add_on_parent_client_booking_guid: string = "";
}
