export class client {
  public passenger_identifier: string= "";
  public first_name: string = "";
  public family_name: string = "";
  public age_category: string = "";
  public gender: string = "";
  public date_of_birth: string = "";
  public email: string= "";
  public iso_nationality: string = "";
  public iso_nationality_sate: string = "";
  public telephone_country_code: string = "";
  public telephone_number: string = "";
  public emergency_contact: string = "";
  public emergency_country_code: string = "";
    public emergency_number: string = "";
    public post_code: string | null = null;
    public isValid: boolean = false;
}
