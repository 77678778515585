﻿<template>

    <div class="bookings-stages">
        <ul>
            <li :class="{ 'current' : Item_Selected(1)}">CART SUMMARY</li>
            <li :class="{ 'current' : Item_Selected(3)}">GUEST DETAILS</li>
            <li :class="{ 'current' : Item_Selected(4)}">PAYMENT</li>
        </ul>
    </div>

</template>

<script lang="ts" src="./header.ts"></script>