﻿import { defineComponent } from 'vue';

import header_menu from "../../booking_engine/components/header/menu.vue"
import booking_line from "./booking_line.vue"
import footer_item from "../../booking_engine/components/base/common/footer.vue"

import { Booking } from "../../booking_engine/services/booking";

let Book: Booking = new Booking();

import { booking_search_response } from "../../../tms/booking_engine/objects/v1_3/booking/search/response";
import { response_login_agency } from "../../booking_engine/objects/agent/identity/v1_3/response_login_agency";

export default defineComponent({
    components: {
        header_menu,
        booking_line,
        footer_item
    },
    props: {
    },
    data() {
        return {
            account_information: new response_login_agency() as response_login_agency,
            Booking_Search: new booking_search_response(),
            loading_search: true
        }
    },
    methods: {
        Load_Agency_Information() {
            this.account_information = new response_login_agency();
            this.account_information.code = this.$store.getters.get_Agent_Login?.agency?.code;
            this.account_information.name = this.$store.getters.get_Agent_Login?.agency?.name;
            this.account_information.address_1 = this.$store.getters.get_Agent_Login?.agency?.address_1;
            this.account_information.address_2 = this.$store.getters.get_Agent_Login?.agency?.address_2;
            this.account_information.post_code = this.$store.getters.get_Agent_Login?.agency?.post_code;
            this.account_information.email = this.$store.getters.get_Agent_Login?.agency?.email;
            this.account_information.telephone = this.$store.getters.get_Agent_Login?.agency?.telephone;
        },
        async download_bookings() {

            this.loading_search = true;
            this.Booking_Search = await Book.Booking_Search(this.$store);
            this.loading_search = false;
        }
    },
    mounted() {
        this.Load_Agency_Information();
        this.download_bookings();
    }
});