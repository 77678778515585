﻿<template>

    <div class="vc-weeks">
        <div class="grid">
            <div class="grid grid-cols-7 vc-weekday-heading">
                <div class="flex justify-center items-center vc-weekday">MON</div>
                <div class="flex justify-center items-center vc-weekday">TUE</div>
                <div class="flex justify-center items-center vc-weekday">WED</div>
                <div class="flex justify-center items-center vc-weekday">THU</div>
                <div class="flex justify-center items-center vc-weekday">FRI</div>
                <div class="flex justify-center items-center vc-weekday">SAT</div>
                <div class="flex justify-center items-center vc-weekday">SUN</div>
            </div>
            <div class="grid grid-cols-7 vc-weekday-row" v-for="week in capacity">
                <div class="flex justify-center items-center vc-day" v-for="day in week.days">
                    <calendar_day :trip_index=trip_index :day=day :selected_tour_id="selected_tour_id" @dateChange="onDateChange"></calendar_day>
                </div>
            </div>
        </div>
    </div>

</template>

<script lang="ts" src="./calendar_details.ts"></script>