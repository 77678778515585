﻿import { defineComponent, PropType } from 'vue';

import { week_day } from "../../../../objects/front_end/calendar/week_day";

export default defineComponent({
    name: "calendar_day",
    components: {
    },
    emits: [
        "dateSelected"
    ],
    props: {
        day: Object as PropType<week_day>,
        selected_day: Boolean
    },
    data() {
        return {
        }
    },
    methods: {
        getDay(): string {
            if (this.day == undefined) {
                return "";
            }

            if (this.day?.tour_id.length == 0) {
                return "";
            }

            return String(this.day.number);
        },
        isDate_Selected(): boolean {
            return this.selected_day ?? false;
        },
        date_selected() {
            this.$emit("dateSelected", this.day);
        },
        get_Tours(): string {
            if (this.day?.tour_id.length == 0) {
                return "";
            }

            if (this.day?.tour_id.length == 1) {
                return "1 Tour";
            }

            return this.day?.tour_id.length + " Tours";
        }
    }
})