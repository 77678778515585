﻿import { defineComponent } from 'vue';

import header_menu from "../booking_engine/components/header/menu.vue";
import forgot_password_screen from "./forgot_password/forgot_password_screen.vue";
import register_screen from "./register/register_screen.vue";
import agent_login from "./login/agent_login.vue"
import select_consultant from "./login/select_consultant.vue"

export default defineComponent({
    components: {
        header_menu,
        forgot_password_screen,
        register_screen,
        agent_login,
        select_consultant
    },
    props: {
        screen: String
    },
    data() {
    },
    methods: {
        Hide_Login(): boolean {
            if (this.screen != "login") {
                return true;
            }

            return this.$store.getters.get_Agent_Login.valid_login ?? false;
        },
        Hide_Select_Consultant(): boolean {
            if (this.screen != "login") {
                return true;
            }

            return !this.$store.getters.get_Agent_Login.valid_login ?? true;
        },
        GetHeroImage(): string {
            return String(import.meta.env.VITE_DEFAULT_HERO_IMAGE);
        },
        isAAT(): boolean {
            if (import.meta.env.VITE_DEFAULT_PARENT == "AAT") {
                return true;
            }
            return false;
        },
        getLoginTitle(): string {
            return String(import.meta.env.VITE_DEFAULT_LOGIN_TITLE);
        }
    },
    mounted() {
    }
});