﻿<template>

    <header class="header">
        <header_menu></header_menu>
        <div class="border-2 p-8 m-4 rounded border-black">
            <header_top></header_top>
            <div class="grid grid-cols-2">
                <div>
                    <passengers></passengers>
                    <booking_notes></booking_notes>
                </div>
                <div><booking_details></booking_details></div>
            </div>
            <booking_documentation></booking_documentation>
        </div>
    </header>

</template>

<script lang="ts" src="./details.ts"></script>