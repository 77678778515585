﻿import { defineComponent } from 'vue';

import { response_booking } from "../../../tms/booking_engine/objects/v1_3/booking/search/response_booking";

import { Booking } from "../../booking_engine/services/booking";
import { Date_Service } from "../../system/services/date_service";

let Book: Booking = new Booking();
let Date_Services: Date_Service = new Date_Service();

export default defineComponent({
    components: {
    },
    props: {
        booking: response_booking,
        index: Number
    },
    data() {
        return {
            search_booking: false as boolean
        }
    },
    methods: {
        Lead_Passenger(): string {
            return this.booking?.passengers[0].first_name + " " + (this.booking?.passengers[0].family_name ?? "");
        },
        Departure_Date(): string {
            return Date_Services.display_date(this.booking?.trips[0].departure_date ?? "");
        },
        Booking_Date(): string {
            return Date_Services.display_date(this.booking?.trips[0].booking_date ?? "");
        },
        Get_Consultant(): string {
            return this.booking?.trips[0].affiliate_contact ?? "";
        },
        Booking_Status(): string {
            return this.booking?.trips[0].passengers[0].status ?? "";
        },
        async Booking_Details(group_booking_id: string) {
            this.search_booking = true;
            this.$store.commit("Setup_Booking", { Booking_Details: await Book.Get_Book(group_booking_id, this.$store.getters.get_Agent_Login) });
            this.$store.commit("Initialise_Booking_Cancellation");
            this.$router.push("/search/details");
        },
        Even_Row(): boolean {
            return this.index === 0 || !!(this.index && !(this.index % 2));
        }
    },
    mounted() {
    }
});