﻿import { defineComponent, PropType, toDisplayString } from 'vue';
import itinerary from "../../base/common/itinerary.vue";
import VLazyImage from "v-lazy-image";

import { product_search_product } from '../../../objects/v1_3/product/search/product_search_product';

import { Booking } from "../../../services/booking";
import { Product } from "../../../services/product";
import { Currency_Service } from "../../../../system/services/currency";

const Book = new Booking();
const Prod = new Product();
const Currency_Services = new Currency_Service();

export default defineComponent({
    name: "tour_card",
    emits: [
        "bookTour"
    ],
    components: {
        itinerary,
        VLazyImage
    },
    props: {
        "tour_details": {type: Object as PropType<product_search_product>, default: new product_search_product()},
        OpenBooking: Boolean,
        InCart: Boolean

    },
    data() {
        return {
            isHidden: true as boolean,
            tour_id: "" as string,
            loadingOpenTour: false as boolean
        }
    },
    methods: {
        tour_see_details(tour_id: string) {

            this.tour_id = tour_id;
            this.isHidden = false;
            this.$refs.itinerary?.download_product(tour_id);
        },
        tour_selected() {
            if(Prod.product_pricing(Book.Pricing_Age_Category(this.$store), this.$store.getters.get_Currency, this.tour_details ?? new product_search_product()) > 0){
                this.$emit("bookTour", this.tour_details?.tour_id);
            }
        },
        async selected_from_URL() {
            if(this.OpenBooking){
                this.loadingOpenTour = true;
                if(this.bookable()){
                    this.loadingOpenTour = false;
                    this.$emit("bookTour", this.tour_details?.tour_id);
                }
                else
                {
                    await this.delay(100);
                    this.selected_from_URL();
                }
            };
        },
        duration(): string {
            return Prod.product_search_duration(this.tour_details ?? new product_search_product());
        },
        durationInDays(): string {
            let days = this.tour_details?.duration?.days ?? 0;
            if(this.tour_details?.duration?.hours ?? 0 >= 1){
                days++;
            }

            if(days == 1){
                return days.toString() + " day";
            }
            else
            {
                return days.toString() + " days";
            }
        },
        currency(): string {  
            return this.$store.getters.get_Currency.currency_info.iso_currency_code;
        },
        price(): string {
            return "From " + Currency_Services.Format_Currency(Prod.product_pricing(Book.Pricing_Age_Category(this.$store), this.$store.getters.get_Currency, this.tour_details ?? new product_search_product()), this.$store.getters.get_Currency);
        },
        image(): string {
            if (this.tour_details?.media_asset != null) {
                return this.tour_details?.media_asset?.asset_url + "?anchor=center&mode=crop&width=900";
            }

            return String(import.meta.env.VITE_DEFAULT_HERO_IMAGE);
        },
        imageAltText(): string 
        {
            return this.tour_details?.media_asset?.name ?? "Image of " + this.tour_details?.name;
        },
        imageSmall(): string {
            if (this.tour_details?.media_asset != null) {
                return this.tour_details?.media_asset?.asset_url + "?anchor=center&mode=crop&width=300&height=200";
            }

            return String(import.meta.env.VITE_DEFAULT_HERO_IMAGE);
        },
        display_description(): string {

            if (this?.tour_details?.description_headline != null) {
                return this.tour_details.description_headline;
            }

            let Output_Text: string = this?.tour_details?.description ?? "";
            if(this?.tour_details?.description_short != null){
                if(!this?.tour_details?.description_short.toLowerCase().includes("room =")){
                    Output_Text = this?.tour_details?.description_short + Output_Text;  
                }
            }


            if (Output_Text != null) {
                if (Output_Text.length > 61) {
                    return Output_Text.slice(0, 60).replace(/<\/?[^>]+(>|$)/g, "");
                } else {
                    return Output_Text.replace(/<\/?[^>]+(>|$)/g, "");
                }
            }
            return "A beautiful tour of Scotland.";

        },
        Format_Description(description: string): string {
            let formatted_description: string = description;

            let tourdetailslength: number = -1; 
            if (this.tour_details != undefined) {
                tourdetailslength = this.tour_details.name.length;
            }

            if(tourdetailslength >= 34){
                if (description.length > 100)
                {
                    formatted_description = formatted_description.slice(0, 97) + "...";
                }
            }
            else
            {
                if (description.length > 140)
                {
                    formatted_description = formatted_description.slice(0, 137) + "...";
                }

            }
            return formatted_description;

        },
        isAgent_Login(): boolean {
            return this.$store.getters.get_Agent_Login?.valid_login == true;
        },
        localUrl(): boolean {
            if (window.location.href.includes("localhost")) {
                return true;
            }
            return false;
        },
        isAAT(): boolean {
            if (import.meta.env.VITE_DEFAULT_PARENT == "AAT") {
                return true;
            }
            return false;
        },
        Mapping_Codes(): string {
            let Output_Text: string = "";

            if (import.meta.env.VITE_DEFAULT_DISPLAY_TOUR_SEARCH == "true") {
                this.tour_details?.mapping_codes?.forEach((Code: string) => {
                    if (Output_Text != "") {
                        Output_Text += ", ";
                    }

                    Output_Text += Code;
                });
            }

            return Output_Text;
        },
        getBrand(): string {
            let Output_Brand: string = "";
            
            if(this.tour_details?.web_url.toLocaleLowerCase().includes("haggis")){
                Output_Brand = "Haggis Adventures";
            }
            else if(this.tour_details?.web_url.toLocaleLowerCase().includes("highland")){
                Output_Brand = "Highland Explorer";
            }

            return Output_Brand;


        },
        bookable(): boolean {
            if(Prod.product_pricing(Book.Pricing_Age_Category(this.$store), this.$store.getters.get_Currency, this.tour_details ?? new product_search_product()) > 0){
                return true;
            }
            else
            {
                return false;
            }

        },
        delay(ms: number) {
            return new Promise( resolve => setTimeout(resolve, ms) );
        }
    },

    mounted() {
        this.selected_from_URL();
    }
})