﻿import { defineComponent } from 'vue';

import { client } from "../../../booking_engine/objects/v1_2/Booking_Details/client";

export default defineComponent({
    components: {
    },
    props: {
        pax: client,
        index: Number
    },
    methods: {
        Lead_Passenger(): boolean {
            return this.index == 0;
        }
    },
    mounted() {
    }
});