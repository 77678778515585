﻿import { capacity_capacity } from "../../v1_3/product/capacity/capacity_capacity";

export class week_day {
    public name: string | null = null;
    public tour_id: string[] = [];
    public number: number | null = null;
    public is_current_month: boolean = false;
    public is_today: boolean = false;
    public date: string | null = null;
    public output_date: string | null = null;
    public isOnRequest: boolean = false;
    public hasDeparture: boolean = false;
    public totalCapacity: number = -1;
    public fromPrice: number = -1;
    public toPrice: number = -1;
    public capacity_info: capacity_capacity = new capacity_capacity();
}
