﻿<template>

    <div class="options mt-8" :hidden="!Display_Tab_Selection()" >

        <div class="pick-up mt-8 flex border border-aat-greylight rounded-sm" v-if="get_Sales_Group().length > 1" >
            <div class="text-heading flex-0 pt-4 pb-3 px-8">
                <strong class="text-black">Options:</strong>
            </div>

            <div id="Sales_Group_Click_Away" v-click-away="onClickAway">
                <button class="select-button pr-14" @click="Option_Is_Open = true" :active="Option_Is_Open" >
                    {{accomodation_formating(product_selected.name_short, product_selected.name)}} <span class="price-from">{{get_price(product_selected)}}</span>
                </button>
                <div class="select-dropdown" v-if="Option_Is_Open">
                    <ul>
                        <li v-for="product in get_Sales_Group()" :key="product.tour_id"
                            class="text-sm" 
                            :selected="product.tour_id === product_selected.tour_id"
                            @click="product_selection(product.tour_id)">
                            {{accomodation_formating(product.name_short, product.name)}} <span class="price-from">{{get_price(product)}}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="group">
            <div class="tabs mt-4" v-if="dateSelected()">

                <div class="option-tabs flex w-full flex" :hidden="get_Sales_Group().length > 3" >
                    <button :disabled="true" v-for="product in get_Sales_Group()" :active="tab == product.tour_id" @click="product_selection(product.tour_id)">{{accomodation_formating(product.name_short, product.name)}} <span class="price-from">{{get_price(product)}}</span></button>
                </div>

                <div class="tab-content">
                    <div :hidden="tab != product.tour_id" v-for="product in get_Sales_Group()">
                        <sales_group_room_type :trip_index=trip_index :tour_id="product.tour_id" :year="year" :month="month"></sales_group_room_type>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script lang="ts" src="./sales_group.ts"></script>