﻿<template>
    

  <div v-if="displayPopup" class="z-50  bg-white h-full w-full slideout-inner object-cover overflow-hidden overscroll-none fixed flex justify-center">
    <div class="z-50 h-full w-full  md:h-8/12 md:w-10/12 rounded-md  items-center">
      <button class="close no-bg bg-opacity-80 " @click="displayPopup=false"></button>
      <div class="flex justify-center ">
        <div class="grid gap-x-1 grid-cols-2 w-full xl:w-10/12">
          <div class="relative justify-center overflow-hidden bg-gray  py-8 sm:py-2 m-2" @click="setfilter(1)">
              <div  class="bg-cover  bg-center group relative cursor-pointer overflow-hidden  px-6 pt-10 pb-8 shadow-xl  transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl sm:mx-auto sm:max-w-sm sm:rounded-lg sm:px-10" style="background-image: url('https://contenthub.radicaltravel.com/media/1914/large-hag-outlander-trail-falkland-aprilroseadventure-codepodhostels-2022.jpg');">
                  <span class="hidden md:inline-grid absolute top-10 z-0 h-20 w-20 rounded-full bg-black transition-all duration-300 group-hover:scale-[10]"></span>
                  <div class="relative z-10 mx-auto max-w-md">
                      <span class="hidden md:inline-grid grid h-20 w-20 place-items-center rounded-full bg-black transition-all duration-300 group-hover:bg-sky-400">
                        <img   class="h-12 mx-auto rounded-md"  src="https://contenthub.radicaltravel.com/media/2106/haggis-adventure-logo-white-1.png?" alt="Image of available Add-on" />
                      </span>
                      <div
                          class="space-y-6 pt-5 text-xl md:text-2xl leading-7 text-white transition-all duration-300 group-hover:text-white/90">
                          <p class="bg-black rounded-xl bg-opacity-80 md:bg-opacity-50 p-2 text-center">HAGGiS Adventures<br> Day Tours</p>
                      </div>
                      
                  </div>
              </div>
            </div>
            <div class="relative justify-center overflow-hidden bg-gray  py-8 sm:py-2 m-2 " @click="setfilter(2)">
              <div  class="bg-cover  bg-center group relative cursor-pointer overflow-hidden  px-6 pt-10 pb-8 shadow-xl  transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl sm:mx-auto sm:max-w-sm sm:rounded-lg sm:px-10" style="background-image: url('https://contenthub.radicaltravel.com/media/1047/h69skyehigh4-1.jpg');">
                  <span class="hidden md:inline-grid absolute top-10 z-0 h-20 w-20 rounded-full bg-black transition-all duration-300 group-hover:scale-[10]"></span>
                  <div class="relative z-10 mx-auto max-w-md">
                      <span class="hidden md:inline-grid grid h-20 w-20 place-items-center rounded-full bg-black transition-all duration-300 group-hover:bg-sky-400">
                        <img   class="h-12 mx-auto rounded-md"  src="https://contenthub.radicaltravel.com/media/2106/haggis-adventure-logo-white-1.png?" alt="Image of available Add-on" />
                      </span>
                      <div
                          class="space-y-6 pt-5 text-xl md:text-2xl leading-7 text-white transition-all duration-300 group-hover:text-white/90">
                          <p class="bg-black rounded-xl bg-opacity-80 md:bg-opacity-50 p-2 text-center">HAGGiS Adventures<br> 2 to 8 Day Tours</p>
                      </div>
                      
                  </div>
              </div>
            </div>
            <div class="relative justify-center overflow-hidden bg-gray  py-8 sm:py-2 m-2 " @click="setfilter(3)">
              <div class="bg-cover  bg-center group relative cursor-pointer overflow-hidden  px-6 pt-10 pb-8 shadow-xl  transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl sm:mx-auto sm:max-w-sm sm:rounded-lg sm:px-10" style="background-image: url('https://contenthub.radicaltravel.com/media/2088/large-hag-kelpies-stephen-bridger-2016-5-copy-without-tower.jpg');">
                  <span class="hidden md:inline-grid absolute top-10 z-0 h-20 w-20 rounded-full bg-white transition-all duration-300 group-hover:scale-[10]"></span>
                  <div class="relative z-10 mx-auto max-w-md">
                      <span class="hidden md:inline-grid grid h-20 w-20 place-items-center rounded-full bg-white transition-all duration-300 group-hover:bg-sky-400">
                        <img   class="w-24 mx-auto rounded-full"  src="https://contenthub.radicaltravel.com/media/2467/hex_icon.png" alt="Image of available Add-on" />
                      </span>
                      <div
                          class="space-y-6 pt-5 text-xl md:text-2xl leading-7 text-white transition-all duration-300 group-hover:text-white/90">
                          <p class="bg-black rounded-xl group-hover:text-black  group-hover:bg-opacity-0 bg-opacity-80 md:bg-opacity-50 p-2 text-center">Highland Explorer Tours <br> Day Tours</p>
                          
                      </div>
                      
                  </div>
              </div>
            </div>
            <div class="relative justify-center overflow-hidden bg-gray  py-8 sm:py-2 m-2 " @click="setfilter(4)">
              <div  class="bg-cover  bg-center group relative cursor-pointer overflow-hidden  px-6 pt-10 pb-8 shadow-xl  transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl sm:mx-auto sm:max-w-sm sm:rounded-lg sm:px-10" style="background-image: url('https://contenthub.radicaltravel.com/media/2021/large-hex-callanish-stones-michelle-morgan-2017.jpg');">
                  <span class="hidden md:inline-grid absolute top-10 z-0 h-20 w-20 rounded-full bg-white transition-all duration-300 group-hover:scale-[10]"></span>
                  <div class="relative z-10 mx-auto max-w-md">
                      <span class="hidden md:inline-grid grid h-20 w-20 place-items-center rounded-full bg-white transition-all duration-300 group-hover:bg-sky-400">
                        <img   class="w-24 mx-auto rounded-full"  src="https://contenthub.radicaltravel.com/media/2467/hex_icon.png" alt="Image of available Add-on" />
                      </span>
                      <div
                          class="space-y-6 pt-5 text-xl md:text-2xl leading-7 text-white transition-all duration-300 group-hover:text-white/90">
                          <p class="bg-black rounded-xl group-hover:text-black  group-hover:bg-opacity-0 bg-opacity-80 md:bg-opacity-50 p-2 text-center">Highland Explorer Tours <br>2 to 8 Day Tours</p>
                          
                      </div>
                      
                  </div>
              </div>
              
            </div>
        </div>
      </div>
      <div class="w-full text-center mt-8 flex justify-center">
            <button type="button" class="button button-pink-book" @click="displayPopup=false">Show all tours</button>
        </div>       
    </div>
  </div>

  

   
</template>

<script lang="ts" src="./agent_options.ts"></script>