import { booking_info } from './booking_info';
import { client } from './client';
import { document } from './document';

export class booking_search_group {
    public group_booking_id: number = 0;
    public booking_error: string = "";
    public session_id: string = "";
    public your_reference: string = "";
    public trips: booking_info[] = [];
    public passengers: client[] = [];
    public documents: document[] = [];
}
