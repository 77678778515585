﻿<template>

    <header class="header" >
        <header_menu></header_menu>
        <hero v-if="isValidView()" :title=getConfirmationText() :subtitle="getSubtitle()" :hero_image="getHeroImage()"></hero>
        <hero v-else title="404 (page not found)" subtitle="Whoops! You've hit a 404 (page not found). Sorry, you'll have to turn around. " :hero_image="getHeroImage()" ></hero>
        <div v-if="!isValidView()" class="flex flex-col items-center"><button type="button" class="m-10 px-10 py-2 button button-pink-book" @click="home_Click()">Return to Home Page</button></div>
    </header>

    <main class="main" v-if="isValidView()">
        
        <!--TEMPORARY MESSAGE DURING PAYMENT FAILURES-->
        <div v-if="!isAgent_Login() && GetBrand() == 'HEX'" class="cart-addon m-10" >
            <p class="m-auto font-bold text-black pt-2 text-center">If you don't receive a confirmation email please contact us at info@highlandexplorertours.com</p>
        </div>
        <div v-if="!isAgent_Login() && GetBrand() == 'HAG'" class="cart-addon m-10" >
            <p class="m-auto font-bold text-black pt-2 text-center">If you don't receive a confirmation email please contact us at info@haggisadventures.com</p>
        </div>

        <div class="row mt-12" id="PaymentConfirmed">
            <h2>Booked itinerary</h2>
        </div>

        <div class="row">

            <div class="payment cart-content flex flex-col md:flex-row">

                <div class="cart-items w-full md:w-7/12 lg:w-8/12 pr-0 md:pr-4 xl:pr-8 mb-8">
                    <basket></basket>
                    <documentation :class="{'hidden': isAgent_Login() == false}"></documentation>
                    <iframe v-if="!isAgent_Login()" :src="getFloDeskURL()" class="align-left w-full h-screen overflow-hidden" title="flodesk Iframe"></iframe>
                </div>

                <trip_summary></trip_summary>
            </div>
         </div>

    </main>

    <footer_item></footer_item>

</template>

<script lang="ts" src="./5_confirmation.ts"></script>