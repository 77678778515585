﻿<template>
    <div id="calendar" class="row w-full mt-12">

        <div class="filters">

            <div class="tour-result mx-4 md:mx-8">
                <p>
                    {{search_result()}} <a @click="Clear_Filter()" class="hover:no-underline rounded-full my-2 my-2 mx-0 md:mx-2 px-3 py-1 text-sm button button-bluelight">Clear Filter</a>

                    <button type="button" class="calendar-button" @click="Apply_Chanages()">
                        <span class="icon inline">
                            <svg class="inline -mt-1 mr-1 svg-inline--fa fa-calendar-alt fa-w-14 fa-2x" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="calendar-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M400 64h-48V12c0-6.6-5.4-12-12-12h-8c-6.6 0-12 5.4-12 12v52H128V12c0-6.6-5.4-12-12-12h-8c-6.6 0-12 5.4-12 12v52H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM48 96h352c8.8 0 16 7.2 16 16v48H32v-48c0-8.8 7.2-16 16-16zm352 384H48c-8.8 0-16-7.2-16-16V192h384v272c0 8.8-7.2 16-16 16zM148 320h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm96 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm96 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm-96 96h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm-96 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm192 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12z"></path></svg>
                        </span>
                        Apply
                    </button>
                </p>
            </div>

        </div>

        <div class="custom-calendar">
            <calendar_month :selected_month=selected_month :months="months" @monthChange="onMonthChange" ref="calendar_month"></calendar_month>
            <calendar_details @dateSelected="Date_Selected" ref="calendar_details"></calendar_details>
        </div>
    </div>

    <div class="slideout" :class="{'active': isTourListOpen, 'hidden': !isTourListOpen}"
         x-transition:enter="transition ease-out duration-500 -mr-64"
         x-transition:enter-start=""
         x-transition:enter-end="transform -translate-x-64"
         x-transition:leave="transform ease-out duration-75"
         x-transition:leave-start=""
         x-transition:leave-end="transform translate-x-64">
        <div class="slideout-inner">
            <button class="close no-bg" @click="isTourListOpen = false"></button>

            <tour_list :tours_selected="tours_selected" :travel_date="travel_date" @tourSelected="Tour_Selected"></tour_list>
        </div>

        <div class="slideout-bg" @click="isTourListOpen = false"></div>

    </div>

</template>

<script lang="ts" src="./calendar.ts"></script>