﻿<template>

    <h4>Change Account Password</h4>

    <div class="form-contents">
        <div class="row flex flex-col lg:flex-row">
            <div class="form-group float-label w-full">
                Current Password*
                <input type="password" name="current_password" id="current_password" spellcheck="false" v-model="current_password" />
            </div>
        </div>
        <div class="row flex flex-col lg:flex-row">
            <div class="form-group float-label w-full">
                New Password*
                <input type="password" name="new_password" id="new_password" spellcheck="false" v-model="new_password" />
            </div>
        </div>
        <div class="row flex flex-col lg:flex-row">
            <div class="form-group float-label w-full">
                Confirm New Password*
                <input type="password" name="confirm_new_password" id="confirm_new_password" spellcheck="false" v-model="confirm_new_password" />
            </div>
        </div>
        <div class="row flex flex-col lg:flex-row">
            <div class="form-group float-label w-full">
                <div class="button" @click="Save_Details()">
                    <svg class="animate-spin h-5 w-5 mr-3 inline"  :class="{ hidden: !saving_details }" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 512 512" stroke="currentColor">
                        <path fill="currentColor" d="M440.65 12.57l4 82.77A247.16 247.16 0 0 0 255.83 8C134.73 8 33.91 94.92 12.29 209.82A12 12 0 0 0 24.09 224h49.05a12 12 0 0 0 11.67-9.26 175.91 175.91 0 0 1 317-56.94l-101.46-4.86a12 12 0 0 0-12.57 12v47.41a12 12 0 0 0 12 12H500a12 12 0 0 0 12-12V12a12 12 0 0 0-12-12h-47.37a12 12 0 0 0-11.98 12.57zM255.83 432a175.61 175.61 0 0 1-146-77.8l101.8 4.87a12 12 0 0 0 12.57-12v-47.4a12 12 0 0 0-12-12H12a12 12 0 0 0-12 12V500a12 12 0 0 0 12 12h47.35a12 12 0 0 0 12-12.6l-4.15-82.57A247.17 247.17 0 0 0 255.83 504c121.11 0 221.93-86.92 243.55-201.82a12 12 0 0 0-11.8-14.18h-49.05a12 12 0 0 0-11.67 9.26A175.86 175.86 0 0 1 255.83 432z" class=""></path>
                    </svg>
                    Save New Password
                </div>
            </div>
        </div>
    </div>

    

</template>

<script lang="ts" src="./change_account_password.ts"></script>