﻿import { defineComponent, PropType } from 'vue';

import { Payment } from "../../../services/payment";

const Pay: Payment = new Payment();
const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

import { payment_response } from "../../../objects/ttc/payment_response/response";
import { resp } from "../../../objects/ttc/payment_response/resp";
import { response } from "../../../objects/v1_3/payment/initialise/response";

import { response_method } from "../../../objects/v1_3/payment/initialise/response_method";
import { booking_search_group } from "../../../objects/v1_2/Booking_Details/booking_search_group";

import { payment } from "../../../objects/ttc/payment_gateway/payment";

export default defineComponent({
    name: "payment_credit_card",
    props: {
        consumer_display: String,
        credit_card_div: String
    },
    data() {
        return {
            payment_response: new resp() as resp,
            initialised_payment: false as Boolean,
            ttc_payment_form: false as Boolean,
            finalised_payment: true as Boolean,
            storepaymentResponse: new payment_response() as payment_response,
            cybersource_url: "",
            showAltUrl: false
        }
    },
    methods: {
        get_Booking(): booking_search_group {
            return this.$store.getters.get_Booking;
        },
        async check_out_page() {
            if (this.$store.getters.get_Agent_Login?.payment_methods.length > 0) {
                if (this.$store.getters.get_Agent_Login.payment_methods[0].method == "CC") {
                    if (this.consumer_display == "false") {
                        await this.Initialise_Payment(false, this.$store.getters.get_Selected_Payment.nett_gross == "N");
                    }
                } else {
                    this.initialised_payment = true;
                    this.ttc_payment_form = true;
                    this.finalised_payment = true;
                }
            } else {
                if (this.consumer_display == "true") {
                    await this.Initialise_Payment(true, false);
                }
            }
        },
        async Initialise_Payment(consumer_sale: boolean, nett_sale: boolean) {
            let Payment_Initialise: response = await Pay.Payment_Initialise(this.$store.getters.get_Payment_Full_Payment, this.$store.getters.get_Payment_Deposit, this.$store.getters.get_Booking, this.$store.getters.get_Agent_Login, consumer_sale, nett_sale).then(response => { return response.json(); })
            let Payment_Method: response_method = Pay.Payment_Method(Payment_Initialise);
            console.log(Payment_Method);

            await Pay.get_cybersource_url(Payment_Initialise);
            let Payment_Transation: payment = JSON.parse(Payment_Method.data_payload ?? "");      

            Payment_Transation.transactionInfo.bookingType = "CD";

            if(!this.isAgent_Login()) {
                Payment_Transation = this.setBlankDefaultAddress(Payment_Transation);
            }
            this.payment_response.ttc = Payment_Transation

            console.log(Payment_Transation);

            this.initialised_payment = true;
            this.ttc_payment_form = false;
            this.finalised_payment = true;

            this.cybersource_url = Pay.get_cybersource_url(Payment_Initialise);
            this.AltUrlTimer(10000);

            window.ttcPaymentgateway.attachPaymentOption(JSON.stringify(Payment_Transation), "#" + this.credit_card_div, (paymentResponse: payment_response) => {
                this.Payment_Processed(paymentResponse);
            });

        },
        isAgent_Login(): boolean {
            return this.$store.getters.get_Agent_Login?.valid_login == true;
        },
        async AltUrlTimer(timer: number) {
            await sleep(timer);
            this.showAltUrl = JSON.parse(import.meta.env.VITE_ALTERNATIVE_PAYMENT_LINK_ENABLED);
        },
        async Payment_Processed(Payment_Response: payment_response) {
            console.log(Payment_Response);

            this.payment_response.response_payment = Payment_Response;

            if(Payment_Response?.paymentSuccessful == false) {
                this.$router.push({name: 'failed_payment', params: { reason: "reason="+Payment_Response?.paymentStatus}});
            }
            else if (Payment_Response?.paymentSuccessful != null) {
                this.finalised_payment = false;
                this.ttc_payment_form = true;
                this.showAltUrl = false;
                
                let Return_Status = await Pay.Commit_Payment(this.payment_response);

                this.$router.push({ path: "/confirmation" });
            }

            this.finalised_payment = true;
        },
        setBlankDefaultAddress(payment: payment): payment {
            payment.transactionInfo.addressCity = "";
            payment.transactionInfo.addressCountry = "";
            payment.transactionInfo.addressLine1 = "";
            payment.transactionInfo.addressLine2 = "";
            payment.transactionInfo.addressPostalCode = "";

            return payment;

        },
        openCyberSource() {
            window.open(this.cybersource_url, "_self");
        }
    },
    mounted() {
        this.check_out_page();
    }
})