﻿import { defineComponent, PropType } from 'vue';

import { Booking } from "../../../services/booking";
import { Currency_Service } from "../../../../system/services/currency";

const Book: Booking = new Booking();
const Currency_Services: Currency_Service = new Currency_Service();

import { response_login } from '../../../objects/agent/identity/v1_3/response_login';
import { response_login_payment } from '../../../objects/agent/identity/v1_3/response_login_payment';

export default defineComponent({
    name: "payment_confirmation",
    props: {
    },
    data() {
        return {
            isOpen: false as Boolean,
            checked_terms: false as Boolean,
            full_payment_now: false as boolean,
            deposit_payment_now: false as boolean
        }
    },
    methods: {
        get_Nett_Total(): string {
            return Currency_Services.Format_Currency(Book.get_full_nett_total(this.$store.getters.get_Booking, this.$store), this.$store.getters.get_Currency);
        },
        get_Deposit_Total(): string {
            return Currency_Services.Format_Currency(Book.get_Total_Deposit(this.$store.getters.get_Booking), this.$store.getters.get_Currency);
        },
        async Full_Payment() {
            if (this.Valid_Form() == true) {
                this.full_payment_now = true;
                this.$emit("updatePayment", true, false);
            }

        },
        async Deposit_Payment() {
            if (this.Valid_Form() == true) {
                this.deposit_payment_now = true;
                await this.$emit("updatePayment", false, true);
                this.deposit_payment_now = false;
            }
        },
        terms_policy(): string {
            return String(import.meta.env.VITE_FOOTER_POLICY_TERMS);
        },
        Valid_Form(): boolean {
            if (this.checked_terms == false) {
                window.alert("Please acknowledge terms and conditions");
                return false;
            }

            return true;
        }
    },
    mounted() {
    }
})