﻿<template>

    <!--desktop and laptop only design-->
    <div class="addon rounded-md addon-content m-1 flex w-full h-auto bg-white " :class="{'hidden': isMobile()}">
        <div class="cover">
            <img class="h-full w-full object-cover rounded-l" :src="image()" :alt="imageAltText()">
        </div>
        <div class="p-4 flex flex-col justify-between w-full leading-normaladdon">
            <div class="add">
                <h6>{{tour?.name}}</h6>
                <div v-if="isAAT()">
                    <h9 v-if="tour?.duration != null && tour?.duration.days > 0">{{tour?.duration.days}} day tour</h9>
                    <h9 v-else>{{tour?.duration.hours}} hour tour</h9>
                </div>
                <h9 v-else>{{durationInDays()}} tour</h9>
                <p class="mt-1 mb-0 text-sm">{{display_description()}}</p>
                <p class="mt-1 mb-0 text-sm"><a class="m-0 p-0" @click="Display_Itinerary = !Display_Itinerary" :active="Display_Itinerary">View itinerary</a></p>
                <div class="lightbox" :hidden="!Display_Itinerary" @click.away="Display_Itinerary = false" >
                    <div class="lightbox-inner">
                        <div class="lightbox-content">
                            <div class="lightbox-head flex">
                                <img class="ml-10 mt-10 rounded" :src="image()" style="height: 200px; width: 300px;">
                            </div>
                            <div class="lightbox-body">
                                <p ><button @click="Full_Tour_To_Cart()" class="button button-default small align-bottom">Add To Cart</button></p>
                                <itinerary :tour_id="tour?.tour_id" :banner="false"></itinerary>
                                <p><button @click="Full_Tour_To_Cart()" class="button button-default small align-bottom">Add To Cart</button></p>

                                <button class="close" @click="Display_Itinerary = false"></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="price relative">
                <a @click="Full_Tour_To_Cart()" class="button button-pink-book full">Add Tour</a>

                <div class="lightbox" :hidden="!Display_Add_To_Cart" >
                    <div class="lightbox-inner">
                        <div class="lightbox-content">
                            <div class="lightbox-head">
                            </div>
                            <div class="lightbox-body">
                                <tour_details :trip_index=trip_index ref="tour_details" @Add_To_Cart="Add_To_Cart"></tour_details>
                                <button class="close" @click="Display_Add_To_Cart = false"></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <!--mobile and tablet only design-->
    <div class="addon rounded-md addon-content m-1 w-full h-auto bg-white " :class="{'hidden': !isMobile()}">
        <div class="cover-mobile">
            <img class="h-full w-full flex object-cover rounded-l" :src="image()" :alt="imageAltText()">
        </div>
        <div class="p-4 flex flex-col justify-between w-full leading-normaladdon">
            <div class="add">
                <h6>{{tour?.name}}</h6>
                <h9 v-if="tour?.duration.days">{{tour?.duration.days}} day tour</h9>
                <p class="mt-2 mb-0 text-sm">{{display_description()}}</p>
                <p class="mt-2 mb-2 text-sm"><a class="m-0 p-0" @click="Display_Itinerary = !Display_Itinerary" :active="Display_Itinerary">View itinerary</a></p>
                <div class="lightbox" :hidden="!Display_Itinerary" @click.away="Display_Itinerary = false" >
                    <div class="lightbox-inner">
                        <div class="lightbox-content">
                            <div class="lightbox-head">
                                <img class="rounded-t-lg w-full" :src="image()" style="height: 200px;">
                            </div>
                            <div class="lightbox-body">
                                <p><button @click="Full_Tour_To_Cart()" class="button button-default small align-bottom">Add To Cart</button></p>
                                <itinerary :tour_id="tour?.tour_id" :banner="false"></itinerary>
                                <p><button @click="Full_Tour_To_Cart()" class="button button-default small align-bottom">Add To Cart</button></p>

                                <button class="close" @click="Display_Itinerary = false"></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="price relative">
                <a @click="Full_Tour_To_Cart()" class="button button-pink-book full">Add Tour</a>

                <div class="lightbox" :hidden="!Display_Add_To_Cart" >
                    <div class="lightbox-inner">
                        <div class="lightbox-content">
                            <div class="lightbox-head">
                            </div>
                            <div class="lightbox-body-mobile">
                                <tour_details :trip_index=trip_index ref="tour_details" ></tour_details>
                                <button class="close" @click="Display_Add_To_Cart = false"></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</template>

<script lang="ts" src="./tour.ts"></script>