﻿import { defineComponent, PropType } from 'vue';

import { age_category } from "../../objects/v1_3/web_engine/age_category"

import { Booking } from "../../services/booking";

const Book = new Booking();

export default defineComponent({
    name: "passenger",
    emits: [
        "passenger_update"
    ],
    props: {
        name: String,
        pass: Object as PropType<age_category>
    },
    data() {
        return {
        }
    },
    methods: {
        async add_passenger() {
            if (this.passenger_total() < 10 && (this.available_spaces() > this.passenger_total() || this.available_spaces() == -1)) {
                this.$store.commit("Passenger_Add", { Age_Category: this.pass?.name });
                this.$emit("passenger_update");

                await Book.Setup_Booking(this.$store);
            }
        },
        async remove_passenger() {
            if (this.passenger_total() > 0) {
                this.$store.commit("Passenger_Remove", { Age_Category: this.pass?.name });
                this.$emit("passenger_update");

                await Book.Setup_Booking(this.$store);
            }
        },
        available_spaces(): number {
            return this.$store.getters.get_seats_free_selected_date;
        },
        passenger_total(): number {
            return Book.Passenger_Total(this.pass?.name ?? "", this.$store.getters.get_Basket);
        },
        GetBrand(): string {
            return String(import.meta.env.VITE_DEFAULT_CLASS);
        },
        get_passenger_description(name: string): string {
            if (this.$store.getters.get_Agent_Login?.valid_login == false) {
                if (name == "Adult") {
                    if (this.GetBrand().toUpperCase() == "HAG") {
                        return "18+ years old"
                    }
                    return "17-59 years old"
                }
                else if (name == "Child") {
                    return "Minimum age for children on tour is 5 years old. Children aged 5-16 must be accompanied by a person aged 18+"
                }
                else if (name == "Student") {
                    return "Students with valid university or college ID"
                }
                else if (name == "Senior") {
                    return "60+ years old"
                }
            }

            return "";
        },
        isMobile(): boolean{
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    }
})