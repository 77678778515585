﻿import { resp } from "../../booking_engine/objects/ttc/payment_response/resp";

export class Rest_API {

    public Get_Data<Response>(url: string): Promise<any> {
        return fetch(url, {
            "method": "GET"
        }).catch(err => {
            console.log("Call Error" + err);
        });
    }
    public Post_Data<Request, Response>(url: string, Body_Data: Request): Promise<any> {
        return fetch(url, {
            "method": "POST",
            "headers": { "Content-Type": "application/json"},
            "body": JSON.stringify(Body_Data)
        });
    }
    public Get_Data_Secure<Response>(url: string, hash_password: string) {
        return fetch(url, {
            "method": "GET",
            "headers": { "Content-Type": "application/json", "x-request-tms-call-authentication": hash_password }
        });
    }
    public Post_Data_Secure<Request, Response>(url: string, hash_password: string, Body_Data: Request) {
        return fetch(url, {
            "method": "POST",
            "headers": { "Content-Type": "application/json", "x-request-tms-call-authentication": hash_password },
            "body": JSON.stringify(Body_Data)
        });
    }
    public Put_Data_Secure<Request, Response>(url: string, hash_password: string, Body_Data: Request) {
        return fetch(url, {
            "method": "PUT",
            "headers": { "Content-Type": "application/json", "x-request-tms-call-authentication": hash_password },
            "body": JSON.stringify(Body_Data)
        });
    }
    public Delete_Data_Secure<Request, Response>(url: string, hash_password: string, Body_Data: Request) {
        return fetch(url, {
            "method": "DELETE",
            "headers": { "Content-Type": "application/json", "x-request-tms-call-authentication": hash_password },
            "body": JSON.stringify(Body_Data)
        });
    }
}