﻿<template>

    <div class="booking-notes mt-12" :hidden="Display_Notes() == false">
        <h6 @click="display_note = !display_note">Booking Notes</h6>
        <p v-for="note in this.$store.getters.get_Booking?.notes">{{note.note_text}}</p>
        <form class="signup border border-aat-greylight rounded mt-8 pr-8" @submit.prevent="Prevent_Submit()" :hidden="!display_note">
            <div class="form-contents">
                <div class="row">
                    <div class="form-group float-label w-full">
                        <ul class="list-none inline">
                            <li class="inline-block"><strong>In relation to:</strong></li>
                            <li class="inline-block ml-4">
                                <label for="checkbox1" class="block">
                                    <input id="checkbox1" name="checkbox1" :checked="Check_Type('Dietary')" type="checkbox" @click="Add_Type('Dietary')" class="focus:ring-action h-4 w-4 text-action border-grey-light rounded mr-2">
                                    Dietary
                                </label>
                            </li>
                            <li class="inline-block ml-4">
                                <label for="checkbox2" class="block">
                                    <input id="checkbox2" name="checkbox2" :checked="Check_Type('Special Assistance')" type="checkbox" @click="Add_Type('Special Assistance')" class="focus:ring-action h-4 w-4 text-action border-grey-light rounded mr-2">
                                    Special Assistance
                                </label>
                            </li>
                            <li class="inline-block ml-4">
                                <label for="checkbox3" class="block">
                                    <input id="checkbox3" name="checkbox3" :checked="Check_Type('Other')" type="checkbox" @click="Add_Type('Other')" class="focus:ring-action h-4 w-4 text-action border-grey-light rounded mr-2">
                                    Other
                                </label>
                            </li>
                        </ul>
                    </div>
                    <div class="form-group float-label w-full">
                        <textarea id="about" name="about" rows="8" cols="250" placeholder="Booking notes..." v-model="note"></textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group w-full text-right">
                        <button class="button button-bluelight small rounded" @click="Add_Note()">
                            <svg class="animate-spin h-5 w-5 mr-3 inline"  :class="{ 'hidden': !saving_note }" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 512 512" stroke="currentColor">
                                <path fill="currentColor" d="M440.65 12.57l4 82.77A247.16 247.16 0 0 0 255.83 8C134.73 8 33.91 94.92 12.29 209.82A12 12 0 0 0 24.09 224h49.05a12 12 0 0 0 11.67-9.26 175.91 175.91 0 0 1 317-56.94l-101.46-4.86a12 12 0 0 0-12.57 12v47.41a12 12 0 0 0 12 12H500a12 12 0 0 0 12-12V12a12 12 0 0 0-12-12h-47.37a12 12 0 0 0-11.98 12.57zM255.83 432a175.61 175.61 0 0 1-146-77.8l101.8 4.87a12 12 0 0 0 12.57-12v-47.4a12 12 0 0 0-12-12H12a12 12 0 0 0-12 12V500a12 12 0 0 0 12 12h47.35a12 12 0 0 0 12-12.6l-4.15-82.57A247.17 247.17 0 0 0 255.83 504c121.11 0 221.93-86.92 243.55-201.82a12 12 0 0 0-11.8-14.18h-49.05a12 12 0 0 0-11.67 9.26A175.86 175.86 0 0 1 255.83 432z" class=""></path>
                            </svg>
                            Save
                        </button>
                    </div>
                </div>

            </div>
        </form>

    </div>

</template>

<script lang="ts" src="./booking_notes.ts"></script>