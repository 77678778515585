﻿import { defineComponent, PropType } from 'vue';

import { Product } from "../../../services/product";

import { pickup } from '../../../objects/v1_3/product/pickup/pickup';
import { product_detail } from '../../../objects/v1_3/product/product/product_detail';
import { pickup_point } from '../../../objects/v1_3/product/pickup/pickup_point';

let Prod = new Product();

export default defineComponent({
    name: "pickup_points",
    props: {
        pickup_id: String,
        trip_index: Number
    },
    data() {
        return {
            pickup_points: new pickup() as pickup,
            isOpen: false as Boolean,
            selected_pickup_point: new pickup_point() as pickup_point
        }
    },
    methods: {
        async Download_Pickup_Points(tour_id: string) {
            let product_details: product_detail = await Prod.Get_Details(tour_id, this.$store);

            this.pickup_points = new pickup();
            if (product_details.operational_information.pickup_run_id != null) {
                this.pickup_points = await Prod.Get_Pickup_Points(product_details.operational_information.pickup_run_id);

                this.pickup_points.points.sort((first, second) => {
                    return 0 - ((first.name ?? "") > (second.name ?? "") ? -1 : 1)
                });
            }
        },
        display_pickup_points(): boolean {
            return this.pickup_points?.points?.length > 0;
        },
        Pickup_Click(pickup_point: pickup_point) {
            this.selected_pickup_point = pickup_point;
            this.isOpen = false;

            this.$store.commit("Initialise_Pickup_Point", { Trip_Index: this.get_Trip_Index(),  Pickup_Point: pickup_point });
        },
        get_Trip_Index(): number {
            return this.trip_index ?? 0;
        },
        onClickAway(event: any) {
            let Click_Away: boolean = true;

/*            if (event.path[1].id == "Pickup_Point_Click_Away") {
                Click_Away = false;
            }*/

            if (Click_Away == true) {
                this.isOpen = false;
            }
        }
    },
    mounted() {
    }
})