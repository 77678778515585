﻿import { defineComponent, PropType } from 'vue';

import { booking_info } from "../../../objects/v1_2/Booking_Details/booking_info";
import { client_booking } from '../../../objects/v1_2/Booking_Details/client_booking';
import { client } from '../../../objects/v1_2/Booking_Details/client';

import { trip_passenger } from "../../../objects/v1_2/Booking/Trip_Passenger";

import { Booking } from '../../../services/booking';
import { Currency_Service } from '../../../../system/services/currency';

const Book = new Booking();
const Currency_Services: Currency_Service = new Currency_Service();

export default defineComponent({
    name: "basket-item",
    props: {
        add_on: Boolean,
        parent_tour_id: String,
        trip: Object as PropType<booking_info>
    },
    data() {
        return {
            isOpen_Item_List: false
        }
    },
    methods: {
        getTrip_Items(): string {
            return this.trip?.passengers.length + " Items";
        },
        getTrip_Heading(): string {
            return this.trip?.passengers.length + " add-on items applied";
        },
        getTrip_Pricing(Passenger: client): string {
            let Return_Passenger: string = "";

            this.trip?.passengers.forEach((Client_Booking: client_booking)  => {
                if (Client_Booking.passenger_identifier == Passenger.passenger_identifier) {
                    Return_Passenger = Book.get_Passenger(Client_Booking?.passenger_identifier, this.$store.getters.get_Booking) + " - " + Currency_Services.Format_Currency(Client_Booking?.price, this.$store.getters.get_Currency);
                }
            });

            if (Return_Passenger == "") {
                if (Passenger.family_name == null) {
                    Return_Passenger = Passenger.first_name;
                } else {
                    Return_Passenger = Passenger.first_name + " " + Passenger.family_name;
                }
            }

            return Return_Passenger;
        },
        async Toggle_Passenger(index: number) {
            this.$store.commit("Add_Add_On_Passenger_Change", { Parent_Tour_ID: this.parent_tour_id, Tour_ID: this.trip?.tour_id, Index: index});
            this.$store.commit("Setup_Booking", { Booking_Details: await Book.Quote_Booking(this.$store) });
        },
        Passenger_Selected(Passenger: client): boolean {
            let Return_Status: boolean = false;

            this.trip?.passengers.forEach((Client_Booking: client_booking) => {
                if (Client_Booking.passenger_identifier == Passenger.passenger_identifier) {
                    Return_Status = true;
                }
            });

            return Return_Status;
        }
    },
    mounted() {
    }
})