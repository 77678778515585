﻿import { defineComponent } from 'vue';

export default defineComponent({
    components: {
    },
    props: {
        title: String,
        subtitle: String,
        hero_image: String
    },
    methods: {
        isAgent_Login(): boolean {
            return this.$store.getters.get_Agent_Login?.valid_login == true;
        },
        isMobile(): boolean{
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    mounted() {
    }
})