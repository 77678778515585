﻿<template>

    <div class="cart-addon bg-grey pr-4">
        <div v-if="DayTourAdded()">
            <h5>Receive a 10% discount when booking multiple Day Tours!</h5>
        </div>
        <div class="flex">
            <div class="flex-1">
                <h6>Add Another Tour</h6>
            </div>
            <div class="flex-1 text-right pr-6">
                <h6><a @click="View_All_Tours()" class="text-black cursor-pointer">View All Tours <svg class="inline -mt-1 h-4 w-4 svg-inline--fa fa-arrow-right fa-w-14 fa-2x" aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M218.101 38.101L198.302 57.9c-4.686 4.686-4.686 12.284 0 16.971L353.432 230H12c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h341.432l-155.13 155.13c-4.686 4.686-4.686 12.284 0 16.971l19.799 19.799c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L235.071 38.101c-4.686-4.687-12.284-4.687-16.97 0z" class=""></path></svg></a></h6>
            </div>
        </div>
        <div class="flex flex-col xl:flex-row">
            <tour :tour="tour" v-for="tour in get_Tours()"></tour>
        </div>
    </div>

</template>

<script lang="ts" src="./tours.ts"></script>