﻿import { product_detail_marketing_information_media } from "./product_detail_marketing_information_media";
import { product_detail_marketing_information_itinerary } from "./product_detail_marketing_information_itinerary";

export class product_detail_marketing_information {
    public name_short: string = "";
    public description: string = "";
    public description_headline: string = "";
    public descrtiption_short: string = "";
    public itineraries: product_detail_marketing_information_itinerary[] = [];
    public media: product_detail_marketing_information_media[] = [];
    public web_url: string = "";
}