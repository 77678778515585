﻿import { defineComponent, PropType } from 'vue';

import { Booking } from "../../../services/booking";
import { Currency_Service } from "../../../../system/services/currency";
import { Date_Service } from "../../../../system/services/date_service";

import { booking_details } from "../../../objects/v1_2/Booking_Details/booking_details";

const Book: Booking = new Booking();
const Currency_Services: Currency_Service = new Currency_Service();
const Date_Services = new Date_Service();

export default defineComponent({
    name: "payment_summary",
    props: {
        isGross: String
    },
    data() {
        return {
            isDiscounted: false
        }
    },
    methods: {
        get_Departre_Date(trip: booking_details): string {
            return Date_Services.output_date(trip.departure_date);
        },
        get_Full_Total(): string {
            return Currency_Services.Format_Currency(Book.get_full_total_price(this.$store.getters.get_Booking, this.$store), this.$store.getters.get_Currency);
        },
        get_Nett_Total(): string {
            return Currency_Services.Format_Currency(Book.get_full_nett_total(this.$store.getters.get_Booking, this.$store), this.$store.getters.get_Currency);
        },
        get_Discount(): string {
            this.isDiscounted = Book.get_discount(this.$store.getters.get_Booking) != 0;

            return "- " + Currency_Services.Format_Currency(Book.get_discount(this.$store.getters.get_Booking), this.$store.getters.get_Currency);
        },
        get_Discount_Percentage(): string {
            return Book.get_Discount_Percentage(this.$store.getters.get_Booking, this.$store) + "%";
        },
        get_Price(price: number): string {
            return Currency_Services.Format_Currency(price, this.$store.getters.get_Currency);
        },
        get_Departure_Date(departure_date: string): string {
            return Date_Services.string_full_date(departure_date);
        },
        getPassenger(passenger_identifier: string): string {
            return Book.get_Passenger(passenger_identifier, this.$store.getters.get_Booking);
        }
    },
    mounted() {
    }
})